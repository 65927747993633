import { List, Typography } from 'antd'
import { FC } from 'react'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { TitleCase } from 'src/sdk/helpers/strings'
import IvyIcon from '../icon'
import { HorizontalSpace, VerticalSpace } from '../layout'
import { Section } from '../layout/Section'
import { Text } from '../text/Text'

const ConfigIcon: FC<{ success: boolean }> = ({ success }) => {
  return success ? (
    <IvyIcon size={24} type={'symbol/check-circle'} color={'success'} />
  ) : (
    <IvyIcon size={24} type={'symbol/exclamation-circle'} color={'danger'} />
  )
}

const DebugConfig: FC = () => {
  const { defaultProcessor, features, props, analytics, intl } = usePublicConfig()

  return (
    <VerticalSpace>
      <Section title={'Company Settings'} titleSize={5}>
        <List>
          <List.Item key={'processor'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={defaultProcessor !== undefined && defaultProcessor.name !== undefined} />
              <Text>
                Payment Processor {defaultProcessor && defaultProcessor.name && `- (${defaultProcessor.name})`}
              </Text>
            </HorizontalSpace>
          </List.Item>
          <List.Item key={'sms'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={features !== undefined && features?.sms} />
              <Text>Text Messaging</Text>
            </HorizontalSpace>
          </List.Item>
        </List>
      </Section>
      <Section title={'Forms'} titleSize={5}>
        <List>
          {features &&
            Object.entries(features?.surveys as { [key in string]: boolean }).map(([name, value]) => (
              <List.Item key={name}>
                <HorizontalSpace align={'center'}>
                  <ConfigIcon success={value} />
                  <Text>{TitleCase(name)}</Text>
                </HorizontalSpace>
              </List.Item>
            ))}
        </List>
      </Section>
      <Section title={'General'} titleSize={5}>
        <List>
          <List.Item key={'google_analytics'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={analytics.google !== undefined} />
              <Text>Google Analytics</Text>
            </HorizontalSpace>
          </List.Item>
          <List.Item key={'favicon'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={props.favicon !== undefined} />
              <Text>Favicon</Text>
            </HorizontalSpace>
          </List.Item>
        </List>
      </Section>
      <Section title={'International'} titleSize={5}>
        <List>
          <List.Item key={'intl_locale'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={intl.locale !== undefined} />
              <Text>Locale ({intl.locale})</Text>
            </HorizontalSpace>
          </List.Item>
          <List.Item key={'intl_currency'}>
            <HorizontalSpace align={'center'}>
              <ConfigIcon success={intl.currency !== undefined} />
              <Text>Currency ({intl.currency})</Text>
            </HorizontalSpace>
          </List.Item>
        </List>
      </Section>
    </VerticalSpace>
  )
}

export default DebugConfig
