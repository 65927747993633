import { Menu } from 'antd'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { useAccount } from 'src/sdk/contexts/Account'
import { useMenuConfig, usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import IvyIcon from '../icon'
import { Flex } from '../layout/Grid'
import { BreakpointMin } from '../screen/Breakpoint'
import { Skeleton } from '../skeleton/Skeleton'

import './Navigation.less'
import { useI18n } from '../../contexts/I18n'

type NavigationProps = {
  loading?: boolean
} & Partial<RouteComponentProps>

export const Navigation: FC<NavigationProps> = ({ loading = false }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { pages } = useMenuConfig()
  const { customer } = useAccount()
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.LG })
  const {
    props: {
      navigation: { mode, theme, ...navigationProps },
    },
  } = usePublicConfig()
  const handleNavClick = (e: any) => history.push(e.key)
  const {t} = useI18n()
  return (
    <Menu
      className={withPrefix('main-menu')}
      inlineIndent={40}
      onClick={handleNavClick}
      theme={theme ?? 'dark'}
      selectedKeys={[`/${pathname.replace('/', '').split('/')[0]}`]}
      mode={mode === 'burger' ? 'vertical' : 'inline'}
      items={[
        ...pages
          .filter((page) => page.nav && page.enabled)
          .filter((page) => (page.membersOnly ? customer?.isMember : true))
          .map((page) => {
            const text = t(page.name)
            const url = page.path ?? page.uri

            return {
              label: loading ? (
                <Skeleton width={75} height={22} />
              ) : (
                <Flex direction={'horizontal'} align={'center'} size={16}>
                  {page.icon && <IvyIcon size={24} className={withPrefix('menu-item-icon')} type={page.icon} />}
                  <span className={withPrefix('menu-item-text')}>{text}</span>
                </Flex>
              ),
              key: url,
            }
          }),
        ...(mode === 'horizontal' && isDesktop
          ? [
              {
                label: (
                  <Flex direction={'horizontal'} align={'center'} size={16}>
                    <IvyIcon type={'application/setting'} />
                    <FormattedMessage id={'account'} defaultMessage={'account'} />
                  </Flex>
                ),
                key: '/account',
              },
            ]
          : []),
      ]}
      {...navigationProps}
    />
  )
}
