import { DatePicker as Wrapped, DatePickerProps } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import IvyIcon from 'src/sdk/components/icon'
import './DatePicker.less'
import { useI18n } from '../../contexts/I18n'

export const { RangePicker } = Wrapped

export const DatePicker: FC<DatePickerProps> = (props) => {
  const {t} = useI18n()
  return (
    <Wrapped
      defaultValue={moment()}
      defaultPickerValue={moment()}
      picker={'date'}
      suffixIcon={<IvyIcon size={16} type={'application/calendar'} />}
      size={'middle'}
      allowClear={props.allowClear ?? false}
      {...props}
    />
  )
}
