import { ErrorTitles } from './descriptors'

export type ApiErrorURLResponse = {
  title?: string
  message: string
  reference_id: string
}

export type ApiErrorItem = {
  error?: string
  details?: string
  referenceId?: Data.ID
  referenceType: string
}

export class ApiError extends Error {
  code?: number = 0
  error?: string = ''
  title?: string = ''
  errorMessage?: string = ''
  errorDescription?: any
  errorDetails?: ApiErrorItem | ApiErrorItem[] | any | undefined
  requestId?: string = ''
  uri?: string = ''

  name = 'ApiError'

  constructor(status: number, json: any = {}) {
    super(json?.error_description ?? '')
    this.uri = window.location.pathname

    this.code = status
    if (this.code === 404) {
      this.error = 'not_found'
      this.errorMessage = 'The resource you are looking for could not be found.'
    } else {
      const descriptor = ErrorTitles[json.error]
      this.error = descriptor ? descriptor.title : json.error
      this.title = descriptor ? descriptor.title : json.title
      this.errorMessage = descriptor && descriptor.message ? descriptor.message : json.error_message
      this.errorDescription = json.error_description
      this.errorDetails = json.error_details ? json.error_details : undefined
      this.requestId = json.request_id
    }
  }
}
