import { FC } from 'react'
import { Descriptions as Wrapper, DescriptionsProps } from 'antd'
import './Descriptions.less'
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item'
import { useI18n } from '../../contexts/I18n'

const Descriptions = ({ ...props }: DescriptionsProps) => {
  return Wrapper({ ...props })
}

const DescriptionsItem: FC<DescriptionsItemProps> = ({ label, ...props }) => {
  const { t } = useI18n()
  return <Wrapper.Item label={typeof label === 'string' ? t(label) : label} {...props} />
}
export { Descriptions as default, DescriptionsItem }
