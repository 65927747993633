import { TextProps } from 'antd/lib/typography/Text'
import { FC } from 'react'
import { Text } from './Text'

type SmallTextProps = {
  align?: 'left' | 'center' | 'right'
  block?: boolean
} & TextProps

const SmallText: FC<SmallTextProps> = ({ align, block, children, ...props }) => (
  <Text style={{ fontSize: 12, textAlign: align, display: block ? 'block' : undefined }} {...props}>
    {children}
  </Text>
)

export default SmallText
