import { Entity, Resource, schema } from '@rest-hooks/rest'
import moment from 'moment'
import { Endpoint, useResource } from 'rest-hooks'
import { getMediaUrl } from 'src/sdk/helpers/data'
import Female from 'src/sdk/images/placeholders/directory-member-female.svg'
import Male from 'src/sdk/images/placeholders/directory-member-male.svg'
import { DateTz } from '../../api'
import { BaseAddress } from '../address'
import { BusinessLocation } from '../business'
import { CategoryEntity } from '../category'
import { CompanyEntity } from '../company'
import { ApiResource, SchemaPaginated, UrlProps } from '../entity'
import { EventResource, EventSettings } from '../event'
import { GlobalNetworkCompany } from '../globalNetwork'
import { ImageEntity } from '../media'
import { OrderEntity, OrderItem } from '../order'
import { PassEntity } from '../pass'
import { Passbook } from '../passbook'
import { PaymentMethod } from '../payment'
import { ReservationEntity } from '../reservations'
import { ScanData } from '../scanData'
import { ScheduleType, TimeSlotEntity } from '../scheduler'
import { AbstractSurveyEntity, FormFieldValue, SurveyEndpoint, SurveyEndpointResource, SurveyEntity } from '../survey'
import { UserCredentials } from '../token'
import { TransactionEntity, TransactionRefund, TransactionRefundItem } from '../transaction'
import { BankAccount } from '../wallet/bankaccount'
import { CreditCardEntity } from '../wallet/creditcard'

export class QRCode extends Entity {
  readonly data: string = ''
  readonly url: string = ''

  pk(): string {
    return this.data
  }
}

class AdminEntity extends ApiResource {
  static readonly urlRoot = '/api/user'
  readonly businessLocationId: number = 0
  readonly employeeId: string = ''
  readonly welcomeMessage: string = ''
  readonly lastAccessed: string = ''
  readonly companyId: number = 0
  readonly userId: number = 0
  readonly firstName: string = ''
  readonly lastName: string = ''
  readonly mobileNumber: string = ''
  readonly email: string = ''
  readonly username: string = ''
  readonly password: string = ''
  readonly permissionLevel: string = ''
  readonly status: string = ''
  readonly createdOn: string = ''
  readonly modifiedOn: string = ''
  readonly createdBy: number = 0

  static login<T extends typeof Resource>(this: T) {
    const endpoint = this.create().extend({
      url: () => '/admin/login',
    })
    return super.create().extend({
      fetch: (credentials: UserCredentials) => {
        return endpoint.fetch.call(endpoint, {}, credentials)
      },
      schema: this,
    })
  }

  static switchCompany<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return super.create().extend({
      fetch: (params: { companyId: Data.ID }) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `/admin/company/select/${params.companyId}?sign_out=false`,
          }),
          {},
          params,
        )
      },
      schema: CompanyEntity,
    })
  }

  static switchDomain<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return super.create().extend({
      fetch: (params: { domainName: string }) => {
        console.log(params)
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `/admin/domain/select/${params.domainName}?sign_out=true`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }
}

export interface AccountRegister {
  firstName: string
  lastName: string
  email: string
  password: string
  advanced: AccountSettingsProps
  verifyCallbackUrl: string
}

type AccountSocialProps = {
  facebook?: string
  twitter?: string
  google?: string
  instagram?: string
  foursquare?: string
  linkedin?: string
  tiktok?: string
}

export type AccountSettingsProps = {
  optInEmail?: boolean
  optInSms?: boolean
  optInUpdate?: boolean
  private?: boolean
}

export type AccountPasswordProps = {
  currentPassword?: string
  newPassword: string
}

export type AccountUpdateProps = {
  firstName?: string
  lastName?: string
  middleName?: string
  birthDate?: Date
  mobileNumber?: string
  email?: string
  username?: string
  companyTitle?: string
  website?: string
  profilePhoto?: string
  gender?: 'U' | 'F' | 'M' | 'T'
  timeZone?: string
  social?: AccountSocialProps
  advanced?: AccountSettingsProps
  password?: AccountPasswordProps
}

export interface AccountIsRegistered {
  customerId: number
  activated: boolean
  firstName: string
  mobileNumber?: string
  email?: string
  isMember: boolean
}


export type AccountFlag = '' | 'createpw' | 'changepw' | 'membership_failed_payment'


export class AccountPhone {
  countryCode?: string
  number: string = ''
  full: string = ''

  static formatted(phone: AccountPhone) {
    if (!phone || !phone.number) return
    if (phone.countryCode) {
      var pattern = /(\d{3})(\d{3})(\d{4})$/
      const formatted = phone.number.replace(pattern, '($1) $2-$3')
      return phone.countryCode ? `+${phone.countryCode} ${formatted}` : formatted
    } else {
      if (phone.number.length > 10) {
        return phone.number.replace(/(\d)(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4')
      } else {
        return phone.number.replace(/(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
      }
    }
  }
}

export interface IAccountActivity {
  readonly id: Data.ID
  readonly date: Date
}

export interface IAccountEntity {
  readonly id: Data.ID
  readonly companyName?: string
  readonly companyTitle?: string
  readonly firstName: string
  readonly middleName?: string
  readonly lastName: string
  readonly fullName?: string
  readonly mobile?: AccountPhone
  readonly phone?: AccountPhone
  readonly birthdate?: Date
  readonly email?: string
  readonly profilePhoto?: string
  // readonly lastActivity?: IAccountActivity
}

class AccountEntity extends ApiResource implements IAccountEntity, Data.Imaginated {
  static readonly urlRoot = '/api/account'
  readonly address: BaseAddress = BaseAddress.fromJS()
  readonly birthdate: Date = new Date()
  readonly homeHouseId: number = 0
  readonly companyId: number = 0
  readonly companyName: string = ''
  readonly companyTitle: string = ''

  readonly email: string = ''
  readonly firstName: string = ''
  readonly fullName: string = ''
  readonly gender: string = ''
  readonly lastName: string = ''
  readonly middleName: string = ''
  readonly mobile?: AccountPhone
  readonly phone?: AccountPhone
  readonly optInEmail: boolean = false
  readonly optInSms: boolean = false
  readonly private: boolean = false
  readonly profilePhoto: string = ''
  readonly totalTouchpoints: number = 0
  readonly userId: number = 0
  readonly website: string = ''

  readonly isMember: boolean = false
  readonly houseAccountEnabled: boolean = false
  readonly flag?: AccountFlag
  readonly advanced?: AccountSettingsProps
  readonly globalNetworks: GlobalNetworkCompany[] = []
  readonly profileImage: string = getMediaUrl(this.profilePhoto) ?? (this.gender === 'F' ? Female : Male)

  get name(): string {
    return this.fullName
  }

  get images(): string[] {
    return [this.image]
  }

  get image(): string {
    return getMediaUrl(this.profilePhoto) ?? (this.gender === 'F' ? Female : Male)
  }

  get initials(): string {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`
  }

  // get defaultAddress(): AccountAddress {
  //   return {
  //     firstName: this.firstName ?? '',
  //     lastName: this.lastName ?? '',
  //     address: this.address ?? '',
  //     address2: this.address2 ?? '',
  //     address3: this.address3 ?? '',
  //     city: this.city ?? '',
  //     state: this.state ?? '',
  //     zipCode: this.zipCode ?? '',
  //     country: this.country ?? '',
  //   } as AccountAddress
  // }

  // get street(): string {
  //   const address = this.defaultAddress
  //   return `${address.address}${address.address2 && `| ${address.address2}`}`
  // }

  // get cityStateZip(): string {
  //   return `${this.city}${this.state && `, ${this.state}`} ${this.zipCode}`
  // }

  static logout() {
    return super.fetch('/signout', {})
  }

  static login<T extends typeof Resource>(this: T) {
    const endpoint = this.create().extend({
      url: () => '/login',
    })
    return super.create().extend({
      fetch: async (credentials: UserCredentials) => {
        return await endpoint.fetch.call(endpoint, {}, credentials).then<AccountEntity>()
      },
      schema: this,
    })
  }

  static isRegistered = (username: string) => {
    return this.fetch(`/api/public/account/registered/${encodeURIComponent(username)}`, {
      method: 'GET',
    }).then<AccountIsRegistered>()
  }



  static get(): AccountEntity {
    return useResource(this.detail(), {})

  }

  static updateProfile<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return super.partialUpdate().extend({
      url: () => `${this.urlRoot}`,
      fetch: (params: AccountUpdateProps) => {
        return endpoint.fetch.call(endpoint, {}, params)
      },
      schema: this,
    })
  }

  static changeHomeHouse<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return super.partialUpdate().extend({
      url: () => `${this.urlRoot}`,
      fetch: (id: Data.ID) => {
        return endpoint.fetch.call(endpoint, {}, {
          homeHouseId: id
        })
      },
      schema: this,
    })
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url: () => `${this.urlRoot}`,
      schema: this,
    })
  }


  static register = async (values: AccountRegister) => {
    return await this.fetch(`/api/public/account/register`, {
      method: 'POST',
      body: JSON.stringify(values),
    }).then<{ activated: boolean, first_name: string }>()
  }

  static activate = async (values: { code: string, password: string, customerId: number }) => {
    return await this.fetch(`/api/public/account/activate`, {
      method: 'POST',
      body: JSON.stringify(values),
    }).then<AccountEntity>()
  }

  static updateProfilePhoto<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return endpoint.extend({
      url: () => '/api/file/account/profile-photo',
      fetch: (file: any) => {
        const formData = new FormData()
        formData.append('File', file)
        return super.postFile('/api/file/account/profile-photo', {
          body: formData,
        })
      },
      schema: this,
    })
  }
}

export class AccountNominateMember extends AbstractSurveyEntity {
  static readonly urlRoot = `/api/account/nominations`

  static get(): AccountNominateMember {
    return useResource(this.detail(), {})
  }
}

export class AccountPersonalDetails extends AbstractSurveyEntity {
  static readonly urlRoot = '/api/account/personal-details'

  static get() {
    return useResource(this.detail(), {})
  }

  static submit<T extends typeof Resource>(this: T) {
    const update = this.partialUpdate()
    return update.extend({
      fetch: async (fieldValues: FormFieldValue[]) => {
        const values = await super.parseValues(fieldValues)
        return update.fetch.call(update, null, values)
        // return update.call(this.partialUpdate(), {}, values)
      },
    })
  }
}

class AccountPurchasedTicket extends ApiResource implements Data.Categorized<CategoryEntity> {
  static readonly urlRoot = '/api/account/events'
  readonly ticketId: number = 0
  readonly eventId: number = 0
  readonly customerId: number = 0
  readonly price: number = 0.0
  readonly status: string = 'purchased'
  readonly categories: CategoryEntity[] = []
  readonly event: EventResource = EventResource.fromJS()
  readonly transactionId: number = 0
  readonly purchaseDate: Date | undefined
  readonly serial: string = ''
  readonly qrCode: string = ''
  readonly scanDate: Date | undefined

  static getByEvent(params: Data.Identified): AccountPurchasedTicket[] {
    return useResource(this.list().extend({ url: () => `${this.urlRoot}/${params.id}` }), {})
  }
}

class Voucher {
  readonly name: string = ''
  readonly description: string = ''
}

class Offer {
  readonly name: string = ''
  readonly description: string = ''
}
class HouseCredit {
  readonly currentValue: number = 0
}

class AccountWallet extends ApiResource {
  static readonly urlRoot = `/api/account/wallet`
  readonly cards: CreditCardEntity[] = []
  readonly banks: BankAccount[] = []

  static schema = {
    cards: [CreditCardEntity],
    banks: [BankAccount],
  }

  static getAll() {
    return useResource(this.detail(), {})
  }

  get merged() {
    return [...this.cards, ...this.banks]
  }

  pk() {
    return 'the_only_one'
  }
}

class AccountAddon extends ApiResource {
  static readonly urlRoot = `/api/account/perks`
  readonly vouchers: Voucher[] = []
  readonly offers: Offer[] = []
  readonly houseCredits: HouseCredit[] = []

  static get(params: Data.Paginated = {}): AccountAddon {
    return useResource(this.detail(), params)
  }
}

export type PatchAccountAdvanced = Partial<{
  current_password: string
  new_password: string
  opt_in_email: boolean
  opt_in_sms: boolean
  private: boolean
}>

class InterestFieldValue {
  readonly selected: boolean = true
  readonly value: string = ''
}

class InterestFieldEntity {
  readonly editable: boolean = true
  readonly group: string = ''
  readonly help: string = ''
  readonly placeholder: string = ''
  readonly type: string = ''
  readonly values: InterestFieldValue[] = []
  readonly value: string = ''
}

class AccountInterests extends ApiResource {
  static urlRoot = `/api/account/attributes`
  readonly name: string = ''
  readonly field: InterestFieldEntity = new InterestFieldEntity()

  static getAll(params: Data.Paginated = {}): AccountInterests[] {
    return useResource(this.list(), params)
  }
}

// class AccountAppointment extends ApiResource {
//   static urlRoot = `/api/account/appointments`
//   readonly schedule: SchedulerEntity = new SchedulerEntity()
//   readonly slot: TimeSlotEntity = new TimeSlotEntity()

//   static getAll(params: Data.Paginated = {}): AccountAppointment[] {
//     return useResource(this.list(), params)
//   }
// }

export type CalendarInvite = {
  google?: string
  ical?: string
  yahoo?: string
}

export type AccountTicketQuery = {
  upcoming?: boolean
  status?: 'cancelled' | 'pending'
} & Data.Paginated

export type TicketTransferRequest = {
  firstName: string
  lastName: string
  email?: string
  mobileNumber?: string
  message?: string
}

export type AccountTicketStatus = 'cancelled' | 'active' | 'purchased' | 'register' | 'scanned' | 'public'

class AccountTicket extends ApiResource implements Data.Identified, Data.Scannable {
  static urlRoot = `/api/account/events/tickets`
  readonly id: number = 0
  readonly ticketId: number = 0
  readonly eventId: number = 0
  readonly guestId: number = 0
  readonly orderItemId: number = 0
  readonly ownerId: number = 0
  readonly serial: string = ''
  readonly status: AccountTicketStatus = 'active'
  readonly price: number = 0
  readonly purchaseDate: string = ''
  readonly scanDate: string = ''
  readonly portalUrl: string = ''
  readonly scanData: ScanData = ScanData.fromJS()
  readonly title: string = ''
  readonly eventTitle: string = ''
  readonly description: string = ''
  readonly calendarInvites: CalendarInvite = {}
  readonly attendee?: AccountEntity
  readonly venue: string = ''
  readonly venueAddress: string = ''
  readonly owner?: AccountEntity
  readonly passbook: Passbook = Passbook.fromJS()
  readonly photo: string = ''
  readonly cancelable: boolean = false
  readonly transferable: boolean = false
  readonly transferred: boolean = false
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS({})
  readonly endDateLocal: DateTz = DateTz.fromJS({})

  static listUrl(params: UrlProps): string {
    return `${this.urlRoot}/${params.id}${params.action ? `/${params.action}` : ''}`
  }

  static url(params: UrlProps): string {
    return `${this.urlRoot}/${params.id}${params.action ? `/${params.action}` : ''}`
  }

  static tickets<T extends typeof Resource>(this: T) {
    const endpoint = this.list()
    return endpoint.extend({
      fetch: (eventId: Data.ID) => {
        return endpoint.fetch.call(endpoint, { id: eventId, action: 'tickets' })
      },
    })
  }

  static transfer<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return endpoint.extend({
      fetch: async (id: Data.ID, request: TicketTransferRequest) => {
        return endpoint.fetch.call(
          endpoint,
          {
            id: id,
            action: 'transfer',
          },
          request,
        )
      },
    })
  }

  static fetchTransferred<T extends typeof Resource>(this: T) {
    const endpoint = this.detail()
    return endpoint.extend({
      fetch: (serial: string) =>
        endpoint.fetch
          .call(
            endpoint.extend({
              url: () => `/api/public/passes/guest-ticket/${serial}`,
            }),
            {},
          )
          .then<AccountTicket>(),
      schema: AccountTicket,
    })
  }

  static transferred(ticket: AccountTicket): boolean {
    return ticket.guestId !== ticket.ownerId
  }
}

class AccountEvent extends ApiResource {
  static urlRoot = `/api/account/events`
  readonly id: Data.ID = 0
  readonly title: string = ''
  readonly description: string = ''
  readonly summary: string = ''
  readonly venue: string = ''
  readonly venueAddress?: string
  readonly graphic: string = ''
  readonly photos: ImageEntity[] = []
  readonly type: string = ''
  readonly purchasedTickets: AccountTicket[] = []
  readonly survey?: SurveyEndpointResource
  readonly timezone_id: string = ''
  readonly uid: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS({})
  readonly endDateLocal: DateTz = DateTz.fromJS({})
  readonly registered: boolean = false
  readonly company: CompanyEntity = CompanyEntity.fromJS({})
  readonly settings: EventSettings = {
    transferEnabled: false,
    cancelEnabled: false,
    socialEnabled: false,
    printEnabled: false,
    calendarEnabled: false,
    buttonText: '',
    callToAction: '',
    verb: '',
  }

  static schema = {
    purchasedTickets: [AccountTicket],
    survey: SurveyEndpointResource,
  }

  get hasActiveTickets(): boolean {
    return this.activeTickets && this.activeTickets.length > 0
  }

  get activeTickets(): AccountTicket[] {
    return this.purchasedTickets.filter((t) => t.status !== 'cancelled' && t.status !== 'scanned')
  }

  get ticketQty(): number {
    return this.activeTickets ? this.activeTickets.length : 0
  }

  get transferredQty(): number {
    return this.activeTickets.filter((p) => p.guestId !== p.ownerId).length
  }

  static upcoming<T extends typeof Resource>(this: T) {
    const endpoint = this.list()
    return endpoint.extend({
      url: () => `${this.urlRoot}?upcoming=true`,
    })
  }

  static cancel<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return endpoint.extend({
      fetch: (id: Data.ID) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/cancel`,
          }),
          { id: id, action: 'cancel' },
          null,
        )
      },
      schema: AccountEventCancelResponse,
    })
  }

  static getPaginated(params: AccountTicketQuery = {}): SchemaPaginated<AccountEvent> {
    return useResource(this.paginated(), params)
  }

  static get(id: Data.ID) {
    return useResource(this.detail(), { id: id })
  }

  static getAll(params: Data.Paginated = {}) {
    return useResource(this.list(), {})
  }
}

class AccountEventCancelResponse extends TransactionRefund {
  readonly booking?: AccountEvent
  readonly orders?: OrderEntity[]
  static schema = {
    orders: [OrderEntity],
    booking: AccountEvent,
  }
}

export class TicketTransferResponse extends Entity {
  message: string = ''
  ticket: AccountTicket = AccountTicket.fromJS()

  static schema = {
    ticket: AccountTicket,
  }

  pk(): string {
    return `${this.ticket.id}`
  }
}

export type AccountBookingStatus =
  | ''
  | 'booked'
  | 'cancelled'
  | 'confirmed'
  | 'arrived'
  | 'completed'
  | 'pending'
  | 'rebook'

type BookingQuery = {
  startDate?: Date | string
  status?: AccountBookingStatus
} & Data.Paginated

type UpdateAccountBooking = {
  surveyResponse?: FormFieldValue[]
}

export class BookingSurvey extends SurveyEntity {
  pk(parent?, key?): string | undefined {
    return `${parent.id}`
  }

  static get key() {
    return 'BookingSurvey'
  }
}

class AccountBooking extends ApiResource {
  readonly id: Data.ID = 0
  readonly blockType: string = ''
  readonly slot: TimeSlotEntity = TimeSlotEntity.fromJS()
  readonly status: AccountBookingStatus = 'booked'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly location?: BusinessLocation
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly survey?: SurveyEndpoint
  readonly type: ScheduleType = 'appointment'
  readonly guest?: IAccountEntity
  readonly owner?: IAccountEntity
  pk() {
    return `${this.id}`
  }

  static get key() {
    return `AccountBooking`
  }
}

class AccountReservation extends AccountBooking {
  static urlRoot = `/api/account/reservations`

  readonly id: Data.ID = ''
  readonly schedule: ReservationEntity = ReservationEntity.fromJS()
  readonly blockType: string = ''
  readonly slot: TimeSlotEntity = TimeSlotEntity.fromJS()
  readonly status: AccountBookingStatus = 'pending'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly company: CompanyEntity = CompanyEntity.fromJS({})
  readonly type: ScheduleType = 'reservation'

  get locationHours() {
    return this.location && this.location.hours ? this.location.hours.keyValue : undefined
  }

  static getById(id: Data.ID) {
    return useResource(this.detail(), { id: id })
  }

  // Default to todays date
  static getAll(params: BookingQuery = { startDate: moment().format('MM/DD/yyyy') }) {
    return useResource(this.list(), params)
  }

  static patch<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return endpoint.extend({
      fetch: async (id: Data.ID, params: UpdateAccountBooking) => {
        const values = params.surveyResponse ? await SurveyEntity.parseValues(params.surveyResponse) : undefined
        return endpoint.fetch
          .call(
            endpoint.extend({
              url: () => `${this.urlRoot}/${id}`,
            }),
            null,
            {
              ...params,
              surveyResponse: values,
            },
          )
          .then<AccountReservation>()
      },
      schema: this,
    })
  }

  static cancel<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return endpoint.extend({
      fetch: (id: Data.ID) =>
        super
          .fetch(`${this.urlRoot}/${id}/cancel`, {
            method: 'POST',
          })
          .then<CancelBookingResponse>(),
      schema: {
        booking: this,
      },
    })
  }
}

class AccountAppointment extends AccountBooking {
  static urlRoot = `/api/account/appointments`
  readonly id: Data.ID = ''
  readonly schedule: ReservationEntity = ReservationEntity.fromJS()
  readonly blockType: string = ''
  readonly slot: TimeSlotEntity = TimeSlotEntity.fromJS()
  readonly status: AccountBookingStatus = 'pending'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly company: CompanyEntity = CompanyEntity.fromJS({})
  readonly type: ScheduleType = 'appointment'

  static schema = {
    survey: SurveyEndpointResource,
  }

  get locationHours() {
    return this.location && this.location.hours ? this.location.hours.keyValue : undefined
  }

  static getById(id: Data.ID) {
    return useResource(this.detail(), { id: id })
  }

  // Default to todays date
  static getAll(params: BookingQuery = { startDate: moment().format('MM/DD/yyyy') }) {
    return useResource(this.list(), params)
  }

  static cancel<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return endpoint.extend({
      fetch: (id: Data.ID) =>
        super
          .fetch(`${this.urlRoot}/${id}/cancel`, {
            method: 'POST',
          })
          .then<CancelBookingResponse>(),
      schema: {
        booking: this,
      },
    })
  }

  static remove<T extends typeof Resource>(this: T) {
    return new Endpoint(({ id }) => Promise.resolve({ id }), {
      schema: new schema.Delete(this),
    })
  }
}

class CancelBookingResponse extends Entity {
  readonly id: Data.ID = 0
  readonly orderId?: Data.ID
  readonly order?: OrderEntity
  readonly refunds?: TransactionRefundItem[]
  readonly amount: number = 0
  readonly message: string = ''
  readonly success: boolean = false
  readonly booking?: AccountReservation | AccountAppointment

  pk() {
    return `${this.id}`
  }
  get reservation() {
    return this.booking && this.booking.type === 'reservation' ? (this.booking as AccountReservation) : undefined
  }

  get appointment() {
    return this.booking && this.booking.type === 'appointment' ? (this.booking as AccountAppointment) : undefined
  }
  static schema = {
    order: OrderEntity,
    appointment: AccountAppointment,
    reservation: AccountReservation,
  }
}

export type AccountPerkStatus = '' | 'active' | 'archived' | 'redeemed' | 'registered' | 'transferred'

class AbstractAccountPerk extends ApiResource implements Data.Identified, Data.Scannable {
  static urlRoot = `/api/account/perks`
  readonly passId: number = 0
  readonly ownerId: number = 0
  readonly serial: string = ''
  readonly expiresOn: Date = new Date()
  readonly validOn: Date = new Date()
  readonly status: AccountPerkStatus = ''
  readonly pass: PassEntity = PassEntity.fromJS({})
  readonly customer?: AccountEntity
  readonly createdOn: Date = new Date()
  readonly transferedOn: Date = new Date()
  readonly scanData: ScanData = ScanData.fromJS()
  readonly requiresPin: boolean = false
  readonly transferred: boolean = false
  readonly transferrable: boolean = false
  readonly owner: AccountEntity = AccountEntity.fromJS({})

  static schema = {
    customer: AccountEntity,
  }

  get image(): string {
    return getMediaUrl(this.pass.graphic)
  }

  get images(): [string] {
    return [this.pass.graphic]
  }
}

class AccountOffer extends AbstractAccountPerk implements Data.Imaginated {
  static urlRoot = `/api/account/perks/offers`

  get image(): string {
    return getMediaUrl(this.pass.graphic)
  }

  get images(): [string] {
    return [this.pass.graphic]
  }

  static getAll(params: Data.Paginated = {}): AccountOffer[] {
    return useResource(this.list(), params)
  }
}

type RedeemVoucherRequest = {
  redemptionPin?: number
  latitude?: string
  longitude?: string
  via: string
  notes?: string
}

class AccountVoucher extends AbstractAccountPerk implements Data.Imaginated {
  static readonly urlRoot = `/api/account/perks/vouchers`
  readonly passbook: Passbook = Passbook.fromJS()

  get image(): string {
    return getMediaUrl(this.pass.graphic)
  }

  get images(): [string] {
    return [this.pass.graphic]
  }

  static getAll(params: Data.Paginated & Record<string, any> = {}): AccountVoucher[] {
    return useResource(this.list(), params)
  }

  static redeem<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (id, params: RedeemVoucherRequest) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/redeem`,
          }),
          {},
          params,
        ),
      schema: AccountVoucher,
    })
  }

  static fetchReceivedVoucher<T extends typeof Resource>(this: T) {
    const endpoint = this.detail()
    return endpoint.extend({
      fetch: (params: { id: Data.ID }) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `/api/public/vouchers/${params.id}`,
          }),
          {},
        ),
      schema: AccountVoucher,
    })
  }

  static transfer<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (id: Data.ID, request: TicketTransferRequest) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/transfer`,
          }),
          {},
          request,
        ),
    })
  }
}

export type SendGuestPassRequest = {
  firstName: string
  lastName: string
  mobileNumber?: string
  mobile?: AccountPhone
  email?: string
  message?: string
}

class AccountPerkEntity extends AbstractAccountPerk {
  static schema = {
    customer: AccountEntity,
  }
}

export type AccountGuestPassStatus = '' | 'active' | 'archived' | 'redeemed' | 'registered' | 'transferred'
export type AccountGuestPassOwner = {
  firstName: string
  lastName: string
}

class AccountGuestPasses extends Entity {
  readonly available: AccountGuestPass[] = []
  readonly used: AccountGuestPass[] = []
  pk() {
    return 'the_only_one'
  }
}



class AccountGuestPass extends ApiResource implements Data.Scannable {
  static readonly urlRoot = `/api/account/perks/guest-passes`
  readonly id: Data.ID = ''
  readonly passId: number = 0
  readonly ownerId: number = 0
  readonly serial: string = ''
  readonly expiresOn: Date = new Date()
  readonly expired: boolean = false
  readonly validOn: Date = new Date()
  readonly status: AccountGuestPassStatus = ''
  readonly pass: PassEntity = PassEntity.fromJS({})
  readonly pkPass: string = ''
  readonly passbook: Passbook = Passbook.fromJS()
  readonly createdOn: Date = new Date()
  readonly transferredOn: Date = new Date()
  readonly scanData: ScanData = ScanData.fromJS()
  readonly guest?: AccountEntity
  readonly owner?: AccountEntity
  readonly survey?: SurveyEndpointResource
  readonly theme?: {
    foregroundColor: string
    backgroundColor: string
  }
  static schema = {
    survey: SurveyEndpointResource,
  }

  static grouped<T extends typeof Resource>(this: T) {
    const endpoint = this.list()
    return this.list().extend({
      fetch: () => endpoint.fetch.call(endpoint, {}).then<AccountGuestPasses>(),
      schema: AccountGuestPasses,
    })
  }

  static transfer<T extends typeof Resource>(this: T) {
    const transferredList = this.list().extend({
      key: () => 'transferred'
    })
    const availableList = this.list().extend({
      key: () => 'available'
    })
    return this.create().extend({
      url: () => `${this.urlRoot}/transfer`,
      schema: AccountGuestPass,
      update: (id: Data.ID) => {
        return {
          [transferredList.key('transferred')]: (ids: string[] = []) => [id, ...ids],
          [availableList.key('available')]: (ids: string[] = []) => [...ids.slice(0, ids.length - 1)]
        }
      }
    })
  }

  static resend<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (id: Data.ID) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}`,
          }),
          {},
          { id: id },
        ),
      schema: AccountGuestPasses,
    })
  }

  static fetchReceivedPass<T extends typeof Resource>(this: T) {
    const endpoint = this.detail()
    return endpoint.extend({
      fetch: (params: { id: Data.ID; pin: Data.ID }) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `/api/public/passes/guest-pass/${params.id}/${params.pin}`,
          }),
          {},
        ),
      schema: AccountGuestPass,
    })
  }

  static available<T extends typeof Resource>(this: T) {
    const endpoint = this.list().extend({
      url: () => `${this.urlRoot}?status=available`,
    })
    return endpoint.extend({
      fetch: () =>
        endpoint.fetch.call(
          endpoint, {},
        ),
      key: () => 'available',
      schema: [AccountGuestPass],
    })
  }

  static transferred<T extends typeof Resource>(this: T) {
    const endpoint = this.list().extend({
      url: () => `${this.urlRoot}?status=transferred`,
    })
    return endpoint.extend({
      fetch: () =>
        endpoint.fetch.call(
          endpoint,
          {},
        ),
      key: () => 'transferred',
      schema: [AccountGuestPass],
    })
  }

  static getPkPass<T extends Resource>(this: T) { }

  static getAll(): AccountGuestPasses {
    return useResource(this.grouped(), {})
  }

  static getById(params: Data.Identified): AccountGuestPass {
    return useResource(this.detail(), params)
  }

  get image(): string {
    return this.pass.graphic
  }

  get images(): [string] {
    return [this.pass.graphic]
  }
}


export type AccountHouseCreditType = '' | 'none' | 'appointment' | 'category' | 'event' | 'order' | 'reservation'

type AccountHouseCreditGroup = {
  [key in AccountHouseCreditType]: number
}

export type HouseCreditPaymentMethod = {
  id: number
  amount: number
}

class AccountHouseCredit extends ApiResource {
  static urlRoot = `/api/account/perks/house-credits`
  readonly initialValue: number = 0
  readonly currentValue: number = 0
  readonly expiresOn: Date = new Date()
  readonly serial: string = ''
  readonly pin: string = ''
  readonly status: string = ''
  readonly expires: boolean = false
  readonly transactions: TransactionEntity[] = []
  readonly referenceType: AccountHouseCreditType = 'none'
  readonly referenceId: number = 0

  get expired(): boolean {
    return new Date(this.expiresOn) <= new Date()
  }

  static getTransactions(params: Data.Identified): AccountHouseCredit {
    return useResource(this.detail(), params)
  }

  static getById(params: Data.Identified): AccountHouseCredit {
    return useResource(this.detail(), params)
  }

  static getAll(params: Data.Paginated = {}): AccountHouseCredit[] {
    return useResource(this.list(), params)
  }

  static filterbyType(houseCredits: AccountHouseCredit[], type: AccountHouseCreditType): AccountHouseCredit[] | [] {
    if (houseCredits === undefined) return []
    const filter = houseCredits.filter((item) => item.referenceType === type)
    return (filter && filter.length) > 0 ? filter : []
  }

  static ResolveHouseCreditToPayment(houseCredits: AccountHouseCredit[], amount: number): PaymentMethod[] {
    if (!houseCredits || houseCredits.length === 0) return []
    var payment_methods: PaymentMethod[] = []
    var remaining_amount = amount
    houseCredits.some((credit) => {
      if (credit.currentValue === 0) return

      const apply_amount = remaining_amount > credit.currentValue ? credit.currentValue : remaining_amount
      payment_methods.push(
        new PaymentMethod({
          id: credit.id,
          amount: apply_amount,
          type: 'housecredit',
        }),
      )
      remaining_amount -= apply_amount
      if (remaining_amount === 0) return true
    })

    return payment_methods
  }

  static ResolveHouseCreditGroup(houseCredits: AccountHouseCredit[]): AccountHouseCreditGroup | undefined {
    if (houseCredits === undefined || houseCredits.length === 0) return undefined
    const appointments = this.filterbyType(houseCredits, 'appointment')
    const category = this.filterbyType(houseCredits, 'category')
    const event = this.filterbyType(houseCredits, 'event')
    const reservation = this.filterbyType(houseCredits, 'reservation')
    const order = this.filterbyType(houseCredits, 'order')
    const none = this.filterbyType(houseCredits, '')
    return {
      appointment:
        appointments && appointments.length > 0
          ? appointments.map((item) => item.currentValue).reduce((prev, next) => prev + next)
          : 0,
      category:
        category && category.length > 0
          ? category.map((item) => item.currentValue).reduce((prev, next) => prev + next)
          : 0,
      event: event && event.length > 0 ? event.map((item) => item.currentValue).reduce((prev, next) => prev + next) : 0,
      order: order && order.length > 0 ? order.map((item) => item.currentValue).reduce((prev, next) => prev + next) : 0,
      reservation:
        reservation && reservation.length > 0
          ? reservation.map((item) => item.currentValue).reduce((prev, next) => prev + next)
          : 0,
      none: none && none.length > 0 ? none.map((item) => item.currentValue).reduce((prev, next) => prev + next) : 0,
    } as AccountHouseCreditGroup
  }
}

class AccountPerks extends ApiResource {
  static urlRoot = `/api/account/perks`
  readonly houseCredits: AccountHouseCredit[] = []
  readonly vouchers: AccountVoucher[] = []

  static getAll(params: Data.Paginated = {}): AccountPerks {
    return useResource(this.detail(), params)
  }
}

export type ScheduleItem = Data.Named & Data.Identified & Pick<Data.Temporal, 'startDate'> & Data.Typed

export type GroupedPurchasedTicket = {
  tickets: AccountTicket[]
  event: EventResource
} & Data.Identified

// class My {
//   static appointments(params: Data.Paginated): ScheduleItem[] {
//     return AccountAppointment.getAll(params)
//       .map((it) => ({
//         id: it.id,
//         name: it.schedule.name,
//         type: it.schedule.group,
//         startDate: it.slot.startDate,
//       }))
//       .filter(ApiResource.distinct)
//   }

//   static upcoming(): AccountUpcoming {
//     return useResource(AccountUpcoming.detail(), {})
//   }

//   static reservations(params: Data.Paginated): ScheduleItem[] {
//     return AccountReservation.getAll(params)
//       .map((it) => ({
//         id: it.id,
//         name: it.schedule.name,
//         type: 'hotel room',
//         startDate: it.slot.startDate,
//       }))
//       .filter(ApiResource.distinct)
//   }
// }

OrderItem.schema = {
  booking: AccountBooking,
  survey: SurveyEndpoint,
  reservation: AccountReservation,
  appointment: AccountAppointment,
  ticket: AccountTicket,
}

export {
  AbstractAccountPerk, AccountAddon, AccountAppointment, AccountEntity, AccountEvent, AccountEventCancelResponse, AccountGuestPass,
  AccountGuestPasses, AccountHouseCredit, AccountInterests,
  AccountOffer, AccountPerkEntity, AccountPerks, AccountPurchasedTicket, AccountReservation, AccountTicket, AccountVoucher,
  AccountWallet, AdminEntity, CancelBookingResponse
}

