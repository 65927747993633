import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import advancedFormat from "dayjs/plugin/advancedFormat"
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { tz } from 'moment-timezone'
import 'moment/locale/ja'
tz.load({ version: '2021a', links: [], zones: [] })
dayjs.locale('en')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(duration)
dayjs.extend(advancedFormat)

export { dayjs, dayjs as default }
