import { Col, Row, Table } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import ExpandableList from 'src/sdk/components/collapse/ExpandableList'
import { Button } from 'src/sdk/components/form'
import NewLineIcon from 'src/sdk/components/icon/es/filled/symbol/NewLine'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import DownloadPdfButton from 'src/sdk/components/pdf/DownloadPdfButton'
import { BreakpointMin } from 'src/sdk/components/screen/Breakpoint'
import { Tag } from 'src/sdk/components/tag'
import { TagType } from 'src/sdk/components/tag/Tag'
import { Money, Title } from 'src/sdk/components/text'
import { withPrefix } from 'src/sdk/contexts/Config'
import { DrawerDetailProvider, useDrawerDetail } from 'src/sdk/contexts/detail-drawer/DrawerDetailProvider'
import { StatementEntity, TransactionEntity } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'
import { Text } from '../../../../sdk/components/text/Text'
import { I18nProvider, useI18n } from '../../../../sdk/contexts/I18n'
import { StatementPDF } from './StatementPDF'
import './TransactionsTable.less'

type TransactionTableProps = {
  statement: StatementEntity
  transactions?: TransactionEntity[]
}

const TransactionDescription: FC<Data.Source<TransactionEntity>> = ({ data }) => {
  const { showDetails } = useDrawerDetail()
  const { formatDate: dateFormatter } = useIntl()
  let description: string = data.description?.replace('_', ' ') ?? ''
  if (data.type === 'order' && data.order) {
    const order = data.order
    let title = `Order #${order.id}`
    switch (order.via) {
      case 'connectedpos':
        title = 'Dining'
        break
      case 'pms':
        title = 'Hotel'
        break
      case 'spa':
        title = 'Spa'
        break
      default:
        title = `Order #${order.id}`
    }
    if (order.businessLocation) {
      title = `${title} at ${order.businessLocation.name}`
    }
    return (
      <ExpandableList title={title} data={data.order.items.filter((i) => i.price > 0).map((i) => i.title)}>
        <Button type={'text'} theme={'primary'} onClick={() => showDetails('order', order.id)}>
          View Order Details
        </Button>
      </ExpandableList>
    )
  }
  if (data.description) {
    description = data.description
  } else if (data.data && data.data.some((d) => d.referenceType === data.type)) {
    description = data.data.find((d) => d.referenceType === data.type)?.referenceItem ?? description
  } else if (data.orderId) {
    description = `Order #${data.orderId}`
  }
  if (description === '') description = data.type
  return Capitalize(description)
}

export const TransactionStatusTag = (transaction: TransactionEntity) => {
  let type: TagType = 'default'
  let title: string = ''
  if (transaction.refundedAmount === transaction.amount) {
    title = 'Refunded'
    type = 'warning'
  } else if (transaction.status === 'processed') {
    title = 'Paid'
    type = 'primary'
  } else if (transaction.status === 'invoiced') {
    if (transaction.amountDue > 0) {
      title = 'Due'
      type = 'info'
    } else {
      title = 'Invoiced'
      type = 'info'
    }
  } else if (transaction.status === 'refunded') {
    title = 'Refunded'
    type = 'warning'
  }
  return (
    <Tag size={'small'} type={type}>
      {title}
    </Tag>
  )
}

const columnsDesktop = [
  {
    title: <Text>ID</Text>,
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: <Text>Date</Text>,
    dataIndex: 'chargedOn',
    key: 'Date',
    render: (value: Date) => <FormattedDate value={value} />,
    sorter: (a, b) => (moment(a.chargedOn).isBefore(moment(b.chargedOn)) ? -1 : 1),
  },
  {
    title: <Text>Status</Text>,
    key: 'status',
    render: (transaction: TransactionEntity) => TransactionStatusTag(transaction),
    // sorter: (a, b) => (a.status < b.status ? -1 : 0),
  },
  {
    title: <Text>Description</Text>,
    key: 'description',
    render: (transaction: TransactionEntity) => <TransactionDescription data={transaction} />,
  },
  {
    title: <Text>Payment Type</Text>,
    key: 'paymentType',
    render: (transaction: TransactionEntity) =>
      transaction.amountDue > 0
        ? ''
        : transaction.paymentType === 'invoice'
        ? 'House Account'
        : transaction.brand
        ? `${Capitalize(transaction.brand)} ${transaction.lastFour && '**' + transaction.lastFour}`
        : '',
  },
  {
    title: <Text>Price</Text>,
    dataIndex: 'amount',
    key: 'price',
    render: (price: number, { tax, currency }: TransactionEntity) => <Money currency={currency}>{price - tax}</Money>,
  },
  {
    title: <Text>Tax</Text>,
    dataIndex: 'tax',
    key: 'tax',
    render: (tax: number, { currency }: TransactionEntity) => <Money currency={currency}>{tax}</Money>,
  },
  {
    title: <Text>Total</Text>,
    dataIndex: 'amount',
    key: 'Total',
    align: 'right' as 'right',
    render: (data: number, { currency }: TransactionEntity) => <Money currency={currency}>{data}</Money>,
    sorter: (a: TransactionEntity, b: TransactionEntity) => a.amount - b.amount,
  },
]

const columnsMobile = [
  {
    title: <Text>Date</Text>,
    dataIndex: 'chargedOn',
    key: 'chargedOn',
    render: (value: Date) => <FormattedDate value={value} />,
  },
  {
    title: <Text>Description</Text>,
    key: 'description',
    render: (transaction: TransactionEntity) => <TransactionDescription data={transaction} />,
  },
  {
    title: <Text>Total</Text>,
    dataIndex: 'amount',
    key: 'Total',
    align: 'right' as 'right',
    render: (data: number, { currency }: TransactionEntity) => <Money currency={currency}>{data}</Money>,
  },
]

export const StatementTransactionsTable: FC<TransactionTableProps> = ({ statement, transactions }) => {
  const logo = null
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.LG })
  const { formatDate } = useIntl()
  const filteredTransactions = transactions?.filter((t) => {
    return !(
      t.linkedTransactionId &&
      t.status === 'refunded' &&
      transactions.findIndex((t2) => t2.id === t.linkedTransactionId) !== -1
    )
  })
  const { money, date } = useI18n()
  const LinkedTransactionRender = (data: TransactionEntity) => {
    return (
      <VerticalSpace>
        {data.linked
          .filter((t) => t.status === 'refunded')
          .map((t) => (
            <HorizontalSpace key={t.id} align={'center'} style={{ marginLeft: 8 }}>
              <NewLineIcon style={{ transform: 'scaleX(-1)', fontSize: 20 }} />
              <Tag size={'small'} type={'warning'}>
                {t.amount === data.amount ? 'Refunded' : 'Partial Refund'}
              </Tag>
              <VerticalSpace>
                <Text strong context={{ id: t.id }}>
                  {'Transaction ID: {id}'}
                </Text>
                <Text
                  context={{
                    amount: money(t.amount, data.currency),
                    date: date(t.chargedOn),
                    card: `${Capitalize(t.brand)} **${t.lastFour}`,
                  }}
                >
                  {'A refund in the amount of {amount} was issued on {date} to {card}'}
                </Text>
              </VerticalSpace>
            </HorizontalSpace>
          ))}
      </VerticalSpace>
    )
  }

  return (
    <DrawerDetailProvider>
      <VerticalSpace size={16}>
        <Row align={'middle'} justify={'space-between'} gutter={[20, 40]}>
          <Col xs={{ order: 2 }} lg={{ order: 1 }}>
            <Title type={'secondary'} level={4}>
              Transaction Details
            </Title>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 5, order: 2 }}>
            <DownloadPdfButton<StatementEntity>
              block
              document={({ company, intlFormatter }) => (
                <I18nProvider>
                  <StatementPDF
                    dateFormatter={formatDate}
                    statement={statement}
                    transactions={filteredTransactions}
                    company={company}
                    intlFormatter={intlFormatter}
                  />
                </I18nProvider>
              )}
            />
          </Col>
        </Row>
        <Table
          key={'statement-transactions-table'}
          className={withPrefix('transactions-table')}
          columns={isDesktop ? columnsDesktop : columnsMobile}
          dataSource={filteredTransactions}
          rowKey={(item) => item.id}
          expandable={{
            expandedRowRender: LinkedTransactionRender,
            rowExpandable: (transaction) => transaction.linked?.some((t) => t.status === 'refunded'),
          }}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </VerticalSpace>
    </DrawerDetailProvider>
  )
}
