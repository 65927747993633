import { Drawer as AntDrawer, Typography } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { ImageEntity } from 'src/sdk/datasource/media'
import { CardCarousel } from '../carousel/Carousel'
import { withFullScreenDrawer } from '../drawer'
import IvyIcon from '../icon'
import { CenteredContent } from '../layout'
import { Flex } from '../layout/Grid'
import { OverlayLoader } from '../loader'
import { Breakpoint } from '../screen/Breakpoint'
import FullPageGallery from './FullPageGallery'
import './Gallery.less'
import { Title } from '../text'

const Drawer = withFullScreenDrawer(AntDrawer)

interface GalleryProps {
  media: ImageEntity[]
  title?: string | ReactNode
  itemsToShow?: number
  className?: string
  showTitle?: boolean
  isModal?: boolean
  type?: 'full' | 'modal'
}
interface GalleryThumbnailProps {
  image: ImageEntity
  width?: number
  active?: boolean
  onClick?: (image: ImageEntity) => void
  title?: string
}

const GalleryThumbnail: FC<GalleryThumbnailProps> = ({ image, onClick, title, active }) => {
  const [loading, setLoading] = useState(true)
  return (
    <div
      className={withPrefix('gallery-item', onClick ? 'gallery-item-click' : '', active ? 'gallery-item-active' : '')}
    >
      <OverlayLoader loading={loading} style={{ height: '100%' }}>
        <img
          src={image.url}
          onLoad={() => setLoading(false)}
          className={withPrefix('gallery-item-image')}
          onClick={() => onClick && onClick(image)}
        />
        {title && (
          <div className={withPrefix('gallery-item-title')}>
            <Title level={5} className={withPrefix('gallery-item-title-text')}>
              {title}
            </Title>
          </div>
        )}
        <div className={withPrefix('gallery-item-preview')}>
          <div className={withPrefix('gallery-item-preview-content')}>
            <IvyIcon type={'application/eye'} />
            Preview
          </div>
        </div>
      </OverlayLoader>
    </div>
  )
}
const GalleryModal: FC<GalleryProps> = ({ ...props }) => {
  return <Gallery isModal showTitle className={withPrefix('gallery-modal-container')} {...props} />
}

const Gallery: FC<GalleryProps> = ({
  media,
  title,
  type = 'modal',
  isModal = false,
  showTitle = false,
  itemsToShow = 2,
  className,
}) => {
  const { setModal } = useDynamicDrawer()
  const [main, setMain] = useState<ImageEntity>(media.find((m) => m.main) ?? media[0])
  const [visible, setVisible] = useState(false)
  const [thumbnails, setThumbnails] = useState<ImageEntity[]>(
    media.filter((i) => !i.main).slice(0, itemsToShow <= media.length ? itemsToShow : media.length),
  )
  const handleClick = (image: ImageEntity) => {
    setThumbnails((prevState) => [...prevState.filter((item) => item.url !== image.url), main])
    setMain(image)
  }

  const openGalleryView = () => {
    switch (type) {
      case 'full':
        setVisible(true)
        break
      case 'modal':
        setModal({
          content: <GalleryModal media={media} />,
          className: withPrefix('gallery-modal'),
          footer: null,
        })
        break
    }
  }

  return (
    <Flex
      size={!isModal ? 4 : 0}
      direction={'vertical'}
      className={withPrefix('gallery-container', className ? className : '')}
    >
      <div className={withPrefix('gallery-main')} onClick={openGalleryView}>
        <GalleryThumbnail
          image={main}
          onClick={!isModal ? openGalleryView : undefined}
          title={showTitle ? main.title : undefined}
        />
      </div>
      {type === 'full' && (
        <Drawer
          className={withPrefix('full-page-gallery-drawer')}
          destroyOnClose
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <CenteredContent width={1200}>
            <FullPageGallery media={media} title={title} />
          </CenteredContent>
        </Drawer>
      )}
      {isModal ? (
        <>
          <div className={withPrefix('gallery-nav-container')} />
          <CardCarousel
            navigation
            dots
            dotPosition={'top'}
            slidesToShow={3}
            slidesToScroll={3}
            responsive={[
              {
                breakpoint: Breakpoint.MD,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
            className={withPrefix('gallery-carousel')}
          >
            {media.map((image) => (
              <GalleryThumbnail active={main.url === image.url} key={image.url} image={image} onClick={handleClick} />
            ))}
          </CardCarousel>
        </>
      ) : (
        <Flex direction={'horizontal'} size={!isModal ? 4 : 12} className={withPrefix('gallery-items')}>
          {thumbnails.map((image) => (
            <GalleryThumbnail key={image.url} image={image} onClick={openGalleryView} />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export { Gallery }
