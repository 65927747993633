import { Input, notification, Typography } from 'antd'
import React, { useEffect } from 'react'
import { FC, useLayoutEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Button, Item } from '../form'
import { HexColorPicker } from 'react-colorful'
import { GridList } from '../list'
import { DashCase } from 'src/sdk/helpers/strings'
import { HorizontalSpace, VerticalSpace } from '../layout'
import IvyIcon from '../icon'
import { ThemeProp, useDebug } from './DebugProvider'
import { Split } from '../layout/Grid'
import './Debug.less'
import { Text } from '../text/Text'

const { Search } = Input

type DebugColorItemProps = {
  item: ThemeProp
  active: boolean
  onClick: (name: string) => void
  onChange: (newValue: string) => void
}

const DebugColorItem: FC<DebugColorItemProps> = ({ item, active, onClick, onChange }) => {
  const popOver = React.createRef<HTMLDivElement>()
  const [popoverPosition, setPopoverPosition] = useState<'top' | 'bottom'>('bottom')
  const [prevColor, setPrevColor] = useState(item.value)
  const [currentValue, setCurrentValue] = useState(item.value)
  const [newValue, setNewValue] = useState<string>()
  useLayoutEffect(() => {
    const box = popOver.current?.getBoundingClientRect()
    if (box) {
      if (box.bottom > window.innerHeight && popoverPosition === 'bottom') {
        setPopoverPosition('top')
      }
    }
  }, [active])

  useLayoutEffect(() => {
    setCurrentValue(item.value)
  }, [item])

  useLayoutEffect(() => {
    const delay = setTimeout(() => {
      if (!newValue) return
      onChange(newValue)
      setPrevColor(currentValue)
      setCurrentValue(newValue)
    }, 250)
    return () => clearTimeout(delay)
  }, [newValue])

  return (
    <div className={withPrefix('debug-color-item', active ? 'debug-color-item-active' : '')}>
      <Item label={DashCase(item.name)}>
        <Input
          name={item.name}
          value={currentValue}
          onChange={(evt) => setNewValue(evt.target.value)}
          suffix={
            item.isColor && (
              <div
                onClick={() => onClick(item.name)}
                className={withPrefix('debug-color-box')}
                style={{ backgroundColor: currentValue }}
              />
            )
          }
        />
      </Item>
      {active && item.isColor && (
        <div ref={popOver} className={withPrefix('debug-color-popover', `debug-color-popover-${popoverPosition}`)}>
          <HexColorPicker color={currentValue} onChange={setNewValue} />
        </div>
      )}
    </div>
  )
}

function hasParentClass(child, className) {
  if (child.className.split(' ').indexOf(className) >= 0) return true
  try {
    return child.parentNode && hasParentClass(child.parentNode, className)
  } catch (TypeError) {
    return false
  }
}

const DebugProps: FC = () => {
  const { setValue, reset, getJson, props, changes, version, setVersion } = useDebug()
  const [filtered, setFiltered] = useState<string>()
  const [activeItem, setActiveItem] = useState<string>()

  const HandleReset = () => {
    reset()
    notification.success({
      message: <Text strong>Colors Reset</Text>,
      description: 'Configuration has been reverted to the original state',
    })
  }

  const HandleCopy = () => {
    navigator.clipboard.writeText(getJson())
    notification.success({
      message: <Text strong>Copied</Text>,
      description: 'Configuration JSON has been copied to your clipboard',
    })
  }

  const HandleCardClick = (evt) => {
    if (activeItem !== undefined && !hasParentClass(evt.target, 'ant-debug-color-item')) {
      setActiveItem(undefined)
    }
  }

  return (
    <VerticalSpace size={12} onClick={HandleCardClick}>
      <div className={withPrefix('debug-color-actions')}>
        <VerticalSpace>
          <Search
            onChange={(e) => setFiltered(e.target.value)}
            onSearch={setFiltered}
            enterButton={'Search'}
            className={withPrefix('debug-color-actions-search')}
            allowClear
            onFocus={() => setActiveItem(undefined)}
            size={'small'}
          />
          <Split>
            <HorizontalSpace>
              <Button onClick={HandleCopy} icon={<IvyIcon type={'action/copy'} />} size={'small'} type={'ghost'}>
                Copy Config
              </Button>
              <Button
                disabled={changes.length === 0}
                onClick={HandleReset}
                icon={<IvyIcon type={'action/redo'} />}
                size={'small'}
                type={'ghost'}
              >
                Reset
              </Button>
            </HorizontalSpace>
            <HorizontalSpace>
              <Button
                disabled={version === 0}
                onClick={() => setVersion(version - 1)}
                icon={<IvyIcon type={'action/undo'} />}
                size={'small'}
                type={'ghost'}
              >
                Undo
              </Button>
            </HorizontalSpace>
          </Split>
        </VerticalSpace>
      </div>

      <div className={withPrefix('debug-color-list')}>
        <GridList
          data={filtered ? props?.filter((item) => item.name.toLowerCase().includes(filtered.toLowerCase())) : props}
          keyProp={'name'}
          renderItem={(item) => (
            <DebugColorItem
              key={item.name}
              onChange={(newValue) => setValue(item, newValue)}
              onClick={(name) => {
                setActiveItem(activeItem === name ? undefined : name)
              }}
              active={activeItem === item.name}
              item={item}
            />
          )}
          grid={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
            gutter: [16, 0],
          }}
        />
      </div>
    </VerticalSpace>
  )
}

export default DebugProps
