import { Avatar, Card, Drawer, Layout, Select, Typography } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../logo'
import Navigation from '../menu'
import { SubTitle } from '../text'
import { RouteMap } from 'src/containers/RouteMap'
import { usePropsConfig, withPrefix } from 'src/sdk/contexts/Config'
import { CardMetaProps } from 'antd/lib/card'
import { useMediaQuery } from 'react-responsive'
import { useAccount } from 'src/sdk/contexts/Account'
import { useLayoutState } from 'src/layouts'
import useLogoType from 'src/sdk/hooks/useLogoType'
import { BreakpointMax } from '../screen/Breakpoint'
import GlobalNetworkNavigationSelect from '../../../components/global-network/GlobalNetworkNavigationSelect'
import './Sider.less'
import { Text } from '../text/Text'

export type SiderProps = typeof Layout.Sider.defaultProps & {
  enabled?: boolean
}

export const AccountSider: FC<CardMetaProps> = ({ ...rest }) => {
  const { customer } = useAccount()

  return customer ? (
    <Link to={RouteMap.accountMembershipTab} className={withPrefix('account-sider-card')}>
      <Card.Meta
        {...rest}
        avatar={
          <Avatar src={customer.image} size={56} shape={'circle'}>
            {customer.initials}
          </Avatar>
        }
        title={
          <SubTitle level={5} style={{ marginBottom: 0, color: 'inherit' }}>
            {customer.fullName}
          </SubTitle>
        }
        description={
          <Text type={'secondary'} style={{ color: 'inherit' }} ellipsis>
            {customer.email}
          </Text>
        }
      />
    </Link>
  ) : null
}

const SiderLogo: FC<{ collapsed: boolean; isMobile: boolean }> = ({ collapsed, isMobile }) => {
  const { navigation } = usePropsConfig()
  const logoType = useLogoType()

  return (
    <Link
      className={withPrefix(
        'portal-sider-header-logo',
        `portal-sider-header-logo-${navigation.logo.justify ?? 'left'}`,
      )}
      data-cy={'menu-logo'}
      to={'/'}
      style={{ overflow: 'hidden', width: '100%', justifyContent: logoType?.justify }}
    >
      {isMobile ? (
        <Logo
          dark={navigation.theme === 'light'}
          type={logoType?.logo?.collapsed ?? 'icon+text'}
          style={{ margin: collapsed ? '0 auto' : undefined }}
        />
      ) : (
        <Logo
          dark={navigation.theme === 'light'}
          type={collapsed ? logoType?.logo?.collapsed : logoType?.logo?.expanded ?? 'icon+text'}
          style={{ margin: collapsed ? '0 auto' : undefined }}
        />
      )}
    </Link>
  )
}

const Sider: FC<SiderProps> = ({ enabled = true, ...props }) => {
  const { siderState } = useLayoutState()
  const [collapsed, setCollapsed] = useState(siderState === 'collapsed')
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.MD })

  useEffect(() => {
    setCollapsed(siderState === 'collapsed')
  }, [siderState])

  return (
    <Layout.Sider
      className={`fixed-left portal-navigation`}
      collapsed={siderState === 'collapsed'}
      onCollapse={setCollapsed}
      trigger={null}
      {...props}
    >
      <Layout.Header>
        <SiderLogo collapsed={collapsed} isMobile={isMobile} />
      </Layout.Header>
      <Layout.Content className={withPrefix('sider-navigation')}>
        <Navigation loading={!enabled} />
      </Layout.Content>
      <div className={withPrefix('sider-footer')}>
        <GlobalNetworkNavigationSelect />
        <AccountSider />
      </div>
    </Layout.Sider>
  )
}

const MobileSider: FC<Pick<DrawerProps, 'onClose' | 'visible'>> = (props) => (
  <Drawer bodyStyle={{ padding: 0 }} closable width={'80%'} placement={'left'} {...props}>
    <Sider theme={'dark'} key={'mobile'} width={'100%'} collapsed={false} collapsible={false} />
  </Drawer>
)

export { Sider, MobileSider }
