import { FormProps as AntFormProps, ButtonProps, Form, FormInstance, Row, Typography, notification } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDLE } from 'rest-hooks'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { AbstractSurveyEntity, FormFieldValue, SurveyEntity } from '../../datasource/survey'
import { Button } from '../form'
import { VerticalSpace } from '../layout'
import { Section } from '../layout/Section'
import { SectionLoader } from '../loader'
import { Title } from '../text'
import { Markup } from '../text/Markup'
import SurveyField from './SurveyField'
import './SurveyForm.less'
import { Text } from '../text/Text'

type FormProps = Omit<AntFormProps, 'form' | 'title'> & {
  form: AbstractSurveyEntity
  onFinish: (values: FormFieldValue[], formInstance: FormInstance) => void
  loading?: boolean
  showTitle?: boolean
  showDescription?: boolean
  title?: string | ReactNode
  subTitle?: string
  submitText?: string
  externalBtn?: boolean
  staticBtn?: boolean
  formName?: string
  btnProps?: ButtonProps
}

type DynamicSurveyFormProps = {
  id: Data.ID
  onSuccess?: (message: string, header: string) => void
  onFail?: () => void
  btnProps?: ButtonProps
}

const DynamicSurveyForm: FC<DynamicSurveyFormProps> = ({ id, btnProps, onSuccess, onFail }) => {
  const { notifyOnError } = useNotification()
  const [successMessage, setSuccessMessage] = useState('Form was successfully submitted')
  const [successHeader, setSuccessHeader] = useState('Success')
  const [submitting, setSubmitting] = useState(false)
  const { data: survey, loading, error } = useDLE(SurveyEntity.detail(), { id: id })

  useEffect(() => {
    if (!survey) return
    survey.confirmationHeader && setSuccessHeader(survey.confirmationHeader)
    survey.confirmationText && setSuccessMessage(survey.confirmationText)
  }, [survey])

  const onFinish = (values: FormFieldValue[]) => {
    setSubmitting(true)
    SurveyEntity.submit(id, values)
      .then(() => {
        if (onSuccess) {
          onSuccess(successMessage, successHeader)
        } else {
          notification.success({ message: successHeader, description: successMessage })
        }
      })
      .catch((error) => {
        if (onFail) {
          onFail()
        } else {
          notifyOnError(error)
        }
      })
      .finally(() => setSubmitting(false))
  }

  return loading ? (
    <SectionLoader />
  ) : survey ? (
    <Section title={survey.name} subTitle={<Markup sanitized={false} html={survey.description} />}>
      <SurveyForm
        staticBtn={false}
        submitText={survey.buttonCreate}
        form={survey}
        onFinish={onFinish}
        loading={submitting}
        showTitle={false}
        showDescription={false}
        btnProps={btnProps}
      />
    </Section>
  ) : null
}

const SurveyForm: FC<FormProps> = ({
  form,
  onFinish,
  showTitle,
  title,
  subTitle,
  showDescription = false,
  submitText,
  loading,
  externalBtn,
  staticBtn = true,
  formName,
  btnProps,
  ...rest
}) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  const fields = form.fields
  const [formInstance] = Form.useForm()

  const handleSubmit = (values: any) => {
    const fieldValues: FormFieldValue[] = Object.entries(values)
      .filter(([, val]) => val !== undefined)
      .map(([key, val]) => {
        const surveyField = fields.find((field) => field.id === parseInt(key))
        return Array.isArray(val)
          ? val.length > 0
            ? val.map((value) => ({
                field_id: parseInt(key),
                type: surveyField?.field.type,
                answer: value as string,
              }))
            : val
          : {
              field_id: parseInt(key),
              type: surveyField?.field.type,
              answer: val as string,
            }
      })
      .flat()

    onFinish(fieldValues, formInstance)
  }
  const initialValues: {} = fields.reduce((acc, surveyField) => {
    const fieldType = surveyField.field.type
    switch (fieldType) {
      case 'select':
        return {
          ...acc,
          [surveyField.id]: surveyField.field.values?.find((value) => value.selected)?.value,
        }
      case 'datetime':
        return {
          ...acc,
          [surveyField.id]: '',
        }
      case 'checkbox':
        return {
          ...acc,
          [surveyField.id]: surveyField.field.values
            ?.filter((value) => value.selected)
            ?.reduce((values, item) => [...values, item.value], [] as string[]),
        }
      default:
        return {
          ...acc,
          [surveyField.id]: surveyField.field.value,
        }
    }
  }, {})
  return (
    <VerticalSpace size={16}>
      {(showTitle || showDescription) && (
        <VerticalSpace size={0}>
          {title ? (
            typeof title === 'string' ? (
              <Title className={'survey-title'} level={2}>
                {title}
              </Title>
            ) : (
              title
            )
          ) : (
            showTitle && (
              <Title className={'survey-title'} level={2}>
                {form.name}
              </Title>
            )
          )}

          {showDescription && form.description && (
            <Text>{<Markup sanitized={false} html={form.description} />}</Text>
          )}
        </VerticalSpace>
      )}
      <Form
        form={formInstance}
        initialValues={initialValues}
        onFinish={handleSubmit}
        className={withPrefix('survey-form')}
        {...rest}
      >
        <Row gutter={[20, 10]}>
          {fields.map((sf) => (
            <SurveyField
              disabled={!form.canSubmit}
              formInstance={formInstance}
              key={sf.id}
              name={`${sf.id}`}
              surveyField={sf}
              loading={loading}
            />
          ))}
        </Row>

        {!externalBtn && (
          <Button
            style={{ width: isMobile ? '100%' : undefined }}
            loading={loading}
            htmlType={'submit'}
            disabled={!form.canSubmit}
            title={submitText || 'Save changes'}
            wide
            {...btnProps}
          />
        )}
      </Form>
    </VerticalSpace>
  )
}

export { DynamicSurveyForm, SurveyForm as default }
