import { Entity, Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { AccountAppointment, AccountReservation, AccountTicket } from './account/account'
import { BusinessLocation } from './business'
import { ApiResource, SchemaPaginated } from './entity'
import { ProductResource } from './product'
import { ShippingAddressEntity } from './shipping'
import { SurveyEndpoint } from './survey'
import { TransactionEntity } from './transaction'

export type OrderStatus = 'cancelled' | 'completed' | 'pending' | 'processing'

export type OrderItemStatus = 'active' | 'cancelled'

type OrderType = '' | 'product' | 'event' | 'appointment' | 'reservation' | 'fandb'

class OrderItem extends Entity implements Data.Imaginated {
  readonly id: Data.ID = ''
  readonly externalId: string = ''
  readonly isSubscription: boolean = false
  readonly subscriptionFrequency: string = ''
  readonly orderId: number = 0
  readonly discount?: number
  readonly price: number = 0
  readonly quantity: number = 0
  readonly total: number = 0
  readonly subTotal: number = 0
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly productAttribute: string = ''
  readonly productId: number = 0
  readonly type: OrderType = ''
  readonly subscriptionId: number = 0
  readonly createdOn: Date = new Date(0)
  readonly shippingTotal: number = 0
  readonly survey?: SurveyEndpoint
  readonly booking?: AccountReservation | AccountAppointment
  readonly reservation: AccountReservation = this.booking as AccountReservation
  readonly appointment: AccountAppointment = this.booking as AccountAppointment
  readonly ticket: AccountTicket = new AccountTicket()
  readonly product: ProductResource = new ProductResource()
  readonly status: OrderItemStatus = 'active'

  /* Schema is defined at bottom of account.ts */

  pk() {
    return `${this.id}`
  }

  get image(): string {
    return this.photo
  }

  get images(): string[] {
    return [this.photo]
  }
}

export type OrderQuery = {
  status?: string
  orderBy?: 'id' | 'date' | 'date_desc' | 'total' | 'total_desc'
} & Data.Paginated

class OrderEntity extends ApiResource implements Data.Identified {
  static urlRoot = `/api/account/orders`
  readonly id: Data.ID = ''
  readonly paidDate: string = ''
  readonly createdOn: Date = new Date()
  readonly data: string = ''
  readonly note: string = ''
  readonly currency?: string
  readonly status: OrderStatus = 'processing'
  readonly charge: number = 0.0
  readonly serviceFeeTotal: number = 0
  readonly shippingTotal: number = 0
  readonly discountTotal: number = 0
  readonly subTotal: number = 0.0
  readonly tipTotal: number = 0.0
  readonly taxTotal: number = 0.0
  readonly total: number = 0.0
  readonly type: string = ''
  readonly via: string = ''
  readonly quantity: number = 0
  readonly shippingAddress?: ShippingAddressEntity
  readonly items: OrderItem[] = [OrderItem.fromJS()]
  readonly surveys?: SurveyEndpoint[]
  readonly businessLocation?: BusinessLocation
  static schema = {
    shippingAddress: ShippingAddressEntity,
    items: [OrderItem],
    // transactions: [TransactionEntity],
  }

  pk(): string {
    return `${this.id}`
  }

  get hasProducts(): boolean {
    return this.items.some((o) => o.type === 'product')
  }

  get hasTickets(): boolean {
    return this.items.some((o) => o.type === 'event')
  }

  get hasReservations(): boolean {
    return this.items.some((o) => o.type === 'reservation')
  }

  get hasAppointments(): boolean {
    return this.items.some((o) => o.type === 'appointment')
  }

  static getPaginated(params: OrderQuery = {}): SchemaPaginated<OrderEntity> {
    return useResource(this.paginated(), params)
  }

  static getById(params: Data.Identified): OrderEntity {
    return useResource(this.detail(), params)
  }

  static transactions<T extends typeof Resource>(this: T) {
    return this.detail().extend({
      url: (orderId: Data.ID) => `${this.urlRoot}/${orderId}/transactions`,
      schema: [TransactionEntity],
    })
  }
}

export { OrderEntity, OrderItem }
