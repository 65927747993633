import { Typography } from 'antd'
import { FC } from 'react'
import { useI18n } from '../../contexts/I18n'
import './index'

type TitleProps = typeof Typography.Title.defaultProps & {value?: string, context?: Record<string, any>}

export const SubTitle: FC<TitleProps> = (props) => <Title type={'secondary'} {...props} />

export const Title: FC<TitleProps> = ({ context = {}, children, value, ...props }) => {
  const { t } = useI18n()
  return <Typography.Title {...props}>
    {value ? t(value, context) : typeof children === 'string' ? t(children, context) : children}
    </Typography.Title>
}
