import { Col, Row, Form, DatePicker } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Button, Item } from 'src/sdk/components/form'
import IvyIcon from 'src/sdk/components/icon'
import { TagList, TagListItemProps } from 'src/sdk/components/tag/TagList'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { useFilter } from 'src/sdk/contexts/Filter'
import { CategoryEntity } from 'src/sdk/datasource/category'
import { TimeSlot } from 'src/sdk/helpers/date'
import { HorizontalSpace } from '../layout'
import { Flex } from '../layout/Grid'
import { Tags } from '../tag/Tag'
import './FilterForm.less'
import { Text } from '../text/Text'
import { useI18n } from '../../contexts/I18n'

export type InitTags = {
  timeSlots?: TimeSlot[]
  categories: CategoryEntity[]
}

export type FilterQueryValues = {
  startDate?: string
  endDate?: string
  tags?: {
    categories?: Set<Data.ID>
    timeSlot?: React.Key
  }
  numberOfFilters: number
}

type FilterFormValueProps = {
  startDate?: moment.Moment
  endDate?: moment.Moment
  initTags: InitTags
  onSearch?: (query: FilterQueryValues) => void
}

export const FilterActionButton: FC<ButtonProps & { filterCount?: number }> = ({ filterCount, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })

  return (
    <Button className='list-action-search__filter' type={'ghost'} {...props}>
      {isMobile ? (
        <IvyIcon size={16} height={24} type={'custom/filters'} />
      ) : (
        <Flex direction={'horizontal'} align={'center'} size={8}>
          <IvyIcon size={16} type={'custom/filters'} />
          <Text>Filters</Text>
        </Flex>
      )}
    </Button>
  )
}

export const FilterForm: FC<Partial<FilterFormValueProps>> = ({
  initTags = {
    categories: [],
    timeSlots: [],
  },
  onSearch = () => {},
  startDate: start,
  endDate: end,
}) => {
  const { setFilterQuery, filterQuery, setDidSearch } = useFilter()
  const [startDate, setStartDate] = useState<moment.Moment | undefined | null>(start)
  const [endDate, setEndDate] = useState<moment.Moment | undefined | null>(end)
  const [checkedCategories, setCheckedCategories] = useState(new Set(initTags.categories.map((c) => c.id)))
  const [checkedTimeSlot, setCheckedTimeSlot] = useState<React.Key | undefined>()
  const config = usePublicConfig()

  const [dateChangeTracker, setDateChangeTracker] = useState({
    date: false,
    timeStart: false,
    timeEnd: false,
    venue: false,
  })

  const tagProps: Partial<TagListItemProps> = { size: 'middle', shape: 'asymmetric', type: 'ghost' }

  const onFinish = (clear?: boolean) => {
    let startQuery = startDate
    let endQuery = endDate

    if (checkedTimeSlot) {
      const timeSlot = initTags.timeSlots?.find((it) => it.id == checkedTimeSlot)
      startQuery = moment(timeSlot?.start)
      endQuery = moment(timeSlot?.end)
    }

    const query = {
      startDate: clear ? undefined : startQuery?.toISOString(),
      endDate: clear ? undefined : endQuery?.toISOString(),
      tags: {
        timeSlot: clear ? undefined : checkedTimeSlot,
        categories: clear ? undefined : checkedCategories,
      },
      numberOfFilters: clear
        ? initTags.categories.length
        : checkedTimeSlot !== undefined
        ? 1
        : 0 + checkedCategories.size + Object.values(dateChangeTracker).reduce((acc, curr) => (acc += curr ? 1 : 0), 0),
    }

    setFilterQuery(query)
    onSearch && !clear && onSearch(query)
  }

  const onDateTimeChange = (name: 'date' | 'timeStart' | 'timeEnd', changed: boolean = true) => {
    setDateChangeTracker({
      ...dateChangeTracker,
      [name]: changed,
    })
  }

  const onTimeSlotChange = (tag: React.Key, checked: boolean) => {
    if (checked) {
      const timeSlot = initTags.timeSlots?.find((i) => i.id == tag)
      setStartDate(timeSlot?.start)
      setEndDate(timeSlot?.end)
      setCheckedTimeSlot(tag)
    } else if (checkedTimeSlot === tag) {
      setStartDate(undefined)
      setEndDate(undefined)
      setCheckedTimeSlot(undefined)
    }
  }

  const onCategoryChange = (tags: Set<React.Key>, tag: React.Key): typeof tags => {
    tags.has(tag) ? tags.delete(tag) : tags.add(tag)
    return tags
  }

  useEffect(() => {
    if (checkedTimeSlot) {
      onDateTimeChange('timeStart', false)
      onDateTimeChange('timeEnd', false)
    }
  }, [checkedTimeSlot])
  const {t} = useI18n()
  return (
    <Form>
      <Row gutter={[10, 0]}>
        <Col xs={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
          <Item label='Start date'>
            <DatePicker placeholder={t('Select date')}
              onFocus={(e) => e.target.blur()}
              allowClear
              format={config.environment === 'US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
              value={filterQuery.startDate || startDate ? moment(filterQuery.startDate || startDate) : undefined}
              disabled={checkedTimeSlot !== undefined}
              disabledDate={(value) => value < moment().subtract(1, 'days')}
              onChange={(date) => {
                setStartDate(date)
                onDateTimeChange('timeStart')
              }}
            />
          </Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
          <Item label='End date'>
            <DatePicker  placeholder={t('Select date')}
              onFocus={(e) => e.target.blur()}
              allowClear={true}
              format={config.environment === 'US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
              value={filterQuery.endDate || endDate ? moment(filterQuery.endDate || endDate) : undefined}
              disabled={checkedTimeSlot !== undefined}
              disabledDate={(value) => value < moment().subtract(1, 'days')}
              onChange={(date) => {
                setEndDate(date)
                onDateTimeChange('timeEnd')
              }}
            />
          </Item>
        </Col>
      </Row>
      {initTags?.categories && initTags?.categories.length > 0 && (
        <Item>
          <TagList
            dataSource={initTags.categories}
            onChange={(id) => setCheckedCategories(onCategoryChange(checkedCategories, id))}
            checkedTags={checkedCategories}
            name={'categories'}
            tagProps={tagProps}
          />
        </Item>
      )}
      {initTags?.timeSlots && (
        <Item>
          <Tags
            checkable
            checked={checkedTimeSlot}
            size={'middle'}
            type={'ghost'}
            data={initTags.timeSlots.map((it) => ({ id: it.id, title: t(it.title) }))}
            onCheck={onTimeSlotChange}
          />
        </Item>
      )}
      <Button
        onClick={() => {
          setDidSearch(false)
          setCheckedTimeSlot(undefined)
          setStartDate(null)
          setEndDate(null)
          setCheckedCategories(new Set())
          onFinish(true)
        }}
        title={'Clear'}
        type={'ghost'}
        block
        style={{ marginBottom: 15 }}
      />
      <Button onClick={() => onFinish()} title={'Search'} type={'primary'} block />
    </Form>
  )
}
