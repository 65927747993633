import { Typography } from 'antd'
import { FC } from 'react'
import { Radio } from 'src/sdk/components/form/Radio'
import { CenteredContent, HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { ListItem, ListItemMeta } from 'src/sdk/components/list/ListItem'
import { PaymentBrand } from 'src/sdk/components/payment-brand/PaymentBrand'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { BillingUseTag } from '../BillingUse'
import { PaymentMethodListProps } from '../payment-methods/PaymentMethodList'
import CreditCardView from './CreditCardView'
import { Title } from '../../../../../sdk/components/text'
import { Text } from '../../../../../sdk/components/text/Text'

type PaymentCardProps = PaymentMethodListProps & {
  checked?: boolean
  editable?: boolean
  data: CreditCardEntity
}

const CreditCardListItem: FC<PaymentCardProps> = ({ data, onChecked, checked, editable }) => {
  const { id, billingUse, nickname, brand, lastFour } = data
  const { setDrawer, setDrawerVisible } = useDynamicDrawer()

  const handleClick = () => {
    setDrawer({
      content: (
        <CenteredContent>
          <CreditCardView data={data} onDelete={() => setDrawerVisible(false)} />
        </CenteredContent>
      ),
    })
  }

  return (
    <ListItem
      key={id}
      className={withPrefix(
        onChecked ? 'wallet-card-checkable' : '',
        onChecked && checked ? 'wallet-card-checked' : '',
      )}
      onClick={() => {
        onChecked && onChecked(data)
        editable && handleClick()
      }}
    >
      <ListItemMeta
        key={id}
        avatar={{
          size: 48,
          shape: 'square',
          background: 'light',
          icon: <PaymentBrand color={'default'} type={'full'} brand={brand.toLowerCase() as PaymentMethodBrand} />,
        }}
        extra={!onChecked && <BillingUseTag billingUse={billingUse} />}
        description={
          <HorizontalSpace style={{ justifyContent: 'space-between' }} align={'center'}>
            <VerticalSpace size={0}>
              <Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                {nickname}
              </Title>
              <Text type={'secondary'} style={{ textTransform: 'capitalize', fontSize: 12 }}>
                {brand}
                {` - ${lastFour}`}
              </Text>
            </VerticalSpace>
            {onChecked && <Radio defaultChecked={checked} checked={checked} />}
          </HorizontalSpace>
        }
      />
    </ListItem>
  )
}

export default CreditCardListItem
