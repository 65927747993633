import { FC, ReactNode } from 'react'
import { BaseAddress } from 'src/sdk/datasource/address'
import { TextProps } from 'antd/lib/typography/Text'
import { HorizontalSpace, VerticalSpace } from '../layout'
import { Title } from './Title'
import { Text } from './Text'

type AddressProps = {
  title?: string | ReactNode
  justify?: 'center' | 'left' | 'right' | 'space-between'
} & Data.Source<BaseAddress> &
  TextProps

export const Address: FC<AddressProps> = ({ data, title, justify, ...props }) => (
  <VerticalSpace size={0} justify={justify}>
    {title &&
      (typeof title === 'string' ? (
        <Title style={{ marginBottom: 0 }} level={5}>
          {title}
        </Title>
      ) : (
        title
      ))}
    {data.address && (
      <Text {...props}>
        <HorizontalSpace justify={justify}>
          {data.address}
          {data.address2 && `| ${data.address2}`}
        </HorizontalSpace>
      </Text>
    )}
    {data.address3 && <Text {...props}>{data.address3}</Text>}
    <Text {...props}>
      {data.city}
      {data.state && `, ${data.state}`} {data.zipCode}
    </Text>
  </VerticalSpace>
)
