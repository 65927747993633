import { Col, Input, notification, Row, Typography } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Checkbox } from 'src/sdk/components/checkbox/Checkbox'
import Form, { Button, Item } from 'src/sdk/components/form'
import { CompactGroup, CompactItem } from 'src/sdk/components/form/CompactGroup'
import { FormItem } from 'src/sdk/components/form/FormItem'
import IvyIcon from 'src/sdk/components/icon'
import { VerticalSpace } from 'src/sdk/components/layout'
import { Flex, Split } from 'src/sdk/components/layout/Grid'
import { Paginator } from 'src/sdk/components/list'
import { OverlayLoader, SectionLoader } from 'src/sdk/components/loader'
import { PaymentBrandList } from 'src/sdk/components/payment-brand/PaymentBrand'
import { BreakpointMax } from 'src/sdk/components/screen/Breakpoint'
import { usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { useNotification } from 'src/sdk/contexts/Notification'
import { NewPaymentMethod, usePayment } from 'src/sdk/contexts/payment/Payment'
import { PaymentFormValue } from 'src/sdk/datasource/checkout'
import { PaymentMethod } from 'src/sdk/datasource/payment'
import { BankAccount } from 'src/sdk/datasource/wallet/bankaccount'
import { CreditCardCreateForm, CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { WalletItem } from 'src/sdk/datasource/wallet/wallet'
import useWallet from 'src/sdk/hooks/useWallet'
import BankAccountListItem from '../bank-account/BankAccountListItem'
import CreditCardListItem from '../credit-card/CreditCardListItem'
import '../Wallet.less'
import { Title } from '../../../../../sdk/components/text'
import { Text } from '../../../../../sdk/components/text/Text'
import { useI18n } from '../../../../../sdk/contexts/I18n'

interface WalletListProps {
  wallet: (CreditCardEntity | BankAccount)[]
  onChange: (wallet: WalletItem) => void
  defaultWallet?: Data.ID
}

const WalletList: FC<WalletListProps> = ({ wallet, onChange, defaultWallet }) => {
  const [selected, setSelected] = useState<Data.ID>()
  const isSelected = (item: WalletItem) => selected === item.id

  const handleChange = (item: WalletItem) => {
    onChange(item)
    setSelected(item.id)
  }

  return (
    <Paginator<WalletItem>
      className={withPrefix('payment-method-select')}
      type={'list'}
      infinite={false}
      dataSource={wallet}
      renderItem={(item) =>
        item instanceof CreditCardEntity ? (
          <CreditCardListItem checked={isSelected(item)} onChecked={handleChange} data={item} />
        ) : (
          <BankAccountListItem checked={isSelected(item)} onChecked={handleChange} data={item} />
        )
      }
      pagination={{
        pageSize: 6,
        hideOnSinglePage: true,
        total: wallet?.length,
      }}
    />
  )
}

interface WalletAddProps {
  buttonTitle?: string
  onSuccess: (paymentMethod: NewPaymentMethod) => void
  amount?: number
  title?: string
  defaultSave?: boolean
}

const WalletAdd: FC<WalletAddProps> = ({
  onSuccess,
  buttonTitle = 'Continue',
  title = 'Add a new credit card',
  amount = 0,
  defaultSave = false,
}) => {
  const { notifyOnError } = useNotification()
  const { CardInformation, createPaymentMethod } = usePayment()
  const [loading, setLoading] = useState(false)
  const { defaultProcessor } = usePublicConfig()
  const [saveCard, setSaveCard] = useState(defaultSave)
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.XS })
  const [country, setCountry] = useState('US')
  const onFinish = async (values: PaymentFormValue) => {
    setLoading(true)
    const fullName = values.name?.split(' ')
    const firstName = fullName && fullName.length > 0 ? fullName[0] : undefined
    const lastName = fullName && fullName.length > 1 ? fullName[fullName.length - 1] : undefined
    const newValues = {
      ...values,
      saveCard: saveCard,
      name: `${firstName} ${lastName}`,
      country: values.country,
      zipCode: values.zipCode,
    } as CreditCardCreateForm

    await createPaymentMethod(newValues, amount)
      .then((result) => {
        if (!result) {
          notification.error({
            message: 'Error adding this card',
            description: 'There was an error adding this payment method.  Try again',
          })
          return
        }
        onSuccess(result)
      })
      .catch(notifyOnError)
      .finally(() => setLoading(false))
  }
  const {t} = useI18n()
  return (
    <OverlayLoader loading={loading}>
      <Form<PaymentFormValue> className={withPrefix('payment-form')} onFinish={onFinish}>
        <VerticalSpace size={20}>
          <Row>
            <Col span={24}>
              <Title level={3} type={'secondary'}>
                {title}
              </Title>
            </Col>
          </Row>
          <Row className={withPrefix('payment-form-items')}>
            <Col span={24}>
              <Flex direction={'horizontal'} justify={'space-between'} style={{ marginBottom: 8 }}>
                <Title level={5} style={{ marginBottom: 0 }}>
                  Card Information
                </Title>
                {defaultProcessor?.cardsAccepted && <PaymentBrandList brands={defaultProcessor.cardsAccepted} />}
              </Flex>
            </Col>

            <Col span={24} key={'card_information_col'}>
              <CardInformation key={'card_information'} isMobile={isMobile} />
            </Col>
            {!defaultSave && (
              <Split>
                <Item label={''} valuePropName={'checked'} style={{ marginBottom: 0, display: 'flex' }}>
                  <Checkbox checked={saveCard} onChange={(evt) => setSaveCard(evt.target.checked)}>
                    Save this card for future purchases
                  </Checkbox>
                </Item>
              </Split>
            )}
          </Row>

          <Row>
            <Col span={24}>
              <Title level={5}>Billing Information</Title>
            </Col>

            <CompactGroup>
              <CompactItem width={100} rules={[{ required: true, message: '' }]} name={'name'} label={'Full Name'}>
                <Input size={'middle'} type={'text'} />
              </CompactItem>
              <CompactItem
                width={50}
                rules={[{ required: true, message: '' }]}
                name={'country'}
                label={'Billing Country'}
              >
                <FormItem.Countries size={'middle'} onChange={(val) => setCountry(val)} />
              </CompactItem>
              <CompactItem width={50}>
                <FormItem.ZipCode country={country} />
              </CompactItem>
            </CompactGroup>
          </Row>

          <Row>
            <Col span={24}>
              <Button block type={'primary'} htmlType={'submit'}>
                {buttonTitle}
              </Button>
            </Col>
          </Row>
        </VerticalSpace>
      </Form>
    </OverlayLoader>
  )
}

interface PaymentMethodSelectProps {
  includeBanks?: boolean
  onChange: (paymentMethod: PaymentMethod) => void
  onAdd?: (paymentMethod: PaymentMethod) => void
  defaultWallet?: Data.ID
  title?: string | ReactNode
  amount?: number
  addCardBtnTitle?: string
  addCardTitle?: string
  defaultSave?: boolean
}

const PaymentMethodSelect: FC<PaymentMethodSelectProps> = ({
  includeBanks = true,
  onChange,
  onAdd,
  amount,
  title,
  defaultWallet,
  addCardBtnTitle,
  addCardTitle,
  defaultSave,
}) => {
  const { setModal, setModalVisible } = useDynamicDrawer()
  const { wallet, loading } = useWallet()

  const onPaymentMethodChange = (wallet: WalletItem) => {
    onChange(
      new PaymentMethod({
        id: wallet.id,
        type: 'creditcard',
        amount: amount,
      }),
    )
  }
  const onPaymentMethodAdded = (newMethod: NewPaymentMethod) => {
    onChange(newMethod.paymentMethod)
    onAdd && onAdd(newMethod.paymentMethod)
    setModalVisible(false)
  }

  const showModal = () => {
    setModal({
      destroyOnClose: true,
      footer: null,
      centered: true,
      closable: true,
      content: (
        <WalletAdd
          onSuccess={onPaymentMethodAdded}
          amount={amount}
          buttonTitle={addCardBtnTitle}
          defaultSave={defaultSave}
        />
      ),
    })
  }

  return loading ? (
    <SectionLoader />
  ) : wallet && wallet.length > 0 ? (
    <VerticalSpace size={16}>
      {title && (typeof title === 'string' ? <Text>{title}</Text> : title)}
      <WalletList
        wallet={!includeBanks ? wallet.filter((w) => w.paymentType === 'creditcard') : wallet}
        onChange={onPaymentMethodChange}
        defaultWallet={defaultWallet}
      />
      <Button type={'text'} onClick={showModal}>
        <Flex size={8} direction={'horizontal'} align={'center'} justify={'center'}>
          <IvyIcon type={'symbol/plus'} size={15} />
          <Text type={'secondary'}>Use another payment method</Text>
        </Flex>
      </Button>
    </VerticalSpace>
  ) : (
    <WalletAdd
      title={addCardTitle}
      onSuccess={onPaymentMethodAdded}
      amount={amount}
      buttonTitle={addCardBtnTitle}
      defaultSave={defaultSave}
    />
  )
}

export default PaymentMethodSelect
