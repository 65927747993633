import { Typography } from 'antd'
import { FC, PropsWithChildren } from 'react'
import { useI18n } from '../../contexts/I18n'

const Text: FC<
  PropsWithChildren<typeof Typography.Text.defaultProps> & {
    value?: string
    context?: Record<string, any>
  }
> = ({ context = {}, children, value, ...props }) => {
  const { t, money } = useI18n()
  const { amount, currency, ...ctx } = context
  return (
    <Typography.Text {...props}>
      {value ? (
        <>
          {t(value, { ...ctx, ...(amount ? { amount: money(amount, currency) } : {}) })}
          {children}
        </>
      ) : typeof children === 'string' ? (
        t(children, { ...ctx, ...(amount ? { amount: money(amount, currency) } : {}) })
      ) : (
        children
      )}
    </Typography.Text>
  )
}

export { Text }
