import { Empty as Wrapped, Typography } from 'antd'
import { FC, memo, useCallback, useState } from 'react'
import { SubTitle } from 'src/sdk/components/text'
import { ReactComponent as Items } from 'src/sdk/images/emptyStates/placeholder.svg'
import { Text } from '../text/Text'

type ListItemType =
  | 'appointments'
  | 'companies'
  | 'dining'
  | 'events'
  | 'members'
  | 'perks'
  | 'rooms'
  | 'store'
  | 'items'
  | string

const Placeholder: FC<Pick<EmptyPlaceholderProps, 'type'>> = ({ type = 'items' }) => {
  const [TypedPlaceholder, setTypedPlaceholder] = useState<FC>()
  useCallback(() => import(`../../images/emptyStates/${type}.svg`).then(setTypedPlaceholder), [type])
  return TypedPlaceholder ? <TypedPlaceholder /> : <Items />
}

type EmptyPlaceholderProps = {
  filtered: boolean
  type: ListItemType
  description: string | React.ReactNode
  title: string
}

const Empty: FC<Partial<EmptyPlaceholderProps>> = ({
  filtered = false,
  type = 'items',
  description = '',
  title = '',
}) => {
  return (
    <Wrapped
      image={<Placeholder type={filtered ? 'items' : type} />}
      imageStyle={{
        height: 72,
      }}
      description={
        <div style={{ maxWidth: 450, margin: '0 auto' }}>
          <SubTitle level={4}>
            {filtered ? 'Your filters produced no results!' : title ? title : `No items to show`}
          </SubTitle>
          {filtered && <Text>Try adjusting or clearing your filters to display better results</Text>}
          {description && <Text>We are sorry, but we have nothing to offer you right now. Follow our updates. We are preparing something special for our members soon
          </Text>}
        </div>
      }
    />
  )
}

export default memo(Empty)
