import { Col, Divider, Form, Input, List, notification, Row, Typography } from 'antd'
import { FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useController } from 'rest-hooks'
import Card from 'src/sdk/components/card'
import { Button, Item } from 'src/sdk/components/form'
import { LabelTooltip } from 'src/sdk/components/form/LabelToolTip'
import { Avatar } from 'src/sdk/components/image'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { OverlayLoader } from 'src/sdk/components/loader'
import { PaymentBrand } from 'src/sdk/components/payment-brand/PaymentBrand'
import { PopConfirm } from 'src/sdk/components/popconfirm/PopConfirm'
import { BreakpointMax } from 'src/sdk/components/screen/Breakpoint'
import { Tag } from 'src/sdk/components/tag'
import { useNotification } from 'src/sdk/contexts/Notification'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import { BankAccount, BankAccountUpdate } from 'src/sdk/datasource/wallet/bankaccount'
import { WalletBillingUse } from 'src/sdk/datasource/wallet/wallet'
import { Capitalize } from 'src/sdk/helpers/strings'
import useWallet from 'src/sdk/hooks/useWallet'
import BillingUse from '../BillingUse'
import GoCardlessVerifyBankAccount from './Forms/Verify/GoCardlessVerifyBankAccount'
import StripeVerifyBankAccount from './Forms/Verify/StripeVerifyBankAccount'
import { Title } from '../../../../../sdk/components/text'
import { Text } from '../../../../../sdk/components/text/Text'
import { useI18n } from '../../../../../sdk/contexts/I18n'

type BankAccountViewProps = {
  onDelete?: () => void
} & Data.Source<BankAccount>

const BankAccountView: FC<BankAccountViewProps> = ({ data, onDelete }) => {
  const [loading, setLoading] = useState(false)
  const [isPrimary, setIsPrimary] = useState(data.default)
  const { verified } = data

  return (
    <OverlayLoader loading={loading}>
      <Row
        gutter={[
          {
            xs: 0,
            sm: 0,
            md: 55,
            lg: 55,
          },
          { xs: 32, sm: 24, md: 32, lg: 32 },
        ]}
      >
        <Col span={24} lg={14}>
          {verified ? (
            <BankAccountDetails primary={isPrimary} onLoading={setLoading} data={data} />
          ) : (
            <BankAccountVerify data={data} />
          )}
        </Col>
        <Col span={24} lg={10}>
          <BankAccountCardView primaryChange={setIsPrimary} data={data} onDelete={onDelete} onDeleting={setLoading} />
        </Col>
      </Row>
    </OverlayLoader>
  )
}

const BankAccountDetails: FC<
  Data.Source<BankAccount> & { primary: boolean; onLoading: (loading: boolean) => void }
> = ({ onLoading, data }) => {
  const { fetch } = useController()
  const { onUpdate } = useWallet()
  const [billingUse, setBillingUse] = useState<WalletBillingUse>(data.billingUse)
  const { id, nickname } = data
  const { notifyOnError } = useNotification()

  const updateBankAccount = (value: BankAccountUpdate) => {
    onLoading(true)
    fetch(
      BankAccount.partialUpdate(),
      { id: id },
      {
        nickname: value.nickname,
        billingUse: billingUse,
      },
    )
      .then((response) => {
        notification.success({ message: 'Bank Account successfully updated' })
        onUpdate(response)
      })
      .catch(notifyOnError)
      .finally(() => {
        onLoading(false)
      })
  }
  const {t} = useI18n()
  return (
    <Form<BankAccountUpdate>
      id={'updateBankAccount'}
      initialValues={{
        nickname: nickname,
      }}
      onFinish={updateBankAccount}
    >
      <Row
        gutter={[
          {
            xs: 0,
            sm: 0,
            md: 55,
            lg: 55,
          },
          { xs: 32, sm: 24, md: 32, lg: 32 },
        ]}
      >
        <Col span={24}>
          <Title level={1}>Bank Account Details</Title>
        </Col>
        <Col span={24}>
          <Item
            name='nickname'
            label={
              <LabelTooltip
                label={'Account Nickname'}
                helpText={'Enter a nickname to easily identify this payment method in the future'}
              />
            }
          >
            <Input />
          </Item>
          {/* <BillingUse value={billingUse} onChange={setBillingUse} /> */}
        </Col>
      </Row>
    </Form>
  )
}

const BankAccountVerify: FC<BankAccountViewProps> = ({ data, onDelete }) => {
  return data.type === 'stripe' ? <StripeVerifyBankAccount data={data} /> : <GoCardlessVerifyBankAccount data={data} />
}

const BankAccountCardView: FC<
  BankAccountViewProps & { onDeleting: (boolean) => void; primaryChange: (boolean) => void }
> = ({ data, onDelete, onDeleting }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const { bankName, brand, billingDetails, lastFour, verified, default: primary } = data
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.MD })

  const handleDelete = () => {
    onDeleting(true)
    fetch(BankAccount.delete(), { id: data.id })
      .then(() => {
        notification.success({ message: 'Bank account was successfully deleted.' })
        onDelete && onDelete()
      })
      .catch(notifyOnError)
      .finally(() => onDeleting(false))
  }

  return (
    <Card bordered>
      <List>
        <List.Item>
          <HorizontalSpace>
            <Avatar
              style={{ margin: 0 }}
              size={64}
              shape={'square'}
              background={'none'}
              icon={
                <PaymentBrand
                  color={'default'}
                  type={'full'}
                  brand={bankName?.toLowerCase() as PaymentMethodBrand}
                  fallbackType={'bank'}
                />
              }
            />
            <Text strong>{Capitalize(data.nickname)}</Text>
          </HorizontalSpace>
        </List.Item>
        <List.Item>
          <HorizontalSpace>
            <Text strong>Bank Name:</Text>
            <Text>{brand}</Text>
          </HorizontalSpace>
        </List.Item>
        <List.Item>
          <HorizontalSpace>
            <Text strong>Account Holder:</Text>
            <Text>{billingDetails.name}</Text>
          </HorizontalSpace>
        </List.Item>
        <List.Item>
          <HorizontalSpace>
            <Text strong>Account Number:</Text>
            <Text>{` •••• ${lastFour}`}</Text>
          </HorizontalSpace>
        </List.Item>
        <List.Item>
          <HorizontalSpace>
            <Text strong>Status:</Text>
            <Tag size={'small'} type={verified ? 'success' : 'default'} shape={'asymmetric'}>
              {verified ? 'verified' : 'pending verification'}
            </Tag>
          </HorizontalSpace>
        </List.Item>
      </List>
      <Divider />
      <VerticalSpace size={12} style={{ marginBottom: isMobile ? 100 : 0 }}>
        <PopConfirm
          title={'Are you sure you want to delete this bank account?'}
          iconType={'symbol/exclamation-circle'}
          okText={'Yes'}
          cancelText={'Nevermind'}
          onConfirm={handleDelete}
        >
          <Button block type={'ghost'}>
            Delete
          </Button>
        </PopConfirm>
        <Button form={'updateBankAccount'} block htmlType={'submit'} type={'primary'}>
          Update
        </Button>
      </VerticalSpace>
    </Card>
  )
}

export default BankAccountView
