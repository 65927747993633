import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormInstance, Input, notification } from 'antd'
import { FC, useState } from 'react'
import { useController } from 'rest-hooks'
import Form, { Item } from 'src/sdk/components/form'
import { CompactGroup, CompactItem } from 'src/sdk/components/form/CompactGroup'
import { FormRuleProps } from 'src/sdk/components/form/Form'
import { FormItem } from 'src/sdk/components/form/FormItem'
import { LabelTooltip } from 'src/sdk/components/form/LabelToolTip'
import { VerticalSpace } from 'src/sdk/components/layout'
import { useIntlConfig } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { usePayment } from 'src/sdk/contexts/payment/Payment'
import { useStripeProvider } from 'src/sdk/contexts/payment/Stripe'
import { CreditCardCreateForm, CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import useWallet from 'src/sdk/hooks/useWallet'
import BillingUse from '../BillingUse'
import { PaymentMethodAddProps } from '../payment-methods/PaymentMethodAdd'
import '../Wallet.less'
import { useI18n } from '../../../../../sdk/contexts/I18n'

type CreditCardAddFormProps = {
  form: FormInstance<CreditCardCreateForm>
} & PaymentMethodAddProps

const formRules: FormRuleProps<CreditCardCreateForm> = {
  name: [
    {
      required: true,
      message: 'Please enter your full name',
    },
  ],
  zipCode: [
    {
      required: true,
      message: 'Please enter your billing zipcode',
    },
  ],
  nickname: [
    {
      required: true,
      message: 'Please enter a nickname for this card',
    },
  ],
  cardNumber: [
    {
      required: true,
      message: 'Credit card number is required',
    },
  ],
  country: [
    {
      required: true,
      message: 'Country is required',
    },
  ],
  cardExpiry: [
    {
      required: true,
      message: 'Expiration date is required',
    },
  ],
  cardCvc: [
    {
      required: true,
      message: 'Card security code is required',
    },
  ],
}

const CreditCardAddForm: FC<CreditCardAddFormProps> = ({ onLoading, onSuccess, billingUse, form }) => {
  const { fetch } = useController()
  const intl = useIntlConfig()
  const { wallet } = useWallet()
  const [currentBillingUse, setCurrentBillingUse] = useState(billingUse ?? '')
  const [country, setCountry] = useState(intl.country.toUpperCase())
  const { CardInformation, createPaymentMethod } = usePayment()
  const { notifyOnError } = useNotification()
  const stripe = useStripe()
  const { processorId } = useStripeProvider()
  const elements = useElements()

  const onFinish = async (formValues: CreditCardCreateForm) => {
    const cardNumber = elements?.getElement(CardNumberElement)
    if (!stripe || !cardNumber || !processorId) {
      return
    }

    onLoading && onLoading(true)

    createPaymentMethod(formValues)
      .then((response) => {
        return fetch(
          CreditCardEntity.add(),
          {},
          {
            token: response?.paymentMethod.id,
            billingUse: currentBillingUse,
            nickname: response?.cardDetails?.nickname,
            billingDetails: response?.paymentMethod.billingDetails,
            paymentProcessorId: processorId,
          },
        )
      })
      .then((response) => {
        onSuccess
          ? onSuccess(response)
          : notification.success({ message: 'Your payment method was successfully added' })
      })
      .catch(notifyOnError)
      .finally(() => onLoading && onLoading(false))
  }
  const {t} = useI18n()
  return (
    <VerticalSpace size={24}>
      {wallet && wallet?.length > 0 && !billingUse && (
        <BillingUse value={currentBillingUse} onChange={setCurrentBillingUse} />
      )}

      <Form<CreditCardCreateForm> form={form} onFinish={onFinish}>
        <Item
          rules={formRules.name}
          name='name'
          label={
            <LabelTooltip
              label={'Name on card'}
              helpText={'Enter your first and last name exactly as they appear on your billing statement'}
            />
          }
        >
          <Input />
        </Item>
        <CardInformation rules={formRules} />
        <CompactGroup>
          <CompactItem width={50} collapseWidth={100} initialValue={country} name={['country']} label={'Country'}>
            <FormItem.Countries onChange={(value) => setCountry(value.toUpperCase())} />
          </CompactItem>
          <CompactItem
            name={'zipCode'}
            required
            width={50}
            collapseWidth={50}
            label={country === 'US' ? 'Zipcode' : 'Postal Code'}
          >
            <FormItem.ZipCode name={'zipCode'} country={country} />
          </CompactItem>
          <CompactItem
            name={'nickname'}
            width={100}
            collapseWidth={100}
            rules={formRules.nickname}
            label={
              <LabelTooltip
                label={'Card Nickname'}
                helpText={'Enter a nickname to easily identify this payment method in the future'}
              />
            }
          >
            <Input />
          </CompactItem>
        </CompactGroup>
      </Form>
    </VerticalSpace>
  )
}

export default CreditCardAddForm
