import axios, { AxiosResponse, Method } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { ApiError } from '../api/error'
import { RemoveNullValues } from '../helpers/object'
import { Resource, RestEndpoint } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { IntlConfig } from './config'

export type UrlProps = {
  id?: Data.ID
  action?: string
}

export type PaginatedSchema<T> = {
  results: T[]
  pagination: PaginatedHeader
}

class PaginatedHeader {
  pageNumber: number = 1
  totalCount: number = 0
  pageSize: number = 0
  hasPreviousPage: boolean = false
  hasNextPage: boolean = false
}
export type PaginatedQuery = Omit<Record<string, any>, 'pageNumber'>
export class PaginatedData<T> {
  results: T[] = []
  pagination: PaginatedHeader = new PaginatedHeader()
  static schema = {
    pagination: PaginatedHeader,
  }
}
export class SchemaPaginatedTracker {
  pageNumber: number = 0
  keys: Data.ID[] = []
}

export type SchemaPaginated<T> = {
  results: T[]
  current: T[]
  tracker: SchemaPaginatedTracker[]
  pagination: PaginatedHeader
}

export abstract class ApiSingletonResource extends Resource {
  static error: ApiError | undefined

  static async fetch(input: RequestInfo, init: RequestInit) {
    const url = typeof input === 'string' ? input.replace('/the_only_one', '') : input.url
    let options: RequestInit = init
    const language = `${JSON.parse(localStorage.getItem('locale') ?? JSON.stringify(IntlConfig.fromJS().locale))}`.split(
      '-',
    )[0]
    let headers: Record<string, string> = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': language,
    }

    if (init.headers) {
      Object.entries(init.headers).map((header) => {
        headers[header[0]] = header[1]
      })
    }

    if (process.env.NODE_ENV === 'development') {
      headers.ProxyKey = `${process.env.REACT_APP_PROXY_KEY}`
    }


    options = {
      method: (init.method ?? 'GET') as Method,
      headers: headers,
      body:
        options.body ?
          options.body instanceof FormData ? options.body :
            JSON.stringify(this.serialize(typeof options.body === 'string' ? JSON.parse(options.body) : options.body)) : undefined,
    }
    return fetch(url, options)
      .then(async (response) => {
        let json: any = ''
        try {
          json = await response.json()
        } catch {
          try {
            json = await response.text()
          } catch { }
        }
        return {
          response,
          json,
        }
      })
      .then(({ response, json }) => {
        if (response.status === 401) {
          var location = response.headers.get('location')
          if (location) {
            return (window.location.pathname = location)
          }
        }

        if (!response.status.toString().startsWith('2')) {
          throw new ApiError(response.status, json)
        }

        return json
      })
      .then((response) => this.deserialize(response))
  }

  static async query(url: string = '', params: Record<string, any> = {}) {
    const URL = url ? url : this.urlRoot
    var headers: Record<string, string> = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    const queryString = Object.keys(params).length > 0 ? `?${this.queryString(params)}` : ''
    return await this.fetch(`${URL}${queryString}`, {
      method: 'GET',
      headers: headers,
    })
    // return this.deserialize(result)
  }

  static async getByIdStateless(id: Data.ID) {
    return this.query(`${this.urlRoot}/${id}`)
  }

  static buildQueryUrl(url: string, params: Record<string, any>) {
    return `${url}?${this.queryString(params)}`
  }

  static async post(url: string, data: any) {
    const URL = url ? url : this.urlRoot
    var headers: Record<string, string> = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    return await super
      .fetch(URL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(this.serialize(data)),
      })
      .then((response) => this.deserialize(response))
  }

  static async postForm(input: RequestInfo, form: FormData) {
    const url = typeof input === 'string' ? input : input.url
    var headers: Record<string, string> = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    var options = {
      method: 'POST',
      headers: headers,
      body: form,
    }
    return super.fetch(url, options).then((response) => this.deserialize(response))
  }

  static async postFile(input: RequestInfo, init: RequestInit) {
    const url = typeof input === 'string' ? input : input.url
    const request = axios.request({
      method: 'POST',
      url,
      data: init.body,
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      transformResponse: (data) => (data === '' ? [] : this.deserialize(JSON.parse(data))),
    })
    return await request.then((value) => value.data)
  }

  static paging<T extends ApiResource>(params: Data.Paginated & any = {}) {
    const language = `${JSON.parse(localStorage.getItem('locale') ?? JSON.stringify(IntlConfig.fromJS().locale))}`.split(
      '-',
    )[0]
    return super
      .fetchResponse(`${this.urlRoot}?${this.queryString(params)}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Accept-Language': language },
      })
      .then(async (response) => {
        const json = await response.json()
        return {
          results: json.map((item: any) => super.fromJS(ApiResource.deserialize(item))),
          pagination: ApiResource.deserializePagination(response.headers),
        }
      })
      .then<SchemaPaginated<T>>()
  }

  static paginated<T extends typeof ApiResource>(
    this: T,
  ): RestEndpoint<
    (this: RestEndpoint, params: Data.Paginated | any) => Promise<SchemaPaginated<T>>,
    SchemaPaginated<T>,
    any
  > {
    const language = `${JSON.parse(localStorage.getItem('locale') ?? JSON.stringify(IntlConfig.fromJS().locale))}`.split(
      '-',
    )[0]
    return super.list().extend({
      fetch: async (params: Data.Paginated) => {
        return super
          .fetchResponse(this.listUrl(params), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Accept-Language': language },
          })
          .then(async (response) => {
            const json = response.status !== 204 ? await response.json() : response.text()
            return {
              results:
                Array.isArray(json) && json.length > 0
                  ? json.map((item: any) => super.fromJS(ApiResource.deserialize(item)))
                  : [],
              pagination: ApiResource.deserializePagination(response.headers),
            }
          })
          .then<SchemaPaginated<T>>()
      },
      update: (newResults: any, { pageNumber, ...rest }: Data.Paginated & Record<string, any>) => ({
        [this.paginated().key(rest)]: ApiResource.appendList.bind(ApiResource, { ...newResults, ...rest }),
      }),
      schema: {
        results: [this],
        current: [this],
        tracker: [new SchemaPaginatedTracker()],
        pagination: new PaginatedHeader(),
      },
    })
  }
  static appendList(
    newResults: { results: string[]; pagination: PaginatedHeader } | undefined,
    existingResults: { results: string[]; tracker: SchemaPaginatedTracker[]; pagination: PaginatedHeader } | undefined,
  ) {
    const existingList = existingResults?.results ?? []
    const existingSet: Set<string> = new Set(existingList)
    const addedList = newResults?.results.filter((pk: string) => !existingSet.has(pk))
    const mergedResults: string[] = [...existingList, ...(addedList ?? [])]
    return {
      results: mergedResults,
      current: newResults?.results,
      pagination: newResults?.pagination,
      tracker: [
        ...(existingResults?.tracker
          ? existingResults?.tracker.filter((t) => t.pageNumber !== newResults?.pagination.pageNumber)
          : []),
        newResults
          ? {
            pageNumber: newResults?.pagination.pageNumber,
            keys: newResults?.results,
          }
          : {},
      ],
    }
  }
  static queryString = (params: Record<string, any>): string => {
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.length === 0 && delete params[key]
      } else if (value === undefined || value === '') {
        delete params[key]
      }
    })
    return `${new URLSearchParams(this.serialize(params))}`
  }

  // Converts query parameters to snake_case
  static listUrl(params?: Partial<Data.Paginated & Record<string, unknown>>): string {
    const cleanedParams = params ? RemoveNullValues(params) : {}
    return super.listUrl(this.serialize(cleanedParams))
  }
  // Converts query parameters to snake_case
  static url(params?: Partial<Data.Identified & Record<string, any>>): string {
    return super.url(this.serialize({ ...params }))
  }

  static distinct<T extends Data.Identified>(value: T, index: number, self: T[]): boolean {
    return self.findIndex((it) => it.id === value.id) === index
  }
  static serialize<To>(data: any[] | Record<string, any>): To {
    return snakecaseKeys(data, { deep: true }) as To
  }
  static deserialize<T>(data: any[] | Record<string, any>): T {
    return camelcaseKeys(data, { deep: true }) as T
  }
  static deserializePagination(headers: Headers) {
    const paginationHeader = headers.get('pagination')
    let pagination = {}
    if (paginationHeader) {
      const obj = JSON.parse(paginationHeader)
      pagination = camelcaseKeys<PaginatedHeader>(obj, { deep: true }) as PaginatedHeader
    }
    return pagination
  }
  pk(): string | undefined {
    return 'the_only_one'
  }
}
export abstract class AbstractResource extends Resource implements Data.Identified {
  static error: ApiError | undefined
  id: Data.ID = 0
  static async fetch(input: RequestInfo, init: RequestInit): Promise<AxiosResponse> {
    const language = `${JSON.parse(localStorage.getItem('locale') ?? JSON.stringify(IntlConfig.fromJS().locale))}`.split(
      '-',
    )[0]
    const url = typeof input === 'string' ? input : input.url
    const request = axios.request({
      method: (init.method ?? 'get') as Method,
      url,
      data: init.body,
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Accept-Language': language },
      transformRequest: (data) => {
        return data && JSON.stringify(this.serialize(JSON.parse(data)))
      },
      transformResponse: (data) => {
        return data === '' ? [] : this.deserialize(JSON.parse(data))
      },
    })
    return await request
      .then((value) => value.data)
      .catch((error) => {
        if (error.response) {
          // this.error = Object.assign(new ApiError(), error.response.data, { code: error.response.status })
          // this.error = { code: error.response.status, error: '', errorDescription: '', errorMessage: error.response.statusText }
          // Force the user to be logged out when a 401 unauthorized response is returned
          if (this.error && this.error.code === 401 && url !== '/login') {
            window.location.replace('/logout')
          }
          console.log(this.error)
          // return request
          // return this.error
          throw this.error
        } else {
          console.log(error)
          return error
        }
      })
  }
  static async postFile(input: RequestInfo, init: RequestInit): Promise<AxiosResponse> {
    const url = typeof input === 'string' ? input : input.url
    const request = axios.request({
      method: (init.method ?? 'get') as Method,
      url,
      data: init.body,
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      transformResponse: (data) => (data === '' ? [] : this.deserialize(JSON.parse(data))),
    })
    return await request
      .then((value) => value.data)
      .catch((error) => {
        if (error.response) {
          // this.error = Object.assign(new ApiError(), error.response.data, { code: error.response.status })
          // this.error = { code: error.response.status, error: '', errorDescription: '', errorMessage: error.response.statusText }
          // Force the user to be logged out when a 401 unauthorized response is returned
          if (this.error && this.error.code === 401 && url !== '/login') {
            window.location.replace('/logout')
          }
          console.log(this.error)
          // return request
          throw this.error
        } else {
          console.log(error)
          return error
        }
      })
  }
  static fetchById(id: string | number): Promise<AxiosResponse> {
    const endpoint = `${this.urlRoot}/${id}`
    return this.fetch(endpoint, {
      method: 'GET',
    })
  }
  static listUrl(params?: Partial<Data.Paginated & Record<string, unknown>>): string {
    return super.listUrl(this.serialize({ ...params }))
  }
  static url(params?: Partial<Data.Identified & Record<string, unknown>>): string {
    return super.url(this.serialize({ ...params }))
  }
  static queryString = (params: Record<string, unknown>): string =>
    `${new URLSearchParams(AbstractResource.serialize(params))}`

  static distinct<T extends Data.Identified>(value: T, index: number, self: T[]): boolean {
    return self.findIndex((it) => it.id === value.id) === index
  }
  static serialize<To>(data: any[] | Record<string, any>): To {
    return snakecaseKeys(data, { deep: true }) as To
  }
  static deserialize<T>(data: any[] | Record<string, any>): T {
    return camelcaseKeys(data, { deep: true }) as T
  }
  pk(): string | undefined {
    return this.id?.toString()
  }
}

export abstract class ApiResource extends ApiSingletonResource implements Data.Identified {
  id: Data.ID = '0'
  pk(): string | undefined {
    return this.id?.toString()
  }
}
