import { Entity, EntityRecord } from '@rest-hooks/rest'
import moment from 'moment'
import { useResource } from 'rest-hooks'
import Placeholder from 'src/sdk/images/placeholders/directory-company.svg'
import { ExpandableKeyValue } from '../components/collapse/ExpandableList'
import { Capitalize } from '../helpers/strings'
import { BaseAddress } from './address'
import { CategoryEntity } from './category'
import { DirectoryDetail } from './directory'
import { ApiResource, SchemaPaginated } from './entity'
import { DayOfWeek, DaysOfWeek } from './intl'
import { ImageEntity } from './media'
import { ProductResource } from './product'

export type BusinessType = 'commerce' | 'business' | 'restaurant'

export type BusinessSocial = {
  facebook?: string
  twitter?: string
  pinterest?: string
  linkedIn?: string
  flickr?: string
  vimeo?: string
  youtube?: string
}

export class BusinessHours extends Entity {
  id?: Data.ID
  sunday?: string = 'closed'
  monday?: string = 'closed'
  tuesday?: string = 'closed'
  wednesday?: string = 'closed'
  thursday?: string = 'closed'
  friday?: string = 'closed'
  saturday?: string = 'closed'

  public constructor(...args: any[])

  public constructor(id: number, hours?: BusinessHours) {
    super()
    this.id = id
    if (hours === undefined) return
    this.sunday = hours?.sunday ?? 'closed'
    this.monday = hours?.monday ?? 'closed'
    this.tuesday = hours?.tuesday ?? 'closed'
    this.wednesday = hours?.wednesday ?? 'closed'
    this.thursday = hours?.thursday ?? 'closed'
    this.friday = hours?.friday ?? 'closed'
    this.saturday = hours?.saturday ?? 'closed'
  }

  get keyValue(): ExpandableKeyValue[] {
    const days = Object.keys(this)
    if (days.some((day) => DaysOfWeek.includes(day as DayOfWeek) && this[day] !== '')) {
      return days.reduce((acc, day) => {
        if (!DaysOfWeek.includes(day as DayOfWeek)) return acc
        acc.push({
          key: Capitalize(day),
          value: this[day] ? this[day] : 'Closed',
        })
        return acc
      }, [] as ExpandableKeyValue[])
    }
    return []
  }

  get hasHours(): boolean {
    return Object.keys(this).filter((key) => key !== 'id' && this[key] !== '').length > 0
  }

  get closedDays(): string[] {
    return Object.keys(this).filter((key) => this[key] === '')
  }

  get today(): string | null {
    const dayOfWeek = moment().locale('en').format('dddd').toLowerCase()
    const today = this[dayOfWeek]
    return today !== '' ? today : 'Closed'
  }

  get closesToday(): string | undefined {
    if (!this.today) return undefined
    const hour_split = this.today.split('-')
    if (hour_split.length < 2) return undefined
    return hour_split[1].trim()
  }

  get opensNext() {
    const date = moment().locale('en')
    const today = date.format('dddd').toLowerCase() as DayOfWeek
    const tomorrow = date.add(1, 'day').format('dddd').toLowerCase() as DayOfWeek
    let day = date
    let opensAt = ''
    let count = 0

    while (count < 6) {
      day = date.add(1, 'day')
      let dayOfWeek = day.format('dddd').toLowerCase()
      let openTime = this.openTime(dayOfWeek as DayOfWeek)
      if (openTime) {
        if (dayOfWeek === today) {
          dayOfWeek = 'today'
        } else if (dayOfWeek === tomorrow) {
          dayOfWeek = 'tomorrow'
        }
        opensAt = `${dayOfWeek} at ${openTime}`
        break
      }
      count++
    }
    return opensAt !== '' ? opensAt : null
  }

  pk() {
    return `${this.id}`
  }

  openTime(day: DayOfWeek) {
    var times = this[day]
    if (!times) return
    const hour_split = times.split('-')
    return hour_split[0]?.trim()
  }

  closeTime(day: DayOfWeek) {
    var times = this[day]
    if (!times) return
    const hour_split = day.split('-')
    return hour_split.length > 1 ? hour_split[1] : undefined
  }

  isOpen(timezoneOffset: number): boolean | undefined {
    if (!this.closesToday) return undefined
    const businessLocal = moment().utc().utcOffset(timezoneOffset, false)
    const businessCloseLocal = moment(`${businessLocal.format('YYYY-MM-DD')}T${this.closesToday}`, 'YYYY-MM-DDTh:mm A')

    if (businessCloseLocal.format('A') === 'AM') {
      businessCloseLocal.add(1, 'day')
    }
    return businessLocal.isBefore(businessCloseLocal)
  }
}

export class BusinessContact extends EntityRecord {
  readonly id: number = 0
  readonly businessId: number = 0
  readonly firstName: string = ''
  readonly lastName: string = ''
  readonly middleName: string = ''
  readonly phoneNumber: string = ''
  readonly mobileNumber: string = ''
  readonly email: string = ''
  readonly profilePhoto: string = ''

  pk(): string {
    return `${this.id}`
  }
}

export class BusinessLocation extends EntityRecord {
  static schema = {
    address: BaseAddress,
  }
  readonly id: number = 0
  readonly businessId: number = 0
  readonly name: string = ''
  readonly email: string = ''
  readonly website?: string
  readonly phoneNumber: string = ''
  readonly tollFreeNumber: string = ''
  readonly latitude: string = ''
  readonly longitude: string = ''
  readonly hours?: BusinessHours = BusinessHours.fromJS()
  readonly address: BaseAddress = BaseAddress.fromJS()

  pk(): string {
    return `${this.id}`
  }
}

export type BusinessQuery = {
  categoryId?: Data.ID
  search?: string
  group?: string
  orderBy?: string
} & Data.Paginated

export class BusinessEntity extends ApiResource implements Data.Named, Data.SingleCategory {
  static urlRoot = `/api/directory/businesses`
  static schema = {
    products: [ProductResource],
    media: [ImageEntity],
    // survey: SurveyEntity
  }
  // private _hours?: BusinessHours
  readonly hasProducts: boolean = false
  readonly categoryId: Data.ID = ''
  readonly category: CategoryEntity = CategoryEntity.fromJS()
  readonly address?: BaseAddress
  readonly name: string = ''
  readonly website: string = ''
  readonly about: string = ''
  readonly slogan?: string
  readonly brief?: string
  readonly email: string = ''
  readonly phoneNumber: string = ''
  readonly type: BusinessType = 'business'
  readonly banner: string = ''
  readonly graphic: string = ''
  readonly surveyId: Data.ID = 0
  readonly contactId: Data.ID = 0
  readonly contacts?: BusinessContact[]
  readonly media?: ImageEntity[]
  readonly details: DirectoryDetail[] = []
  readonly locations?: BusinessLocation[]
  readonly products?: ProductResource[]
  readonly social: BusinessSocial = {}

  // Create hours entity using the business ID as the PK
  get hours() {
    if (!this.locations || this.locations.length === 0) return undefined
    const location = this.locations.find((l) => l.hours)
    if (!location) return BusinessHours.fromJS()
    return BusinessHours.fromJS(location.hours)
  }

  // set hours(value: BusinessHours) {
  //   this._hours = value
  // }

  get featured() {
    return this.category.featured
  }

  get categoryIds() {
    return [this.categoryId, this.category?.categoryAbove]
  }

  // To satisfy the search with multiple categories
  get categories(): CategoryEntity[] {
    return [this.category]
  }

  get contact(): BusinessContact | undefined {
    return this.contacts?.find((contact) => contact.id === this.contactId)
  }

  get image(): string {
    return this.graphic.length ? this.graphic : Placeholder
  }

  get images(): string[] {
    return this.media && this.media.length > 0
      ? this.media.filter((m) => m.type === 'image')?.map((m) => m.url)
      : [Placeholder]
  }

  get mainLocation(): BusinessLocation | null {
    if (!this.locations) return null
    const location = this.locations[0]
    return location ? location : null
  }

  static getPaginated(params: BusinessQuery = {}): SchemaPaginated<BusinessEntity> {
    return useResource(this.paginated(), params)
  }

  // static paginated(params: BusinessQuery): Promise<PaginatedData<BusinessEntity>> {
  //   return this.paging(this.urlRoot, params)
  // }

  static listAll(params: BusinessQuery): BusinessEntity[] {
    return useResource(this.list(), params)
  }

  static getById(params: Data.Identified): BusinessEntity {
    return useResource(this.detail(), params)
  }
}
