import { Image, Result as Wrapped, Row, Typography } from 'antd'
import { ResultStatusType } from 'antd/lib/result'
import { CSSProperties, FC, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import PublicView from 'src/components/public'
import { useAuth } from 'src/sdk/contexts/Auth'
import { withPrefix } from 'src/sdk/contexts/Config'
import { OrderEntity } from 'src/sdk/datasource/order'
import { Button } from '../form'
import { VerticalSpace } from '../layout'
import { Breakpoint } from '../screen/Breakpoint'
import './Result.less'
import { Title } from '../text'
import { Text } from '../text/Text'
import { useI18n } from '../../contexts/I18n'

type NamedProps = {
  name:
    | 'no-memberships-error'
    | 'checkout-error'
    | 'checkout-success'
    | 'page-error'
    | 'page-not-found'
    | 'page-success'
    | 'recommend-error'
    | 'recommend-success'
    | 'no-data'
    | 'add-info'
  style?: CSSProperties
}

const ResultImage: FC<Partial<NamedProps>> = ({ name, style }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  return (
    <Image
      src={`${process.env.PUBLIC_URL}/assets/result/${name}.svg`}
      height={isMobile ? 167 : 212}
      preview={false}
      style={style}
    />
  )
}

type ResultPageProps = typeof Wrapped.defaultProps

const ResultPage: FC<ResultPageProps & Partial<NamedProps>> = ({
  children,
  title,
  subTitle,
  name = 'page-success',
  extra,
}) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: Breakpoint.SM })
  return (
    <Row align={'middle'} justify={'center'} className={withPrefix('result-page')}>
      <Wrapped
        style={{ paddingTop: 0 }}
        title={
          <Title type={'secondary'} level={isMobile ? 4 : 3}>
            {title}
          </Title>
        }
        subTitle={<Text type={'secondary'}>{subTitle}</Text>}
        icon={<ResultImage name={name} />}
        extra={
          extra ?? (
            <Button onClick={history.goBack} title={'Go back'} block={isMobile} htmlType={'button'} type={'primary'} />
          )
        }
      >
        {children}
      </Wrapped>
    </Row>
  )
}

const Redirecting: FC<ResultPageProps> = ({ ...props }) => (
  <ResultPage
    status={'success'}
    title={'Redirecting'}
    subTitle={'Please wait while we redirect you.'}
    extra={''}
    name={'page-error'}
    {...props}
  />
)

const NoMemberships: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'error'}
    title={'Not a Member'}
    subTitle={'Looks like you are not a member yet'}
    extra={''}
    name={'page-error'}
    {...props}
  />
)

const RecommendFailed: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'error'}
    title={'Ooops. Your recommendation request failed!'}
    subTitle={
      'We are sorry, but something went wrong with your recommendation request! Please check your connection or try again later.'
    }
    extra={children}
    name={'recommend-error'}
    {...props}
  />
)

const NominateCompleted: FC<ResultPageProps> = ({ title = undefined, subTitle = undefined, children, ...props }) => (
  <ResultPage
    status={'success'}
    title={title ?? 'You have submitted your recommendation request successfully!'}
    subTitle={subTitle ?? 'Congratulations! Your nominee will receive the invitation email in a short time.'}
    extra={children}
    name={'recommend-success'}
    {...props}
  />
)

const AdditionalMemberFailed: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'error'}
    title={'Ooops. There was a problem adding your additional member.'}
    subTitle={
      props.subTitle ??
      'We are sorry, but something went wrong with this additional member request.  Please check your connection or try again later.'
    }
    extra={children}
    name={'recommend-success'}
    {...props}
  />
)

const AdditionalMemberCompleted: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'success'}
    title={'Your new member has been successfully added!'}
    subTitle={'Congratulations! Your new sub member will receive an email with details shortly.'}
    extra={children}
    name={'recommend-success'}
    {...props}
  />
)

const Success: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'success'}
    title={'Congratulations!'}
    subTitle={'Well done. Thanks for being with us.'}
    extra={children}
    name={'page-success'}
    {...props}
  />
)

type ErrorPageProps = {
  backButton?: boolean
  status?: ResultStatusType
  title?: string
  message?: string | ReactNode
} & ResultPageProps

const Error: FC<ErrorPageProps> = ({
  title = 'Sorry, an unexpected error occurred',
  message = 'We are working on fixing problem.',
  status,
  backButton = true,
  ...props
}) => {
  const history = useHistory()
  const { authenticated } = useAuth()
  const ErrorView = (
    <ResultPage
      status={status}
      title={title}
      subTitle={message}
      extra={
        <VerticalSpace>
          {backButton && <Button key={'back'} title={'Go Back'} onClick={() => window.location.replace('/')} />}
          {authenticated && (
            <Button key={'logout'} type={'ghost'} title={'Logout'} onClick={() => history.push('/logout')} />
          )}
        </VerticalSpace>
      }
      name={'page-error'}
      {...props}
    />
  )
  return authenticated ? ErrorView : <PublicView children={ErrorView} />
}

type NotFoundProps = {
  includeButtons?: boolean
} & ResultPageProps
const NotFound: FC<NotFoundProps> = ({ includeButtons, ...props }) => (
  <ResultPage
    status={404}
    title={'Oops. Page not found'}
    subTitle={'Looks like we can’t find this page anymore.'}
    extra={
      includeButtons && (
        <VerticalSpace>
          <Button key={'back'} title={'Go Back'} onClick={() => window.location.replace('/')} />
          <Button key={'logout'} type={'ghost'} title={'Logout'} onClick={() => window.location.replace('/logout')} />
        </VerticalSpace>
      )
    }
    name={'page-not-found'}
    {...props}
  />
)

const MembersOnly: FC<ResultPageProps> = (props) => (
  <ResultPage
    status={403}
    title={'Members Only'}
    subTitle={'Hold up!  You must be an active member to access this page.'}
    extra={
      <VerticalSpace>
        <Button key={'back'} title={'Go Back'} onClick={() => window.location.replace('/')} />
      </VerticalSpace>
    }
    name={'page-not-found'}
    {...props}
  />
)

export type NoDataProps = {
  title: string
  subTitle: string
} & ResultPageProps

const NoData: FC<NoDataProps> = ({ title, subTitle, ...props }) => (
  <ResultPage status={403} title={title} subTitle={subTitle} name={'checkout-error'} {...props} />
)

const Forbidden: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    status={'403'}
    title={'Hold up! Access Restricted'}
    subTitle={'We are sorry, but you do not have access to this page.'}
    extra={children}
    name={'page-error'}
    {...props}
  />
)

export type PaymentResultProps = {
  order?: OrderEntity
  type?: string
} & ResultPageProps

const PaymentFailed: FC<PaymentResultProps> = ({ order, children, ...props }) => (
  <ResultPage
    status={'error'}
    title={'Checkout failed'}
    subTitle={'Your transaction has failed.  See the details below and try again.'}
    extra={children}
    name={'checkout-error'}
    {...props}
  />
)
const PaymentCompleted: FC<PaymentResultProps> = ({ order, type, children, ...props }) => (
  <ResultPage
    status={'success'}
    title={`Thank you for your ${type ? type : 'order'}!`}
    subTitle={'You will receive an email confirmation shortly.'}
    extra={children}
    name={'checkout-success'}
    {...props}
  />
)

type NotImplementedProps = {
  reference?: string
} & ResultPageProps

const NotImplemented: FC<NotImplementedProps> = ({ reference, children, ...props }) => (
  <ResultPage
    status={'403'}
    title={'Hold up! Something is not implemented properly'}
    subTitle={`If you believe you have reached this error by mistake, contact customer service ${
      reference ? `and mentioned reference code: ${reference}` : ''
    }`}
    extra={children}
    name={'page-error'}
    {...props}
  />
)

const NotSetup: FC<ResultPageProps> = ({ title, subTitle, children, ...props }) => (
  <ResultPage
    status={'403'}
    title={title ?? "Whoops, You're not setup to do this"}
    subTitle={subTitle ?? 'You might be missing a required setting to do this'}
    extra={children}
    name={'page-error'}
    {...props}
  />
)

const Result = {
  Success,
  NotFound,
  Forbidden,
  Error,
  NotImplemented,
  PaymentCompleted,
  PaymentFailed,
  NominateCompleted,
  RecommendFailed,
  AdditionalMemberCompleted,
  AdditionalMemberFailed,
  NoMemberships,
  NotSetup,
  MembersOnly,
  Redirecting,
  NoData,
} as const

export { Result }
