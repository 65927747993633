import { Col, List, notification, Row, Space, Table } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { useDLE } from 'rest-hooks'
import { Button } from 'src/sdk/components/form'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { SectionLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import { BreakpointMax } from 'src/sdk/components/screen/Breakpoint'
import { Money, Title } from 'src/sdk/components/text'
import { usePrivateConfig, withPrefix } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { OrderEntity } from 'src/sdk/datasource/order'
import { TransactionEntity } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'
import { Text } from '../../sdk/components/text/Text'
import { useI18n } from '../../sdk/contexts/I18n'
import { OrderDetails } from '../account/tabs/order/OrderDetails'
import { InvoicePayButton } from './InvoicePay'

type SummaryListItemProps = {
  id: Data.ID
  title: ReactNode
  description: ReactNode
}
const SummaryListItem: FC<SummaryListItemProps> = ({ id, title, description }) => (
  <List.Item>
    <Row align={'top'} justify={'space-between'} wrap={false} key={id}>
      <Col flex={'33%'}>
        <Text type={'secondary'}>{title}</Text>
      </Col>
      <Col flex={'auto'} style={{ textAlign: 'right' }}>
        <Text strong>{description}</Text>
      </Col>
    </Row>
  </List.Item>
)

const SummaryRow: FC<Data.Source<SummaryListItemProps[]>> = ({ data }) => (
  <List
    rowKey={(it) => it.id}
    itemLayout={'vertical'}
    dataSource={data}
    renderItem={(props) => <SummaryListItem {...props} />}
  />
)

const TransactionDetails: FC<Data.Identified> = ({ id }) => {
  const { company } = usePrivateConfig()
  const { data: transaction, loading } = useDLE(TransactionEntity.detail(), id ? { id: id } : null)
  const { notifyOnError } = useNotification()
  const [sending, setSending] = useState<boolean>(false)
  const { data: order, loading: orderLoading } = useDLE(
    OrderEntity.detail(),
    transaction?.orderId ? { id: transaction?.orderId } : null,
  )
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.MD })

  const sendTransactionEmail = async () => {
    setSending(true)
    await TransactionEntity.sendByEmail({ id })
      .then(() => {
        notification.success({
          message: 'Email sent',
        })
      })
      .catch(() => notifyOnError)
      .finally(() => setSending(false))
  }
  const { t } = useI18n()
  return loading ? (
    <SectionLoader />
  ) : transaction ? (
    <VerticalSpace size={16}>
      <Row align={'middle'} justify={'space-between'} gutter={[20, 40]}>
        <Col span={24}>
          <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Title level={2}>Invoice Details</Title>
            <Title level={5}>
              <HorizontalSpace>
                <Text>Invoice</Text>
                <Text>#{id}</Text>
              </HorizontalSpace>
            </Title>
          </Space>
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={50} style={{ marginBottom: 32 }}>
        <Col span={12} lg={{ span: 11 }}>
          <VerticalSpace size={24}>
            <VerticalSpace size={0}>
              <Text strong>{company.name}</Text>
              {company.fullAddress && <Text>{company.fullAddress}</Text>}
              {company.cityStateZip && <Text>{company.cityStateZip}</Text>}
            </VerticalSpace>
            <VerticalSpace size={0}>
              <Title level={4}>Billed To</Title>
              <Text strong>{transaction.customer.fullName}</Text>
              <Text>{transaction.billingAddress?.fullAddress}</Text>
              <Text>{transaction.billingAddress?.cityStateZip}</Text>
            </VerticalSpace>
          </VerticalSpace>
        </Col>

        <Col span={12} lg={{ span: 8 }}>
          <SummaryRow
            data={[
              {
                id: 'createdOn',
                title: t('Invoiced'),
                description: <FormattedDate value={transaction?.chargedOn} />,
              },
              {
                id: 'status',
                title: t('Status'),
                description: Capitalize(transaction?.status),
              },
              {
                id: 'balance',
                title: t('Balance Due'),
                description: (
                  <Money currency={transaction.currency}>
                    {transaction?.status === 'invoiced' ? transaction?.amountDue : 0}
                  </Money>
                ),
              },
            ]}
          />
          {transaction.companyId === company.id && transaction.status === 'invoiced' && transaction.amountDue > 0 && (
            <InvoicePayButton transaction={transaction} />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {orderLoading ? (
            <SectionLoader />
          ) : order ? (
            <OrderDetails includePayments={false} includeFooter={false} includeHeader={false} order={order} />
          ) : (
            <Table
              className={withPrefix('transaction-details-totals')}
              pagination={false}
              columns={[
                {
                  title: t('Description'),
                  key: 'title',
                  dataIndex: 'title',
                },
                {
                  title: t('Total'),
                  key: 'description',
                  dataIndex: 'description',
                  align: 'right',
                },
              ]}
              dataSource={transaction.data.map((item, index) => ({
                key: index,
                title: item.referenceItem,
                description: <Money currency={transaction.currency}>{item.amount}</Money>,
              }))}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} lg={{ span: 8, offset: 16 }}>
          <VerticalSpace align={'end'} className={withPrefix('payment-totals')}>
            <Text context={{ amount: transaction.subTotal, currency: transaction.currency }}>
              {'Subtotal: {amount}'}
            </Text>

            {transaction.discount || order?.discountTotal ? (
              <Text
                context={{
                  amount: transaction.discount * -1 || (order && order?.discountTotal),
                  currency: transaction.currency,
                }}
              >
                {'Discount: {amount}'}
              </Text>
            ) : null}

            <Text context={{ amount: `${transaction.tax}`, currency: transaction.currency }}>{'Tax: {amount}'}</Text>

            {transaction.refundedAmount > 0 && (
              <Text context={{ amount: transaction.refundedAmount * -1, currency: transaction.currency }}>
                {'Refund: {amount}'}
              </Text>
            )}
            <Text context={{ amount: transaction.amountDue, currency: transaction.currency }}>{'Total: {amount}'}</Text>
          </VerticalSpace>
        </Col>
        <Col span={24} lg={6}>
          <Button style={{ marginTop: 20 }} loading={sending} onClick={sendTransactionEmail} type={'primary'} block>
            Send by Email
          </Button>
        </Col>
      </Row>
    </VerticalSpace>
  ) : (
    <Result.NotFound title={'Not Found'} subTitle={'This transaction could not be found'} />
  )
}

export { SummaryListItem, TransactionDetails }
