import { Collapse as Wrapper, CollapseProps as WrapperProps, List } from 'antd'
import { Typography } from 'antd/es'
import { BaseType } from 'antd/lib/typography/Base'
import { FC, useEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Split } from '../layout/Grid'
import './ExpandableList.less'
import { Text } from '../text/Text'

export type ExpandableKeyValue = {
  key: string | number
  value: string | number
}

export type ExpandableListProps = {
  title?: string
  data: ExpandableKeyValue[] | string[]
  defaultKey?: number
  loading?: boolean
  collapsed?: boolean
  type?: BaseType
  onCollapseChange?: (collapsed: boolean) => void
} & WrapperProps

const ExpandableList: FC<ExpandableListProps> = ({
  data,
  type,
  title,
  defaultKey = 0,
  loading,
  collapsed,
  onCollapseChange,
  children,
  ...props
}) => {
  const { Panel } = Wrapper
  const [activeKey, setActiveKey] = useState<string | string[]>(collapsed === false ? '1' : [])

  useEffect(() => {
    setActiveKey(loading === true ? [] : collapsed === false ? '1' : [])
  }, [loading])

  return (
    <Wrapper
      activeKey={activeKey}
      onChange={(key) => {
        setActiveKey(key)
        onCollapseChange && onCollapseChange(key.length === 0)
      }}
      expandIconPosition={props.expandIconPosition ?? 'right'}
      collapsible={loading ? 'disabled' : 'header'}
      className={withPrefix('collapse-expandable')}
      {...props}
    >
      {data && (
        <Panel
          key={'1'}
          header={
            <Text type={type} strong>
              {title ? title : data[0]}
            </Text>
          }
        >
          <List>
            {data.map((item: ExpandableKeyValue | string) => item && (
              <List.Item key={`expandable-item-${typeof item === 'string' ? item : item.key}`}>
                {typeof item === 'string' ? (
                  <Text type={type}>{item}</Text>
                ) : <Split>
                    {<Text type={type} strong>
                      {item?.key}
                    </Text>}
                    <Text type={type}>{item?.value}</Text>
                  </Split>
                }
              </List.Item>
            ))}
          </List>

          {children}
        </Panel>
      )}
    </Wrapper>
  )
}

export default ExpandableList
