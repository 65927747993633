import classNames from 'classnames'
import { createContext, PropsWithChildren, ReactElement, ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { useResource } from 'rest-hooks'
import { RouteMap } from 'src/containers/RouteMap'
import { Handshake, IntlConfig, PageConfig, PortalConfig, PropsConfig, SocialItem } from 'src/sdk/datasource/config'
import { CompanyEntity } from '../datasource/company'

const defaultPages: PageConfig[] = [
  {
    icon: 'custom/office',
    uri: RouteMap.index,
    name: 'My Club',
    membersOnly: false,
    enabled: false,
    nav: true,
    order: 0,
  },
  {
    icon: 'custom/dining',
    uri: RouteMap.dinings,
    name: 'Dining',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 1,
  },
  {
    icon: 'custom/events',
    uri: RouteMap.event,
    name: 'Events',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 2,
  },
  {
    icon: 'custom/appointments',
    uri: RouteMap.spas,
    name: 'Spa & Wellness',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 3,
  },
  {
    icon: 'custom/appointments',
    uri: RouteMap.appointments,
    name: 'Appointments',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 4,
  },
  {
    icon: 'custom/office',
    uri: RouteMap.rooms,
    name: 'Meeting Rooms',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 5,
  },
  {
    icon: 'application/team',
    uri: RouteMap.directory,
    name: 'Directory',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 6,
  },
  {
    icon: 'application/message',
    uri: RouteMap.messages,
    name: 'Messages',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 7,
  },
  {
    icon: 'custom/books',
    uri: RouteMap.guestList,
    name: 'Guest List',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 8,
  },
  {
    icon: 'application/gift',
    uri: RouteMap.clubBenefits,
    name: 'Club Benefits',
    membersOnly: true,
    enabled: false,
    nav: true,
    order: 9,
  },
]

type ConfigContext = {
  company: CompanyEntity
  portal: PortalConfig
}

const defaultPublicConfig = new PortalConfig()
const defaultPrivateConfig = new CompanyEntity()

const Config = createContext<ConfigContext>({ portal: defaultPublicConfig, company: defaultPrivateConfig })

function ConfigProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const { portal, company } = useResource(Handshake.detail(), { id: 'the_only_one' })

  return <Config.Provider value={{ portal, company }}>{children}</Config.Provider>
}

export const usePublicConfig: () => PortalConfig = () => useContext(Config).portal
export const usePrivateConfig: () => { company: CompanyEntity } = () => {
  return { company: CompanyEntity.fromJS(useContext(Config).company) }
}
export const useCompanyConfig: () => CompanyEntity = () => useContext(Config).company
export const useIntlConfig: () => IntlConfig = () => IntlConfig.fromJS(usePublicConfig().intl)
export const usePropsConfig: () => PropsConfig = () => usePublicConfig().props

export type IntlFormatter = {
  currency: {
    (value: number, currency?: string): string
  }
  message: (id: string, defaultMessage: string) => string
}

export const useIntlFormatter: () => IntlFormatter = () => {
  const { intl } = usePublicConfig()

  const { formatNumber, formatMessage } = useIntl()
  return {
    currency: (value?: number, currency?: string) =>
      !value || isNaN(value) ? '' : formatNumber(value, { format: 'money', currency: currency ?? intl.currency }),
    message: (id: string, defaultMessage: string) => formatMessage({ id: id, defaultMessage: defaultMessage }),
  }
}

export type RoutePath = {
  base: string
  id: string
}

type MenuConfigProps = {
  pages: PageConfig[]
  pageNameByUri: (uri: string) => string
  generateUriPath: (path: string, params?: any) => string
  getBasePath: (path: string) => string
}

export const useMenuConfig: () => MenuConfigProps = () => {
  const { pages } = usePublicConfig()
  const merged = defaultPages
    .map((page) => {
      const match = pages.find((defaultPage) => defaultPage.uri === page.uri)

      return {
        ...page,
        name: match?.name ?? page.name,
        icon: match?.icon ?? page.icon,
        membersOnly: match?.membersOnly ?? page.membersOnly,
        enabled: match?.enabled ?? page.enabled,
        path: match?.path && match.path.replace(/\s/g, '') !== '' ? match.path : page.uri,
        order: match?.order ?? page.order,
      } as PageConfig
    })
    .sort((a, b) => (a.order > b.order ? 1 : -1))

  const pageNameByUri = (uri: string) => {
    const search = !uri.startsWith('/') ? `/${uri}` : uri
    const match = merged.find((page) => page.uri === search || page.path === search)
    return match?.name ?? ''
  }

  const generateUriPath = (path: string, params?: any) => {
    var base_path = getBasePath(path)
    return generatePath(base_path, params)
  }

  const getBasePath = (path: string) => {
    const split = path.split('/:')
    let basePath = split[0]
    const match = merged.find((page) => page.uri === basePath)
    if (match && match.path) {
      basePath = match.path
      if (split.length > 1) {
        basePath = basePath + '/:' + split[1]
      }
    }
    return basePath
  }

  return {
    pages: merged,
    pageNameByUri,
    generateUriPath,
    getBasePath,
  }
}

export const useSocialList = (): SocialItem[] => {
  const { social } = usePropsConfig()
  return social
    ? [
        ...(social.facebook ? [{ title: 'Facebook', icon: 'facebook', link: social.facebook }] : []),
        ...(social.twitter ? [{ title: 'Twitter', icon: 'twitter', link: social.twitter }] : []),
        ...(social.linkedIn ? [{ title: 'Linkedin', icon: 'linkedin', link: social.linkedIn }] : []),
        ...(social.google ? [{ title: 'Google', icon: 'google', link: social.google }] : []),
        ...(social.instagram ? [{ title: 'Instagram', icon: 'instagram', link: social.instagram }] : []),
      ]
    : []
}

export const withPrefix: (...names: string[]) => string = (...names) => {
  const key = 'ant'
  return classNames(
    names.filter((value) => !!value.trim()).map((name) => (!name.startsWith(key) ? `${key}-${name} ` : name)),
  ).trim()
}

export { ConfigProvider, Config }
