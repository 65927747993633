import { Typography, Card as Wrapped } from 'antd'
import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Avatar, Image } from 'src/sdk/components/image'
import { withPrefix } from 'src/sdk/contexts/Config'
import { ImageEntity } from 'src/sdk/datasource/media'
import { Gallery } from '../image/Gallery'
import { VerticalSpace } from '../layout'
import { Markup } from '../text/Markup'
import './Card.less'
import { Title } from '../text'

type CardImageProps = typeof Image.defaultProps &
  typeof Avatar.defaultProps & {
    type?: Design.PlaceholderIcon
  }

export type CardProps = {
  url: string
  title?: string | ReactNode
  type: 'primary' | 'secondary'
  equalHeight?: boolean
  theme: 'light'
  subTitle?: ReactNode | string
  description?: string | ReactNode
  meta?: ReactNode
  defaultTitle?: string
  hideDescription: boolean
  active: boolean
  onClick: MouseEventHandler
  bordered: boolean
  image?: CardImageProps
  media?: ImageEntity[]
  cover?: ReactNode
  coverBordered?: boolean
  badge: ReactNode
  style: CSSProperties
  shape: 'circle' | 'square'
  className?: string
  actions?: React.ReactNode[]
  footer?: ReactNode
  footerBordered?: boolean
  footerFlush?: boolean
  flush?: boolean
}

type TextProps = { text: string; defaultMessage?: string }

const CardTitle: FC<TextProps> = ({ text, defaultMessage = '', children }) => {
  return children ? (
    <VerticalSpace size={5} className={withPrefix('card-meta-title-wrapper')}>
      <Title title={text} style={{ maxHeight: 64 }} level={5} type={'secondary'}>
        <FormattedMessage id={text} defaultMessage={defaultMessage} />
      </Title>
      <Typography.Paragraph style={{ lineHeight: 1, marginBottom: 5 }}>{children}</Typography.Paragraph>
    </VerticalSpace>
  ) : (
    <Title title={text} style={{ maxHeight: 64, marginBottom: 0 }} level={5} type={'secondary'}>
      <FormattedMessage id={text} defaultMessage={defaultMessage} />
    </Title>
  )
}

const Description: FC = ({ children }) => (
  <Typography.Paragraph ellipsis={{ rows: 3 }} type={'secondary'}>
    {typeof children === 'string' ? (
      <Markup
        className='ant-typography ant-typography-secondary ant-typography-ellipsis-multiple-line'
        style={{ WebkitLineClamp: 3 }}
        html={children}
        sanitized={false}
      />
    ) : (
      children
    )}
  </Typography.Paragraph>
)

const Card: FC<Partial<CardProps>> = ({
  style,
  title,
  subTitle,
  description,
  defaultTitle,
  meta,
  hideDescription = false,
  type = 'primary',
  url,
  onClick = undefined,
  active = true,
  image,
  media,
  badge,
  cover,
  coverBordered,
  bordered = true,
  children,
  className = '',
  shape = 'circle',
  equalHeight,
  footer,
  footerBordered = true,
  footerFlush = false,
  flush = false,
  ...props
}) => {
  const history = useHistory()
  const goToUrl = () => url && history.push(url)
  const src = image?.src
  const fallbackSrc = `${process.env.PUBLIC_URL}/assets/filled/placeholder/${image?.placeholderSrc}.svg`
  const cardCover =
    type === 'primary' ? (
      <Image type={image?.type} src={src} preview={image?.preview ?? false} {...image} />
    ) : (
      <div style={{ marginTop: shape === 'circle' ? 16 : undefined }}>
        <Avatar {...image} src={src} shape={shape} />
      </div>
    )

  return (
    <Wrapped
      onClickCapture={onClick ?? goToUrl}
      className={withPrefix(
        className,
        'card-template',
        `card-${type}`,
        media && media.length > 1 ? 'card-with-gallery' : '',
        coverBordered ? 'card-cover-bordered' : '',
        badge ? 'card-with-badge' : '',
        active ? '' : `card-inactive`,
        equalHeight ? 'card-equal-height' : '',
        footer ? 'card-with-footer' : '',
        footerFlush ? 'card-with-footer-flush' : '',
        flush ? 'card-flush' : '',
      )}
      hoverable={url !== undefined || onClick !== undefined}
      bordered={bordered}
      cover={
        media && media.length > 1 ? <Gallery type={'full'} title={title} media={media} /> : image ? cardCover : cover
      }
      style={style}
      {...props}
    >
      <Wrapped.Meta
        title={
          title ? (
            title && React.isValidElement(title) ? (
              subTitle ? (
                <VerticalSpace size={5} className={withPrefix('card-meta-title-wrapper')}>
                  {title}
                  <Typography.Paragraph
                    className={withPrefix('card-subtitle')}
                    style={{ lineHeight: 1, marginBottom: 5 }}
                  >
                    {subTitle}
                  </Typography.Paragraph>
                </VerticalSpace>
              ) : (
                title
              )
            ) : (
              <CardTitle text={`${title}`} defaultMessage={defaultTitle}>
                {subTitle && subTitle}
              </CardTitle>
            )
          ) : (
            ''
          )
        }
        description={
          !!!hideDescription &&
          description &&
          (typeof description === 'string' ? <Description>{description}</Description> : description)
        }
      />
      {meta && <div className={withPrefix('card-meta-details')}>{meta}</div>}
      {children}
      {footer && (
        <div
          className={withPrefix(
            'card-footer',
            footerBordered ? 'card-footer-bordered' : '',
            footerFlush ? 'card-footer-flush' : '',
          )}
        >
          {footer}
        </div>
      )}
      {badge}
    </Wrapped>
  )
}

export { Card, CardTitle }
