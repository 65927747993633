import { Col, Form, Row, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import Card from 'src/sdk/components/card'
import Checkable from 'src/sdk/components/checkable'
import { Button } from 'src/sdk/components/form'
import { VerticalSpace } from 'src/sdk/components/layout'
import { OverlayLoader } from 'src/sdk/components/loader'
import { TextCentered } from 'src/sdk/components/text/TextCentered'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { BankAccount } from 'src/sdk/datasource/wallet/bankaccount'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { WalletBillingUse, WalletItem } from 'src/sdk/datasource/wallet/wallet'
import useWallet from 'src/sdk/hooks/useWallet'
import BankAccountAddForm from '../bank-account/BankAccountAddForm'
import BankAccountView from '../bank-account/BankAccountView'
import CreditCardAddForm from '../credit-card/CreditCardAddForm'
import CreditCardView from '../credit-card/CreditCardView'
import { Title } from '../../../../../sdk/components/text'

export type PaymentMethodAddProps = {
  before?: () => void
  onCancel?: () => void
  onSuccess?: (paymentItem: WalletItem) => void
  onError?: () => void
  onLoading?: (loading: boolean) => void
  billingUse?: WalletBillingUse
}

const PaymentMethodAdd: FC<PaymentMethodAddProps> = ({ onCancel, onSuccess, ...props }) => {
  const { achProcessor, stripeProcessor } = usePublicConfig()
  const [loading, setLoading] = useState(false)
  const { onUpdate } = useWallet()
  const [form] = Form.useForm()
  const [paymentType, setPaymentType] = useState<'card' | 'bank'>()
  const [paymentMethod, setPaymentMethod] = useState<WalletItem>()

  useEffect(() => {
    setPaymentType(stripeProcessor ? 'card' : 'bank')
  }, [])

  const handleSuccess = (paymentItem: WalletItem) => {
    setPaymentMethod(paymentItem)
    onUpdate(paymentItem)
    onSuccess && onSuccess(paymentItem)
  }

  return paymentMethod ? (
    paymentType === 'card' ? (
      <CreditCardView data={paymentMethod as CreditCardEntity} onDelete={onCancel} />
    ) : (
      <BankAccountView data={paymentMethod as BankAccount} onDelete={onCancel} />
    )
  ) : (
    <OverlayLoader fullScreen loading={loading}>
      <Row
        gutter={[
          {
            xs: 0,
            sm: 0,
            md: 55,
            lg: 55,
          },
          { xs: 24, sm: 24, md: 24, lg: 24 },
        ]}
      >
        <Col span={24}>
          <Title level={1}>Add new payment method</Title>
        </Col>
        <Col span={24}>
          <Title level={3} type={'secondary'}>
            Select Payment Type
          </Title>
        </Col>
        <Col span={24} lg={14}>
          <VerticalSpace size={40}>
            <VerticalSpace>
              {stripeProcessor?.cardsAccepted && stripeProcessor.cardsAccepted.length > 0 && (
                <Checkable
                  title={
                    <Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Credit Card
                    </Title>
                  }
                  size={'lg'}
                  icon={'application/credit-card'}
                  checked={paymentType === 'card'}
                  onClick={() => setPaymentType('card')}
                />
              )}
              {achProcessor && (
                <Checkable
                  title={
                    <Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Direct Debit
                    </Title>
                  }
                  size={'lg'}
                  icon={'application/bank'}
                  checked={paymentType === 'bank'}
                  onClick={() => setPaymentType('bank')}
                />
              )}
            </VerticalSpace>
            {paymentType === 'card' ? (
              <CreditCardAddForm form={form} onSuccess={handleSuccess} onLoading={setLoading} {...props} />
            ) : (
              <BankAccountAddForm form={form} onSuccess={handleSuccess} onLoading={setLoading} {...props} />
            )}
          </VerticalSpace>
        </Col>
        <Col span={24} lg={10}>
          <Card bordered>
            <VerticalSpace size={32}>
              <TextCentered>
                The protection of your data is one of the most important factors in our business. We are aware of your
                concerns and the data security regulations.
              </TextCentered>
              <VerticalSpace size={12}>
                <Button onClick={onCancel} disabled={loading} type={'ghost'} title={'Cancel'} block />
                {paymentType === 'card' && (
                  <Button
                    loading={loading}
                    disabled={loading}
                    type={'primary'}
                    onClick={() => form.submit()}
                    title={'Add new payment method'}
                    block
                  />
                )}
              </VerticalSpace>
            </VerticalSpace>
          </Card>
        </Col>
      </Row>
    </OverlayLoader>
  )
}

export default PaymentMethodAdd
