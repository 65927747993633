import { Col, Row, Typography } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import { useDLE } from 'rest-hooks'
import { VerticalSpace } from 'src/sdk/components/layout'
import { SectionLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import { Money, Title } from 'src/sdk/components/text'
import { StatementEntity, StatementWithTransactions } from 'src/sdk/datasource/transaction'
import { StatementSummaryList } from '../order/OrderDetails'
import { StatementTransactionsTable } from './TransactionTable'
import { Text } from '../../../../sdk/components/text/Text'

const Statement: FC<{ statement: StatementEntity; title?: string }> = ({ statement, title = 'Statement' }) => {
  const { data, loading } = useDLE(StatementWithTransactions.detail(), { id: statement.id })

  const statementSummary = [
    {
      id: 'statement-from',
      title: <Text>Statement From</Text>,
      description: moment(statement.startDate).format('MMMM D, YYYY'),
    },
    {
      id: 'statement-to',
      title: <Text>Statement To</Text>,
      description: moment(statement.endDate).format('MMMM D, YYYY'),
    },
    {
      id: 'transaction-count',
      title: <Text>Total Transactions</Text>,
      description: statement.count,
    },
  ]

  const statementSpent = [
    {
      id: 'charges-processed',
      title: <Text>Charges Processed</Text>,
      description: <Money>{statement.charged}</Money>,
    },
    {
      id: 'refunds',
      title: <Text>Refunds</Text>,
      description: <Money>{statement.refunded * -1}</Money>,
    },
    {
      id: 'total-charges',
      title: <Text>Total Charges this Period</Text>,
      description: <Money>{statement.total}</Money>,
    },
    {
      id: 'balance',
      title: <Text>Amount Due This Period</Text>,
      description: <Money>{statement.periodDue}</Money>,
    },
    ...(data && data.totalDue - data.periodDue > 0
      ? [
          {
            id: 'previous-outstanding-balance',
            title: 'Previous Outstanding Balance',
            description: <Money>{data.totalDue - data.periodDue}</Money>,
          },
        ]
      : []),
    ...(data && data.totalDue
      ? [
          {
            id: 'outstanding-balance',
            title: 'Total Outstanding Balance',
            description: <Money>{data.totalDue}</Money>,
          },
        ]
      : []),
  ]

  return (
    <Row>
      <Col span={24}>
        <Title level={1}>{title}</Title>
      </Col>
      <Col span={24}>
        <VerticalSpace size={40}>
          <Row justify={'space-between'} gutter={50}>
            <Col xs={0} lg={11}>
              <StatementSummaryList dataSource={statementSummary} />
            </Col>
            <Col xs={0} lg={11}>
              <StatementSummaryList dataSource={statementSpent} />
            </Col>
            <Col xs={24} lg={0}>
              <StatementSummaryList dataSource={[...statementSummary, ...statementSpent]} />
            </Col>
          </Row>
          {loading ? (
            <SectionLoader />
          ) : (
            data && <StatementTransactionsTable statement={data} transactions={data.transactions} />
          )}
        </VerticalSpace>
      </Col>
    </Row>
  )
}

const Index: FC<{ id: Data.ID; title?: string }> = ({ id, title }) => {
  const { data, loading } = useDLE(StatementEntity.detail(), { id: id })
  return loading ? <SectionLoader /> : data ? <Statement statement={data} title={title} /> : <Result.NotFound />
}

export default Index
