import { Col, Row, Typography } from 'antd'
import { FC } from 'react'
import { PaymentBrand } from 'src/sdk/components/payment-brand/PaymentBrand'
import { withPrefix } from 'src/sdk/contexts/Config'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import './CreditCard.less'
import { Title } from '../../../sdk/components/text'
import { Text } from '../../../sdk/components/text/Text'

export const CreditCard: FC<Data.Source<CreditCardEntity>> = ({ data }) => {
  const className = withPrefix('payment-card', `payment-card-${data.brand}`)
  return (
    <Row gutter={[0, 20]} className={className}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <PaymentBrand
              color={'default'}
              type={'full'}
              wrapperStye={{ height: 20 }}
              brand={data.brand.toLowerCase() as PaymentMethodBrand}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: 15 }}>
        <Row>
          <Col hidden={!data.brand} span={24}>
            <Title level={5}>{data.nickname}</Title>
          </Col>
          <Col span={24}>
            <Text className={'card-number'} strong>
              **** **** **** {data.lastFour !== '' ? data.lastFour : '0000'}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 8]}>
          <Col span={14}>
            <Title className={withPrefix('payment-card-title')} level={3}>
              Cardholder
            </Title>
            <Text>{data.billingDetails.name}</Text>
          </Col>
          <Col span={10}>
            <Title className={withPrefix('payment-card-title')} level={3}>
              Expires
            </Title>
            <Text>{data.fullExpiration}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
