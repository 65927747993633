import { List } from 'antd'
import { ListItemMetaProps } from 'antd/lib/list'
import { FC } from 'react'
import LocalFormattedDate from 'src/sdk/components/datetime/LocalFormattedDate'
import LocalFormattedTime from 'src/sdk/components/datetime/LocalFormattedTime'
import IvyIcon from 'src/sdk/components/icon'
import { useI18n } from 'src/sdk/contexts/I18n'
import { AccountAppointment, AccountReservation } from 'src/sdk/datasource/account/account'

const OrderBookingDescription: FC<Data.Source<AccountReservation | AccountAppointment>> = ({ data }) => {
  const {t} = useI18n()
  const details: ListItemMetaProps[] = [
    {
      description: <LocalFormattedDate value={data.startDateLocal} dateStyle={'full'} type={'secondary'} />,
      avatar: <IvyIcon size={15} type={'custom/calendar'} />,
    },
    {
      description: <LocalFormattedTime type={'text'} value={[data.startDateLocal, data.endDateLocal]} />,
      avatar: <IvyIcon size={15} type={'custom/time'} />,
    },
    ...(data.guest
      ? [
          {
            description: `${t('Appointment for')} ${data.guest.fullName}`,
            avatar: <IvyIcon size={15} type={'application/user'} />,
          },
        ]
      : []),
  ]
  return (
    <List
      style={{ width: '100%' }}
      bordered={false}
      split={false}
      dataSource={details}
      renderItem={(item) => (
        <List.Item style={{ padding: '2px 0px', border: 'none' }}>
          <List.Item.Meta style={{ marginBottom: 0 }} {...item} />
        </List.Item>
      )}
    />
  )
}

export default OrderBookingDescription
