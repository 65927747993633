import moment from 'moment'
import { createContext, FC, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react'
import { IntlProvider, useIntl, type IntlConfig } from 'react-intl'
import { useLocalStorage } from 'react-use'
import { IntlConfig as PortalIntlConfig } from 'src/sdk/datasource/config'
import { messages } from '../../intl'
import { dayjs } from '../services/dayjs'
import { usePublicConfig } from './Config'

export type LanguageId = Lowercase<string>
export type CountryId = Uppercase<string>
export type LocaleId = `${LanguageId}-${CountryId}` | string

type LocaleDefault = {
  id: LocaleId
  language: LanguageId
  country: CountryId
  currency: string
  timezone: string
  hourFormat: '24' | '12'
}

const DEFAULT_LOCALE: Readonly<LocaleId> = 'en-US' as const
const [DEFAULT_LANGUAGE, DEFAULT_COUNTRY] = DEFAULT_LOCALE.split('-') as [LanguageId, CountryId]
const DEFAULT_CURRENCY = 'USD' as const
const DEFAULT_TIMEZONE = 'UTC' as const
const DEFAULT_RTL = false as const

const localeToLanguage = (locale: string) => locale.split('-')[0].toLowerCase() as LanguageId
const localeToCountry = (locale: string) => locale.split('-')[1].toUpperCase() as CountryId

const Context = createContext<{
  locale: LocaleDefault
  setDefault: (config: PortalIntlConfig) => void
  setLocale: (value: string) => void
  addTranslations: (newTranslations: Record<string, Record<string, string>>) => void
}>({
  locale: {
    language: DEFAULT_LANGUAGE,
    country: DEFAULT_COUNTRY,
    currency: DEFAULT_CURRENCY,
    timezone: DEFAULT_TIMEZONE,
    id: DEFAULT_LOCALE,
    hourFormat: '12',
  },
  setLocale: () => {},
  setDefault: () => {},
  addTranslations: () => {},
})

const I18nProvider: FC<PropsWithChildren<ReactNode>> = ({ children }) => {
  const [defaults, setDefaults] = useState<LocaleDefault>({
    language: DEFAULT_LANGUAGE,
    country: DEFAULT_COUNTRY,
    currency: DEFAULT_CURRENCY,
    timezone: DEFAULT_TIMEZONE,
    id: DEFAULT_LOCALE,
    hourFormat: '12',
  })

  const [locale, setLocale] = useLocalStorage<string>(
    'locale',
    [defaults.language, defaults.country].join('-') as LocaleId,
  )
  const [translations, setTranslations] = useState<Record<string, Record<string, string>>>(messages)

  const addTranslations = (newTranslations: Record<string, Record<string, string>>) => {
    let overrides: Record<string, Record<string, string>> = translations
    Object.entries(newTranslations).map(([locale, value]) => {
      const default_messages = translations[locale]
      overrides[locale] = default_messages ?? {}
      const titleMatch = (title: string) => {
        if (!default_messages) return title
        const index = Object.keys(default_messages).findIndex((key) => key.toLowerCase() === title.toLowerCase())
        if (index === -1) return title
        return Object.keys(default_messages)[index]
      }
      Object.entries(value).map(([k, v]) => {
        overrides[locale][titleMatch(k)] = v
      })
    })
    setTranslations({
      ...translations,
      ...overrides,
    })
  }

  const hour12Format = (locale) => {
    const date = new Date()
    const formatter = new Intl.DateTimeFormat(locale, { hour: 'numeric' })
    const parts = formatter.formatToParts(date)
    return parts.some((part) => part.type === 'dayPeriod')
  }

  const setDefault = (config: PortalIntlConfig) => {
    // Defaults should be determined by the portal configuration
    setDefaults({
      language: localeToLanguage(config.locale ?? DEFAULT_LANGUAGE),
      country: localeToCountry(config.locale ?? DEFAULT_COUNTRY),
      currency: config.currency ?? DEFAULT_CURRENCY,
      timezone: config.timezone ?? DEFAULT_TIMEZONE,
      id: config.locale ?? DEFAULT_LOCALE,
      hourFormat: config.hourFormat ?? hour12Format(config.locale ?? DEFAULT_LOCALE) ? '12' : '24',
    })
  }

  const formats: IntlConfig['formats'] = {
    number: {
      money: {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: defaults.currency,
      },
      credits: {
        style: 'decimal',
      },
    },
    time: {
      short: {
        hour: 'numeric',
        minute: 'numeric',
        hour12: defaults.hourFormat === '12',
      },
    },
  }

  useEffect(() => {
    dayjs.locale(localeToLanguage(locale ?? defaults.id))
    moment.locale(localeToLanguage(locale ?? defaults.id))
  }, [defaults])

  if (!locale) return null

  return (
    <Context.Provider
      value={{
        locale: {
          language: localeToLanguage(locale ?? defaults.id),
          country: localeToCountry(locale ?? defaults.id),
          id: locale as LocaleId,
          currency: defaults.currency,
          timezone: defaults.timezone,
          hourFormat: defaults.hourFormat,
        },
        setLocale: (locale: string) => {
          setLocale(locale)
          window.location.reload()
        },
        addTranslations,
        setDefault,
      }}
    >
      <IntlProvider
        formats={formats}
        messages={translations[locale.split('-')[0]]}
        defaultLocale={locale ?? defaults.id}
        locale={locale}
        onError={(err) => {}}
        timeZone={defaults.timezone}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

const useI18n = (options?: { currency?: string }) => {
  const { intl } = usePublicConfig()
  const { formatMessage, formatDate, formatTime, formatNumber } = useIntl()

  return {
    ...useContext(Context),
    t: (text: string, ctx = {}) => (text ? formatMessage({ id: text, defaultMessage: text }, ctx) : text),
    money: (value?: number, currency?: string) => {
      currency = currency?.trim() ? currency : options?.currency ?? intl.currency
      return !value || isNaN(value) ? '' : formatNumber(value, { format: 'money', currency: currency })
    },
    date: (value: Date | [Date, Date] | string, options?: Parameters<typeof formatDate | typeof formatTime>[1]) =>
      Array.isArray(value) ? value.map((it) => formatDate(it, options)).join(' - ') : formatDate(value, options),
  }
}

export { I18nProvider, useI18n }
