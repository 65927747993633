import { List, Tag as Wrapped, TagProps } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { FC, ReactNode, useEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { HorizontalSpace } from '../layout'
import './Tag.less'
import { useI18n } from '../../contexts/I18n'

export type TagShape = 'rounded' | 'asymmetric'
export type TagType = 'primary' | 'default' | 'ghost' | 'error' | 'success' | 'processing' | 'info' | 'warning'

export type OptionsProps = {
  type: TagType
  size: SizeType
  shape: TagShape
  checkable?: boolean
  isChecked?: boolean
  disabled?: boolean
  onCheck?: (checked: boolean) => void
}

const Tag: FC<TagProps & Partial<OptionsProps>> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'large',
  icon = null,
  checkable = false,
  className,
  isChecked = false,
  disabled,
  onCheck,
  children,
  ...baseProps
}) => {
  const [checked, setChecked] = useState(isChecked)
  const { t } = useI18n()
  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleCheck = () => {
    if (checkable && !disabled) {
      onCheck && onCheck(!isChecked)
      setChecked((prevState) => !prevState)
    }
  }
  return (
    <Wrapped
      onClick={handleCheck}
      className={withPrefix(
        !checkable ? 'ant-tag-not-checkable' : '',
        `tag-${shape}`,
        `tag-${type}`,
        `tag-${size}`,
        checked ? 'tag-checked' : '',
        className ? className : '',
        disabled ? 'tag-disabled' : '',
      )}
      icon={icon}
      {...baseProps}
    >
      {typeof children === 'string' ? t(children) : children}
    </Wrapped>
  )
}

type TagsItem = {
  title: string | ReactNode
  id: React.Key
  icon?: ReactNode
}
type TagsProps = {
  type?: TagType
  size?: SizeType
  shape?: TagShape
  checkable?: boolean
  checked?: React.Key
  onCheck?: (id: React.Key, checked: boolean) => void
} & Data.Source<TagsItem[]>

const Tags: FC<TagsProps> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'large',
  checked,
  checkable,
  onCheck,
  data,
}) => {
  const { t } = useI18n()
  return (
    <List
      split={false}
      grid={{ gutter: 4 }}
      bordered={false}
      dataSource={data}
      itemLayout={'horizontal'}
      renderItem={(tag) => (
        <Tag
          key={tag.id}
          type={type}
          size={size}
          shape={shape}
          isChecked={tag.id === checked}
          checkable={checkable}
          onCheck={(checked) => onCheck && onCheck(tag.id, checked)}
          style={{ marginBottom: 8 }}
        >
          <HorizontalSpace>
            {tag.icon}
            {typeof tag.title === 'string' ? t(tag.title) : tag.title}
          </HorizontalSpace>
        </Tag>
      )}
    />
  )
}

const TagComponent: FC<typeof Tag.defaultProps & Partial<OptionsProps>> = ({
  shape = 'rounded',
  type = 'default',
  size = 'middle',
  className,
  ...baseProps
}) => {
  return <Tag className={withPrefix(`tag-${shape}`, `tag-${type}`, `tag-${size}`)} {...baseProps} />
}

export { Tag, TagComponent, Tags }
