import { Popconfirm, PopconfirmProps, Typography } from 'antd'
import { FC, PropsWithChildren } from 'react'
import IvyIcon from '../icon'
import './PopConfirm.less'
import { Title } from '../text'

type PopConfirmProps = PopconfirmProps & {
  title: string
  iconType?: Design.IvyIcon
}

export const PopConfirm: FC<PropsWithChildren<PopConfirmProps>> = ({ iconType, title, children, ...props }) => (
  <Popconfirm
    cancelButtonProps={{
      type: 'ghost',
      size: 'middle',
    }}
    okButtonProps={{
      size: 'middle',
    }}
    icon={iconType ? <IvyIcon size={30} type={iconType} /> : props.icon}
    title={<Title level={5}>{title}</Title>}
    {...props}
  >
    {children}
  </Popconfirm>
)
