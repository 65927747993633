import { Button, List, Space, Typography } from 'antd'
import { ListItemTypeProps } from 'antd/lib/list/Item'
import React, { FC, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Avatar } from '../image'
import { AvatarProps } from '../image/Avatar'
import { VerticalSpace } from '../layout'
import { BreakpointMax } from '../screen/Breakpoint'
import './index.less'
import { Text } from '../text/Text'

type ListItemProps = {
  className?: string
  bordered?: boolean
  onClick?: () => void
} & Partial<ListItemTypeProps>

const ListItem: FC<ListItemProps> = ({ className = '', bordered, onClick, children, ...props }) => {
  return (
    <List.Item
      className={withPrefix(
        className,
        onClick ? 'list-item-with-click' : '',
        bordered ? 'list-item-bordered' : 'list-item-bordered',
      )}
      onClickCapture={onClick}
      {...props}
    >
      {children}
    </List.Item>
  )
}

export type ListItemMetaProps = {
  onClick?: () => void
  className?: string
  bordered?: boolean
  avatar?: {
    icon: ReactNode
  } & AvatarProps
  icon?: ReactNode
  title?: string | ReactNode
  description?: string | ReactNode
  extra?: ReactNode
}

const ListItemMeta: FC<ListItemMetaProps> = ({
  onClick,
  className,
  avatar,
  icon,
  title,
  description,
  extra,
  bordered = false,
}) => {
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.SM })
  const handleClick = () => {
    onClick && onClick()
  }
  return (
    <List.Item.Meta
      className={[className, withPrefix(bordered ? 'list-item-meta-bordered' : '', 'pv-list-item-meta')].join(' ')}
      description={
        extra ? (
          <div onClickCapture={handleClick} className={withPrefix(onClick ? 'list-item-meta-click' : '')}>
            <Space style={{ width: '100%', justifyContent: 'space-between' }} align={isMobile ? 'start' : 'center'}>
              <VerticalSpace size={0}>
                {title && (
                  <Text className={'pv-list-item-meta-title'} style={{ marginBottom: 0 }}>
                    {title}
                  </Text>
                )}

                {description && (
                  <Text
                    type={'secondary'}
                    className={'pv-list-item-meta-description'}
                    style={{ marginBottom: 0 }}
                  >
                    {description}
                  </Text>
                )}
              </VerticalSpace>
              {extra}
            </Space>
          </div>
        ) : (
          <div onClickCapture={handleClick} className={withPrefix(onClick ? 'list-item-meta-click' : '')}>
            <VerticalSpace size={0} className={withPrefix('list-item-meta-wrapper')}>
              {title && <Text className={'pv-list-item-meta-title'}>{title}</Text>}
              {typeof description === 'string' ? (
                <Text type={'secondary'} className={'pv-list-item-meta-description'}>
                  {description}
                </Text>
              ) : (
                description
              )}
            </VerticalSpace>
          </div>
        )
      }
      avatar={
        icon
          ? icon
          : avatar && (
              <Avatar
                {...avatar}
                style={{
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...avatar.style,
                }}
                size={avatar.size ?? 48}
                icon={avatar.icon}
                background={avatar.background ?? 'none'}
              />
            )
      }
    />
  )
}

export { ListItem, ListItemMeta }
