import { FormItemProps, Input, InputProps, List, Select, SelectProps } from 'antd'
import { Rule } from 'antd/lib/form'
import { FC, KeyboardEvent, ReactElement, useMemo, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useIntlFormatter, withPrefix } from 'src/sdk/contexts/Config'
import { State } from 'src/sdk/datasource/intl'
import { isValidEmail } from 'src/sdk/helpers/strings'
import { useCountries } from 'src/sdk/hooks/useCountries'
import { Item } from '.'
import IvyIcon from '../icon'
import { VerticalSpace } from '../layout'
import './FormItem.less'
import './index.less'
import PhoneNumber from './phone/PhoneNumber'
import { useI18n } from '../../contexts/I18n'

function preventTab(evt: KeyboardEvent) {
  if (evt.code === 'Tab') evt.preventDefault()
}
const FormItemRules: { [key: string]: Rule[] } = {
  email: [
    {
      // type: 'email',
      message: 'Please enter a valid email address',
      pattern: /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/,
      validator: (rule: Rule, email: string, source) => {
        return new Promise<string | undefined>((resolve, reject) => {
          if (isValidEmail(email)) {
            resolve(email)
          } else {
            reject('Please enter a valid email address')
          }
        })
      },
    },
  ],
}

export type FormProps = {
  errorMessage?: string
  enableTab?: boolean
  disabled?: boolean
  placeholder?: string
  value?: any
  initialValue?: any
  country?: string
  noLabel?: boolean
  onValidate?: (valid: boolean) => void
} & FormItemProps

const Email: FC<FormProps> = ({
  placeholder = 'Email Address',
  errorMessage,
  enableTab = true,
  label  ,
  name = 'email',
  required,
  disabled,
  onValidate,
  ...props
}) => {
  const {t} = useI18n()
  return (
    <Item
      label={typeof label === 'string'? t(label): label}
      name={name}
      rules={[
        {
          required: required,
          message: t('Please enter a valid email address'),
          pattern: /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/,
          validator: (rule: Rule, email: string, source) => {
            return new Promise<string | undefined>((resolve, reject) => {
              if (!required && !email) {
                resolve(email)
                onValidate && onValidate(true)
              }
              if (isValidEmail(email)) {
                resolve(email)
                onValidate && onValidate(true)
              } else {
                reject(t('Please enter a valid email address'))
                onValidate && onValidate(false)
              }
            })
          },
        },
      ]}
      {...props}
    >
      <Input
        disabled={disabled}
        onKeyDown={(evt) => !enableTab && preventTab(evt)}
        type={'email'}
      />
    </Item>
  )
}

const ValidatePassword = (password: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return {
    minChars: password.length >= 8,
    upper: password.toLowerCase() !== password,
    lower: password.toUpperCase() !== password,
    numberOrSymbol: /\d/.test(password) || specialChars.test(password),
  }
}

const PasswordStrengthIndicator: FC<{ input: string; onValidate?: (valid: boolean) => void }> = ({
  input,
  onValidate,
}) => {
  const {t} = useI18n()
  const descriptions = {
    minChars: t('At least 8 characters long'),
    upper: t('1 uppercase letter'),
    lower: t('1 lowercase letter'),
    numberOrSymbol: t('1 number or special character'),
  }

  const validation = useMemo(() => ValidatePassword(input), [input])

  const indicators: ReactElement[] = useMemo(() => {
    const level = Object.values(validation).filter((v) => v === true).length
    onValidate && onValidate(level >= 4)
    const elements: ReactElement[] = []
    for (let i = 1; i < 5; i++) {
      elements.push(
        <div
          key={`password-indicator-${i}`}
          className={withPrefix(
            `password-strength-indicator`,
            `password-strength-indicator-${i <= level ? level : 'none'}`,
          )}
        />,
      )
    }
    return elements
  }, [validation])

  return (
    <div className={withPrefix('password-strength-wrapper')}>
      <div className={withPrefix('password-strength-indicators')}>{indicators}</div>
      <List
        className={withPrefix('password-strength-descriptions')}
        dataSource={Object.keys(validation).map((v) => {
          return {
            key: v,
            title: descriptions[v],
            valid: validation[v] === true,
          }
        })}
        renderItem={(item) => (
          <List.Item.Meta
            key={item.key}
            avatar={
              <IvyIcon color={item.valid ? 'success' : 'danger'} type={`symbol/${item.valid ? 'check' : 'close'}`} />
            }
            description={item.title}
          />
        )}
      />
    </div>
  )
}

const Password: FC<FormProps & { showLevel?: boolean; onValidate?: (valid: boolean) => void }> = ({
  showLevel = false,
  label = 'Password',
  placeholder = 'Password',
  disabled,
  onValidate,
  ...props
}) => {
  const [value, setValue] = useState('')
  const {t} = useI18n()
  return showLevel ? (
    <VerticalSpace>
      <Item
        className={withPrefix('password-input')}
        preserve={false}
        name={props.name ? props.name : 'password'}
        label={label}
        required
        rules={[
          {
            validator: (rule: Rule, password: string, source) => {
              return new Promise<string | undefined>((resolve, reject) => {
                const validate = ValidatePassword(password)
                if (Object.values(validate).filter((v) => v === true).length >= 4) {
                  onValidate && onValidate(true)
                  resolve(password)
                } else {
                  onValidate && onValidate(false)
                  reject()
                }
              })
            },
          },
        ]}
      >
        <Input.Password
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          type={t('Enter your password')}
          disabled={disabled}
        />
      </Item>
      <PasswordStrengthIndicator input={value} onValidate={onValidate} />
    </VerticalSpace>
  ) : (
    <Item
      rules={[
        {
          validator: (rule: Rule, password: string, source) => {
            return new Promise<string | undefined>((resolve, reject) => {
              if (password.length === 0) {
                reject()
              } else {
                resolve(password)
              }
            })
          },
        },
      ]}
      className={withPrefix('password-input')}
      preserve={false}
      name={props.name ? props.name : 'password'}
      label={label}
      required
      {...props}
    >
      <Input.Password type={'Enter your password'} disabled={disabled} />
    </Item>
  )
}

const ZipCode: FC<FormProps> = ({
  errorMessage,
  enableTab = true,
  label = '',
  name = 'zipCode',
  value,
  initialValue = undefined,
  country = 'US',
  noLabel,
  status,
  ...props
}) => {
  const { message } = useIntlFormatter()
  const { postalValidator } = useCountries()

  return noLabel ? (
    <Input value={value} onKeyDown={(evt) => !enableTab && preventTab(evt)} type={'text'} {...props} />
  ) : (
    <Item
      name={name}
      label={label ? label : country === 'US' ? 'Zipcode' : 'Postal Code'}
      initialValue={initialValue}
      rules={[
        {
          required: props.required,
          message: errorMessage ?? `Please enter a valid value`,
          pattern: postalValidator(country),
        },
      ]}
      {...props}
    >
      <Input value={value} onKeyDown={(evt) => !enableTab && preventTab(evt)} type={'text'} />
    </Item>
  )
}

export type TextInputProps = {} & FormItemProps & InputProps

const Text: FC<TextInputProps> = ({ label = '', name, ...props }) => (
  <Item label={label} name={name}>
    <Input type={'text'} {...props} />
  </Item>
)

const Countries: FC<SelectProps<string>> = ({ ...props }) => {
  const { countries } = useCountries()
  return (
    <Select {...props}>
      {countries &&
        countries.map((it) => (
          <Select.Option key={it.abbreviation} value={it.abbreviation}>
            {it.name}
          </Select.Option>
        ))}
    </Select>
  )
}

const States: FC<Partial<FormProps> & SelectProps<string>> = ({ country = 'us', ...props }) => {
  const states = State.getAll()
  const [inputValue, setInputValue] = useState('')

  return country === 'US' ? (
    <Select onChange={setInputValue} value={inputValue} name={['billingDetails', 'state']} {...props}>
      {states &&
        states.map((it) => (
          <Select.Option key={it.abbreviation} value={it.abbreviation}>
            {it.name}
          </Select.Option>
        ))}
    </Select>
  ) : (
    <Input onChange={(evt) => setInputValue(evt.target.value)} value={inputValue} name={'billingDetails_state'} />
  )
}

const FormItem = {
  Password,
  Email,
  Countries,
  States,
  ZipCode,
  Phone: PhoneNumber,
  Text,
  FormItemRules,
} as const

export { FormItem }
