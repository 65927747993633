import {
  Checkbox as AntCheckbox,
  Col,
  Input,
  Radio as AntRadio,
  Row,
  Select as AntSelect,
  Space,
  Typography,
  Upload as AntUpload,
} from 'antd'
import { FormInstance, Rule } from 'antd/lib/form'
import moment from 'moment'
import { FC, useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { withPrefix } from 'src/sdk/contexts/Config'
import { FieldType, FieldTypes, FieldValue, SurveyField as SurveyFieldType } from 'src/sdk/datasource/survey'
import { Button, DatePicker, Item } from '../form'
import IvyIcon from '../icon'
import { Tag } from '../tag'
import SmallText from '../text/SmallText'
import { Title } from '../text'
import { useI18n } from '../../contexts/I18n'
import { Markup } from '../text/Markup'
import './SurveyField.less'

const { TextArea: AntTextArea } = Input

export type SurveyFieldProps = {
  surveyField: SurveyFieldType
  formInstance?: FormInstance
  loading?: boolean
}

export type DynamicFieldProps = SurveyFieldProps & {
  name: string
  formInstance?: FormInstance
  loading?: boolean
  disabled?: boolean
}

const Upload: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  const { t } = useI18n()
  return (
    <Item
      name={`${surveyField.id}`}
      className={withPrefix('form-item-upload')}
      label={t(field.label)}
      rules={FieldRule(surveyField)}
    >
      <AntUpload
        name={`${surveyField.id}`}
        beforeUpload={() => false}
        multiple={false}
        accept={
          '.png,.jpeg,.jpg,.gif,.mp3,.avi,.mp4,.3gp,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,xlsm,.txt,.csv,.doc,.docx'
        }
      >
        <Button htmlType={'button'} type={'ghost'}>
          {t('Upload File')}
          <IvyIcon size={15} type={'application/download'} />
        </Button>
      </AntUpload>
    </Item>
  )
}

const Hidden: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  return <Input {...rest} />
}

const DateTime: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const [value, setValue] = useState<moment.Moment | null>(
    surveyField.field.value ? moment(surveyField.field.value) : null,
  )
  const updateValue = (value: moment.Moment | null, dateString: string) => {
    setValue(value)
    formInstance &&
      formInstance.setFields([
        {
          name: `${surveyField.id}`,
          value: moment(dateString).format('MM/DD/YYYY'),
        },
      ])
  }
  return (
    <DatePicker
      onChange={updateValue}
      size={'middle'}
      value={value}
      disabledDate={(value) =>
        surveyField.dataMap === 'birthdate' ||
        (surveyField.dataMap === 'anniversary_date' && value < moment().subtract(1, 'days'))
      }
      format={'MM/DD/YYYY'}
      // format={(date) => date.format('MM-DD-YYYY')}
      defaultPickerValue={moment()}
    />
  )
}
const Password: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  const { t } = useI18n()

  return <Input.Password placeholder={t(field.placeholder ?? field.label)} {...rest} />
}

const Text: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  return <Input defaultValue={field.value} {...rest} />
}

const TextArea: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field

  return <AntTextArea defaultValue={field.value} {...rest} />
}

const Html: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  return (
    <Space direction={'vertical'} className={withPrefix('form-item')}>
      <div className={withPrefix('form-item-label', 'form-item-html-label')}>
        <label>{field.label}</label>
      </div>
      <Markup html={field.value} sanitized={false} />
    </Space>
  )
}

const Select: FC<SurveyFieldProps> = ({ surveyField, formInstance, loading, ...rest }) => {
  const field = surveyField.field
  const selected = field.values?.find((item) => item.selected)
  const { t } = useI18n()
  return field.values?.length === 1 ? (
    <AntCheckbox disabled value={field.values[0].value}>
      {field.values[0].text}
    </AntCheckbox>
  ) : (
    <AntSelect defaultValue={selected?.value} style={{ width: '100%' }} {...rest}>
      {field.values?.map((v) => (
        <AntSelect.Option key={`${surveyField.id}-${v.value}`} value={v.value}>
          {t(v.text)}
        </AntSelect.Option>
      ))}
    </AntSelect>
  )
}

const Radio: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field
  const [value, setValue] = useState<string | undefined>()
  useEffect(() => {
    const defaultValue = surveyField.field.values?.find((v) => v.selected)?.value
    defaultValue && updateValue(defaultValue)
  }, [field])

  const updateValue = (value: string | undefined) => {
    setValue(value)
    formInstance &&
      formInstance.setFields([
        {
          name: `${surveyField.id}`,
          value: value,
        },
      ])
  }
  const { t } = useI18n()
  return (
    <AntRadio.Group defaultValue={surveyField.field.value} buttonStyle={'solid'} {...rest}>
      <Space wrap>
        {field.values && field.values.length < 3
          ? field.values?.map((v) => <AntRadio value={v.value}>{v.text}</AntRadio>)
          : field.values?.map((v) => (
              <Tag
                shape={'asymmetric'}
                size={'middle'}
                type={'default'}
                checkable={true}
                isChecked={value === v.value}
                onCheck={(checked) => checked && updateValue(v.value)}
                key={v.value}
              >
                {t(v.text)}
              </Tag>
            ))}
      </Space>
    </AntRadio.Group>
  )
}

const Checkbox: FC<CheckboxProps> = ({ surveyField, formInstance, value, onChange, ...rest }) => {
  const field = surveyField.field
  const [selected, setSelected] = useState<Set<string>>(new Set<string>())

  useEffect(() => {
    const defaultValues = field.values
      ?.filter((v) => v.selected)
      .reduce((acc, item) => [...acc, item.value], [] as string[])
    setSelected(new Set(defaultValues))
  }, [field])

  useEffect(() => {
    formInstance &&
      formInstance.setFields([
        {
          name: `${surveyField.id}`,
          value: Array.from(selected),
        },
      ])
  }, [selected])

  const update = (checked: boolean, value: string) => {
    let newValues: Set<string> = new Set<string>()

    if (checked) {
      if (!selected.has(value)) {
        newValues = new Set([...selected, value])
        setSelected((prev) => new Set([...prev, value]))
      }
    } else {
      if (selected.has(value)) {
        newValues = new Set([...selected].filter((v) => v !== value))
      }
    }
    setSelected(newValues)
  }

  return (
    <AntCheckbox.Group name={`${surveyField.id}`} style={{ width: '100%' }} {...rest}>
      <Space wrap>
        {field.values?.map((v) => (
          <Tag
            disabled={rest.disabled}
            shape={'asymmetric'}
            size={'middle'}
            type={'default'}
            checkable={true}
            isChecked={selected.has(v.value)}
            onCheck={(checked) => update(checked, v.value)}
            key={v.value}
          >
            {v.text}
          </Tag>
        ))}
      </Space>
    </AntCheckbox.Group>
  )
}

const Canvas: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const ref = useRef<SignatureCanvas>(null)
  const field = surveyField.field
  const [value, setValue] = useState(field.value)
  const handleClear = () => {
    ref.current?.clear()
    setValue('')
  }

  const updateValue = () => {
    formInstance &&
      formInstance.setFields([
        {
          name: `${surveyField.id}`,
          value: ref.current?.toDataURL(),
        },
      ])
  }
  return (
    <Item type={surveyField.field.type} name={`${surveyField.id}`} label={field.label} rules={FieldRule(surveyField)}>
      <Input type={'hidden'} value={value} {...rest} />
      <SignatureCanvas onEnd={updateValue} ref={ref}></SignatureCanvas>
      <Button htmlType={'button'} type={'ghost'} size={'small'} onClick={handleClear}>
        Clear
      </Button>
    </Item>
  )
}

const fieldValueToOption = (value: FieldValue) => {
  return {
    label: value.text,
    value: value.value,
  }
}
const fieldValueToAnswer = (surveyField: SurveyFieldType, value: string) => ({
  field_id: surveyField.id,
  answer: value,
})

type CheckboxProps = SurveyFieldProps & {
  value: FieldValue[]
  onChange(fv: { field_id: number; answer: any }[] | undefined): void
  disabled?: boolean
}

const Section: FC<SurveyFieldProps> = ({ surveyField, formInstance, ...rest }) => {
  const field = surveyField.field

  return (
    <Space direction={'vertical'}>
      <Title className={withPrefix('survey-section')} type={'secondary'} level={4} {...rest}>
        {field.label}
      </Title>
      {field.help && (
        <Typography.Paragraph className={withPrefix('survey-section-description')}>{field.help}</Typography.Paragraph>
      )}
    </Space>
  )
}

const fieldMap: Record<FieldType, FC<any> | string> = {
  text: Text,
  password: Password,
  hidden: Hidden,
  upload: Upload,
  textarea: TextArea,
  select: Select,
  radio: Radio,
  checkbox: Checkbox,
  yesno: Radio,
  section: Section,
  datetime: DateTime,
  canvas: Canvas,
  html: Html,
}

const FieldSize = (size: string) => {
  var width: number
  switch (size) {
    case '1of4':
      width = 6
      break
    case '2of4':
      width = 12
      break
    case '3of4':
      width = 18
      break
    case '4of4':
    case '3of3':
      width = 24
      break
    case '1of3':
      width = 8
      break
    case '2of3':
      width = 16
      break
    default:
      width = 24
      break
  }

  return width
}

const FieldRule = (surveyField: SurveyFieldType): Rule[] => {
  const field = surveyField.field

  const rule: Rule = {
    required: field.required,
  }

  switch (surveyField.dataMap) {
    case 'mobile_number':
    case 'phone_number':
      rule.pattern = /^\d{10}$/
      rule.message = 'Please enter a valid 10 digit number'
      break
    case 'email':
    case 'cc_email':
      rule.pattern = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
      rule.message = 'Please enter a valid email address'
      break
    case 'zip_code':
      rule.pattern = /^\d{5}$/
      rule.message = 'Please enter a valid 5 digital zip code'
      break
    default:
      rule.message = field.required ? `${field.label} is a required field` : ''
  }
  return [rule]
}

const SurveyField: FC<DynamicFieldProps> = ({ surveyField, name, loading, disabled, ...rest }) => {
  const field = surveyField.field
  if (FieldTypes.includes(field.type) === false) {
    console.log(`${field.type} is not currently supported.`)
    return null
  }
  const DynamicField = fieldMap[field.type]
  switch (field.type) {
    case 'section':
      return (
        <Col span={24} className={withPrefix('survey-section-row')}>
          <Row gutter={[20, 10]}>
            <Col span={24} lg={FieldSize(surveyField.field.style)}>
              <Section surveyField={surveyField} />
            </Col>
          </Row>
        </Col>
      )
    case 'html':
      return (
        <Col span={24} className={withPrefix('survey-section-row')}>
          <Row gutter={[20, 10]}>
            <Col span={24} lg={FieldSize(surveyField.field.style)}>
              <Html surveyField={surveyField} />
            </Col>
          </Row>
        </Col>
      )

    case 'hidden':
      return (
        <Item name={name} hidden>
          <DynamicField disabled={loading || disabled} surveyField={surveyField} {...rest} />
        </Item>
      )
    case 'upload':
      return (
        <Col span={24} md={FieldSize(surveyField.field.style)}>
          <DynamicField disabled={loading || disabled} surveyField={surveyField} {...rest} />
        </Col>
      )
    case 'canvas':
      return (
        <Col span={24} md={FieldSize(surveyField.field.style)}>
          <DynamicField disabled={loading || disabled} surveyField={surveyField} {...rest} />
        </Col>
      )
    default:
      return (
        <Col span={24} md={FieldSize(surveyField.field.style)}>
          <Item
            type={surveyField.field.type}
            name={name}
            label={field.label}
            rules={FieldRule(surveyField)}
            help={surveyField.field.help && <SmallText type={'secondary'}>{surveyField.field.help}</SmallText>}
          >
            <DynamicField disabled={loading || disabled} surveyField={surveyField} {...rest} />
          </Item>
        </Col>
      )
  }
}

export default SurveyField
