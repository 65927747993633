import { Switch as Wrapped, Typography, Space } from 'antd'
import { SwitchProps } from 'antd'
import { FC, ReactNode } from 'react'
import { Flex } from '../layout/Grid'
import './Switch.less'
import { Text } from '../text/Text'

type SwitchProperties = {
  description?: {
    label: ReactNode
    position?: 'left' | 'right'
  }
} & SwitchProps

const Switch: FC<Partial<SwitchProperties>> = ({ description, ...props }) => {
  return (
    <Flex
      direction={
        !description || !description.position || description.position === 'right' ? 'horizontal' : 'horizontal-reverse'
      }
      size={8}
    >
      <Wrapped {...props}></Wrapped>
      {description &&
        (typeof description.label === 'string' ? (
          <Text>{description.label}</Text>
        ) : (
          description.label
        ))}
    </Flex>
  )
}

export { Switch }
