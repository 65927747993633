import { FC, useMemo } from 'react'
import { ReactSVG } from 'react-svg'
import { IconProps } from './Icon'
import { default as AntIcon } from '@ant-design/icons'
import { withPrefix } from 'src/sdk/contexts/Config'
import './Icon.less'
import 'flag-icons/css/flag-icons.min.css'
import classNames from 'classnames'

export type IvyIconProps = {
  type: Design.IvyIcon
  theme?: Design.IconTheme
  height?: number
  style?: React.CSSProperties
  color?: 'success' | 'primary' | 'warning' | 'danger' | 'error' | 'info' | 'white' | 'secondary' | 'default'
  background?: 'primary' | 'warning' | 'danger' | 'error' | 'info' | 'light' | 'dark' | 'none'
}

const SvgIcon: FC<IvyIconProps> = ({ type, theme }) => <ReactSVG src={`/assets/${theme}/${type}.svg`} />

const IvyIcon: FC<IvyIconProps & Partial<IconProps>> = ({
  type,
  theme = 'outlined',
  size,
  height,
  wrapperSize,
  className = '',
  color,
  background,
  style,
  ...iconProps
}) => {
  const icon = useMemo(() => () => <SvgIcon type={type} theme={theme} color={color} />, [type, theme, color])
  if (type?.startsWith('flags/')) {
    return <span className={classNames('fi', `fi-${type.replace('flags/', '').toLowerCase()}`, className)} />
  } else {
    return (
      <AntIcon
        style={{ ...style, fontSize: size, width: size, height: height }}
        component={icon}
        className={withPrefix(
          className,
          color ? `ivy-icon-${color}` : '',
          background ? `ivy-icon-background-${background}` : '',
        )}
        {...iconProps}
      />
    )
  }
}

export default IvyIcon
