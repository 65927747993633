import { Select, Typography } from 'antd'
import { FC, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useGlobalNetwork } from 'src/components/global-network/GlobalNetwork'
import { GlobalNetworkCompany } from 'src/sdk/datasource/globalNetwork'
import { Image } from '../../sdk/components/image'
import { Flex } from '../../sdk/components/layout/Grid'
import './GlobalNetwork.less'
import { useLayoutState } from 'src/layouts'
import { Text } from '../../sdk/components/text/Text'

const GlobalNetworkSelectItem: FC<Data.Source<GlobalNetworkCompany> & { selected: boolean }> = ({ data, selected }) => {
  return (
    <div className={withPrefix('global-network-select-item', selected ? 'global-network-select-item-active' : '')}>
      <Image src={data.iconUrl} />
      <Flex direction={'vertical'} className={withPrefix('global-network-select-item-text')} size={4}>
        <Text className={withPrefix('global-network-select-item-title')}>{data.name}</Text>
        <Text className={withPrefix('global-network-select-item-subtitle')}>
          {data.state.trim() ? `${data.state}, ` : ''}
          {data.country}
        </Text>
      </Flex>
    </div>
  )
}

const GlobalNetworkNavigationSelect = () => {
  const { siderState } = useLayoutState()
  const [visible, setVisible] = useState(false)
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>()
  const { globalNetworks, currentNetwork, changeNetwork: setNetwork } = useGlobalNetwork()
  return globalNetworks && globalNetworks.length > 1 ? (
    <div
      ref={(ref) => setWrapperRef(ref)}
      className={withPrefix(
        'global-network-select-wrapper',
        visible ? 'global-network-select-wrapper-open' : '',
        siderState === 'collapsed' ? 'global-network-select-wrapper-collapsed' : '',
      )}
    >
      <Select
        bordered={false}
        getPopupContainer={() => wrapperRef || document.body}
        onDropdownVisibleChange={setVisible}
        className={withPrefix('global-network-select-selector')}
        showArrow={siderState !== 'collapsed'}
        value={currentNetwork?.id}
        onChange={setNetwork}
        showSearch={false}
        dropdownClassName={withPrefix('global-network-select-dropdown')}
        placement={'topRight'}
        options={globalNetworks.map((d) => ({
          disabled: currentNetwork?.id === d.id,
          label: <GlobalNetworkSelectItem data={d} selected={currentNetwork?.id === d.id} />,
          value: d.id,
        }))}
      />
    </div>
  ) : null
}

export default GlobalNetworkNavigationSelect
