import { Avatar, Col, Radio, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { FC, MouseEventHandler, ReactNode } from 'react'
import IvyIcon from 'src/sdk/components/icon'
import { withPrefix } from 'src/sdk/contexts/Config'
import { VerticalSpace } from '../layout'
import './index.less'
import { Text } from '../text/Text'

export type CheckableProps = {
  icon?: Design.IvyIcon
  title: string | ReactNode
  description?: string | ReactNode
  size?: 'lg' | 'sm'
  checked: boolean
  onClick: MouseEventHandler
  color?: string
  iconSize?: number
  iconColor?: string
}

const Checkable: FC<CheckableProps> = ({ icon, title, description, checked, onClick }) => {
  const className = withPrefix('checkable')
  return (
    <Row
      wrap={false}
      className={`${className} ${checked ? 'checked' : ''}`}
      gutter={16}
      align={'middle'}
      onClick={onClick}
      justify={'space-between'}
    >
      <Col span={20}>
        <Row gutter={32} wrap={false}>
          {icon ? (
            <Col>
              <Avatar shape='square' size={48} icon={icon && <IvyIcon size={24} type={icon} />} />
            </Col>
          ) : null}
          <Col>
            <Row align={'middle'} style={{ height: '100%' }}>
              <Col span={24}>
                {typeof title === 'string' ? <Text strong>{title}</Text> : title}
              </Col>
              {description && (
                <Col span={24}>
                  {typeof description === 'string' ? <Text>{description}</Text> : description}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Radio checked={checked} />
      </Col>
    </Row>
  )
}

interface ICheckableGroupItem<T extends { [K in keyof T]: string[] }> {
  title: ReactNode
  description: ReactNode
  icon?: Design.IvyIcon
  value: { [K in keyof T]: string }
  hidden?: boolean
}

interface ICheckableGroup<T extends { [K in keyof T]: string[] }> {
  defaultValue?: { [K in keyof T]: string }
  items: ICheckableGroupItem<T>[]
  onChange: (value?: { [K in keyof T]: string }) => void
}

function CheckableGroup<T extends { [K in keyof T]: string[] }>({ defaultValue, items, onChange }: ICheckableGroup<T>) {
  const [currentValue, setCurrentValue] = useState<{ [K in keyof T]: string }>()

  useEffect(() => {
    defaultValue && setCurrentValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    onChange(currentValue)
  }, [currentValue])

  return (
    <VerticalSpace>
      {items
        .filter((i) => !i.hidden)
        .map((item, index) => (
          <Checkable
            key={index}
            icon={item.icon}
            checked={currentValue === item.value}
            title={item.title}
            description={item.description}
            onClick={() => setCurrentValue(item.value)}
          />
        ))}
    </VerticalSpace>
  )
}
export { Checkable as default, CheckableGroup }
