import { Col, Form, Row, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useController } from 'rest-hooks'
import { Alert } from 'src/sdk/components/alert/Alert'
import { Button, Item } from 'src/sdk/components/form'
import { InputMoney } from 'src/sdk/components/form/InputNumber'
import IvyIcon from 'src/sdk/components/icon'
import { VerticalSpace } from 'src/sdk/components/layout'
import { useNotification } from 'src/sdk/contexts/Notification'
import { BankAccount, BankAccountVerifyRequest } from 'src/sdk/datasource/wallet/bankaccount'
import { Title } from '../../../../../../../sdk/components/text'
import { Text } from '../../../../../../../sdk/components/text/Text'

const StripeVerifyBankAccount: FC<Data.Source<BankAccount>> = ({ data }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [loading, setLoading] = useState(false)
  const [firstValue, setFirstValue] = useState<string | number | undefined>()
  const [secondValue, setSecondValue] = useState<string | number | undefined>()
  const [verifyEnabled, setVerifyEnabled] = useState(false)

  const { canVerify } = data

  useEffect(() => {
    setVerifyEnabled(firstValue !== undefined && secondValue !== undefined)
  }, [firstValue, secondValue])

  const onFinish = (values: BankAccountVerifyRequest) => {
    setLoading(true)
    fetch(BankAccount.verifyAccount(), data.id, values)
      .catch(notifyOnError)
      .finally(() => setLoading(false))
  }

  return canVerify ? (
    <VerticalSpace>
      <Title level={1}>Verify Your Account</Title>
      <Typography.Paragraph>
        Your account must be verified before use. We have credited two small deposits to your bank account. The deposits
        have a description that include <strong>AMTS</strong> and may take 1-2 business days to appear.
      </Typography.Paragraph>

      <VerticalSpace>
        <Text>Enter the two deposit amounts (in cents) that were credited to your account</Text>
        <Form<BankAccountVerifyRequest> onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Item name={'amount_1'}>
                <InputMoney mode={'cents'} onChange={setFirstValue} addonBeforeAmount={0} />
              </Item>
            </Col>
            <Col span={24} md={12}>
              <Item name={'amount_2'}>
                <InputMoney mode={'cents'} onChange={setSecondValue} addonBeforeAmount={0} />
              </Item>
            </Col>
            <Col span={24}>
              <Button
                block
                loading={loading}
                disabled={loading || !verifyEnabled}
                htmlType={'submit'}
                icon={<IvyIcon type={'directional/arrow-right'} />}
              >
                Verify
              </Button>
            </Col>
          </Row>
        </Form>
      </VerticalSpace>
    </VerticalSpace>
  ) : (
    <Alert
      showIcon
      icon={<IvyIcon size={32} type={'symbol/exclamation-circle'} />}
      type={'error'}
      message={'Verify Attempts Exceeded'}
      description={
        <Typography.Paragraph>
          You have exceeded the maximum attempts allowed to verify this bank account.
          <br />
          To continue trying to verify the account, please reach out to us directly.
        </Typography.Paragraph>
      }
    />
  )
}

export default StripeVerifyBankAccount
