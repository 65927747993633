import { Tooltip, Typography } from 'antd'
import { FC } from 'react'
import IvyIcon from '../icon'
import { useI18n } from '../../contexts/I18n'

export const LabelTooltip: FC<{ label: string; helpText: string }> = ({ label, helpText }) => {
  const {t} = useI18n()
  return (
    <>
      {t(label)}
      <Tooltip title={t(helpText)}>
        <Typography.Link tabIndex={-1} href='#' style={{ marginLeft: '5px' }}>
          <IvyIcon type={'symbol/info-circle'} />
        </Typography.Link>
      </Tooltip>
    </>
  )
}
