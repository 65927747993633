import { Typography } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { ImageEntity } from 'src/sdk/datasource/media'
import { Flex } from '../layout/Grid'
import { SectionLoader } from '../loader'
import { Markup } from '../text/Markup'
import './FullPageGallery.less'
import { Title } from '../text'

interface FullPageGalleryProps {
  media: ImageEntity[]
  title?: string | ReactNode
}

type PreLoadedImage = {
  image: ImageEntity
  type: 'vertical' | 'horizontal'
  direction?: 'left' | 'right'
}

const FullPageGalleryItem: FC<Data.Source<PreLoadedImage>> = ({ data }) => (
  <Flex
    className={withPrefix(
      'full-page-gallery-item',
      data.type === 'horizontal' ? 'full-page-gallery-item-h' : '',
      data.direction === 'right' ? 'full-page-gallery-item-h-r' : '',
    )}
  >
    <img className={withPrefix('full-page-gallery-item-image')} src={data.image.url} />
    <Flex size={8} direction={'vertical'} className={withPrefix('full-page-gallery-item-details')}>
      <Title level={5}>{data.image.title}</Title>
      <Typography.Paragraph>
        <Markup sanitized={false} html={data.image.description} />
      </Typography.Paragraph>
    </Flex>
  </Flex>
)

const FullPageGallery: FC<FullPageGalleryProps> = ({ title, media }) => {
  const [images, setImages] = useState<PreLoadedImage[]>([])
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    setLoading(true)
    let lastDirection: 'left' | 'right' = 'left'
    const promises = media.map((u) => {
      return new Promise<PreLoadedImage>((resolve) => {
        const img = new Image()
        img.src = u.url
        img.onload = () => {
          const type = img.width > img.height ? 'vertical' : 'horizontal'
          resolve({
            image: u,
            type: type,
            direction: type === 'horizontal' ? lastDirection : 'left',
          })
          if (type === 'horizontal') {
            lastDirection = lastDirection === 'left' ? 'right' : 'left'
          }
        }
      }).then((response) => {
        setImages((prevState) => [...prevState, response])
      })
    })
    Promise.all(promises).then(() => setLoading(false))
  }, [])

  return (
    <div className={withPrefix('full-page-gallery-wrapper')}>
      {title && <Title className={withPrefix('full-page-gallery-title')}>{title}</Title>}
      {loading ? (
        <SectionLoader />
      ) : (
        <Flex size={48} direction={'vertical'} className={withPrefix('full-page-gallery-list')}>
          {images.map((i) => (
            <FullPageGalleryItem data={i} />
          ))}
        </Flex>
      )}
    </div>
  )
}

export default FullPageGallery
