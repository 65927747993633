import { Col, Row, Typography } from 'antd'
import { FC, useState } from 'react'
import { CheckableCard } from 'src/sdk/components/card/CheckableCard'
import { VerticalSpace } from 'src/sdk/components/layout'
import { Flex } from 'src/sdk/components/layout/Grid'
import { GlobalNetworkCompany } from 'src/sdk/datasource/globalNetwork'
import { Image } from '../../sdk/components/image'
import PublicView from '../public'
import { useGlobalNetwork } from './GlobalNetwork'
import { Title } from '../../sdk/components/text'
import { Text } from '../../sdk/components/text/Text'

const GlobalNetworkHomeHouseSelect: FC<Data.Source<GlobalNetworkCompany[]>> = ({ data }) => {
  const [loading, setLoading] = useState(false)
  const { changeHomeHouse } = useGlobalNetwork()
  return (
    <PublicView>
      <Row wrap justify={'center'} align='middle'>
        <Col xs={20} md={18} lg={20} xl={12}>
          <Row wrap justify={'center'} align='middle' gutter={[32, 32]}>
            <Col span={24}>
              <Title level={1} style={{ marginBottom: 0 }}>
                Choose Location
              </Title>
            </Col>
            <Col span={24}>
              <VerticalSpace>
                {data.map((network) => (
                  <CheckableCard
                    enabled={!loading}
                    key={network.id}
                    onChange={() => {
                      setLoading(true)
                      changeHomeHouse(network.id)
                    }}
                  >
                    <Flex direction={'horizontal'} align={'center'} size={16}>
                      <Image width={36} src={network.iconUrl} />
                      <Flex direction={'vertical'}>
                        <Title level={5} style={{ marginBottom: 0 }}>
                          {network.name}
                        </Title>
                        <Text>
                          {network.state.trim() ? `${network.state}, ` : ''}
                          {network.country}
                        </Text>
                      </Flex>
                    </Flex>
                  </CheckableCard>
                ))}
              </VerticalSpace>
            </Col>
          </Row>
        </Col>
      </Row>
    </PublicView>
  )
}

export default GlobalNetworkHomeHouseSelect
