import { initialState, NetworkManager } from '@rest-hooks/core'
import { FC, Suspense, useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {
  CacheProvider,
  DevToolsManager,
  NetworkErrorBoundary,
  PollingSubscription,
  SubscriptionManager,
} from 'rest-hooks'
import { GlobalNetwork } from 'src/components/global-network/GlobalNetwork'
import { DebugProvider } from 'src/sdk/components/debug/DebugProvider'
import { Result } from 'src/sdk/components/result/Result'
import ScrollToTopController from 'src/sdk/components/scroll/ScrollToTopController'
import { DrawerDetailProvider } from 'src/sdk/contexts/detail-drawer/DrawerDetailProvider'
import { DynamicDrawerProvider } from 'src/sdk/contexts/DynamicDrawer'
import { FeatureProvider } from 'src/sdk/contexts/Feature'
import { I18nProvider } from 'src/sdk/contexts/I18n'
import { NativeAppProvider } from 'src/sdk/contexts/NativeApp'
import { PaymentProvider } from 'src/sdk/contexts/payment/Payment'
import { TokenizeProvider } from 'src/sdk/contexts/Tokenize'
import { store } from 'src/sdk/store'
import { LayoutProvider } from '../layouts'
import { Spinner } from '../sdk/components/loader'
import { AppProvider } from '../sdk/contexts/App'
import { AuthProvider } from '../sdk/contexts/Auth'
import { ConfigProvider } from '../sdk/contexts/Config'
import { NotificationProvider } from '../sdk/contexts/Notification'
import { Routing } from './Routing'

const Root: FC = ({ children }) => {
  const networkManager = new NetworkManager()
  const devToolsManager = new DevToolsManager()
  const subscriptionManager = new SubscriptionManager(PollingSubscription)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('redirect')) {
      window.open('pvapp:///redirect?to=' + params.get('redirect'))
    }
  }, [])

  return (
    <CacheProvider initialState={initialState} managers={[devToolsManager, networkManager, subscriptionManager]}>
      <ReduxProvider store={store}>
        <Suspense fallback={<Spinner />}>
          <NativeAppProvider>
            <I18nProvider>
              <BrowserRouter>
                <ConfigProvider>
                  <DebugProvider>
                    <AppProvider>
                      <TokenizeProvider>
                        <FeatureProvider>
                          <GlobalNetwork>
                            <NotificationProvider>
                              <AuthProvider>
                                <ScrollToTopController />
                                <PaymentProvider>
                                  <DynamicDrawerProvider>
                                    <DrawerDetailProvider>
                                      <LayoutProvider>
                                        <NetworkErrorBoundary
                                          fallbackComponent={(e: any) => {
                                            const error_code = e?.error?.payload
                                              ? e.error.payload.code ?? e.error.payload.response?.status
                                              : e.error?.status
                                            console.log(e)
                                            if (error_code === 403) {
                                              return <Result.Forbidden />
                                            } else if (error_code === 404) {
                                              return <Result.NotFound />
                                            } else if (error_code === 406) {
                                              return <Result.NotFound />
                                            } else {
                                              return <Result.Error />
                                            }
                                          }}
                                        >
                                          <Routing>{children}</Routing>
                                        </NetworkErrorBoundary>
                                      </LayoutProvider>
                                    </DrawerDetailProvider>
                                  </DynamicDrawerProvider>
                                </PaymentProvider>
                              </AuthProvider>
                            </NotificationProvider>
                          </GlobalNetwork>
                        </FeatureProvider>
                      </TokenizeProvider>
                    </AppProvider>
                  </DebugProvider>
                </ConfigProvider>
              </BrowserRouter>
            </I18nProvider>
          </NativeAppProvider>
        </Suspense>
      </ReduxProvider>
    </CacheProvider>
  )
}

export { Root as default }
