import { Input, Tabs, Typography } from 'antd'
import { FC, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import Card from '../card'
import DebugProps from './DebugProps'
import './Debug.less'
import DebugConfig from './DebugConfig'
import { useDebugAuth } from './DebugAuth'
import DebugCompany from './DebugCompany'
import React from 'react'
import { Spinner } from '../loader'
import DebugApp from './DebugApp'
import DebugLoginConfig from './DebugLoginConfig'
import Form, { Button, Item } from '../form'
import { UserCredentials } from 'src/sdk/datasource/token'
import { Title } from '../text'

const { TabPane } = Tabs

type Props = {
  active: boolean
  setActive: (active: boolean) => void
}

const DebugWindow: FC<Props> = ({ active, setActive }) => {
  const { authenticated, authenticate } = useDebugAuth()
  const [loading, setLoading] = useState(false)
  const handleLogin = (credentials: UserCredentials) => {
    setLoading(true)
    authenticate(credentials).finally(() => setLoading(false))
  }
  return (
    <div className={withPrefix('debug-wrapper', active ? 'debug-wrapper-visible' : '')}>
      {authenticated ? (
        <Card className={withPrefix('debug-card')}>
          <React.Suspense fallback={<Spinner />}>
            <Tabs>
              <TabPane tab={'Dev'} key={'company'}>
                <DebugCompany />
              </TabPane>
              <TabPane tab={'Company Settings'} key={'config'}>
                <DebugConfig />
              </TabPane>
              <TabPane tab={'Assets'} key={'assets'}></TabPane>
              <TabPane tab={'Theme'} key={'theme'}>
                <DebugProps />
              </TabPane>
              <TabPane tab={'Login'} key={'login'}>
                <DebugLoginConfig />
              </TabPane>
              <TabPane tab={'App'} key={'App'}>
                <DebugApp />
              </TabPane>
            </Tabs>
          </React.Suspense>
        </Card>
      ) : (
        <Card className={withPrefix('debug-card')} style={{ paddingTop: 30 }}>
          <Title level={3} style={{ paddingBottom: 30 }}>
            Admin Login
          </Title>
          <Form<UserCredentials> onFinish={handleLogin}>
            <Item name={'username'}>
              <Input disabled={loading} />
            </Item>
            <Item name={'password'}>
              <Input.Password disabled={loading} />
            </Item>
            <Button loading={loading} block htmlType={'submit'}>
              Login
            </Button>
          </Form>
        </Card>
      )}
    </div>
  )
}

export default DebugWindow
