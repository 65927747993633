import moment from 'moment'
import { CSSProperties, FC } from 'react'
import { DateTz } from 'src/sdk/api'
import { useI18n } from 'src/sdk/contexts/I18n'
import { HorizontalSpace } from '../layout'
import { Text } from '../text/Text'

interface LocalFormattedDateRange {
  from: DateTz
  to: DateTz
}

type LocalFormattedDateProps = {
  value: DateTz | LocalFormattedDateRange
  time?: boolean
  split?: string
  direction?: 'vertical' | 'horizontal'
  type?: 'secondary' | 'success' | 'warning' | 'danger'
  dateStyle?: 'full' | 'short'
  format?: string
  style?: CSSProperties
}
const LocalFormattedDate: FC<LocalFormattedDateProps> = ({
  value,
  direction = 'horizontal',
  split = '-',
  time = false,
  type,
  style,
  dateStyle,
  format,
}) => {
  const dateFormat = format
    ? format
    : dateStyle
    ? dateStyle === 'full'
      ? time
        ? 'dddd, MMMM Do YYYY \\a\\t h:mm A'
        : 'dddd, MMMM Do YYYY'
      : 'MMMM D, yyyy'
    : time
    ? 'MMMM D, yyyy \\a\\t h:mm A'
    : 'MMMM D, yyyy'

  const MultipleDates: FC<{ values: LocalFormattedDateRange }> = ({ values }) => {
    const from = DateTz.fromJS(values.from)
    const to = DateTz.fromJS(values.to)
    const sameDay = moment(from.date).isSame(to.date, 'day')
    const { date } = useI18n()
    return (
      <HorizontalSpace style={style}>
        {sameDay && !time && !format ? (
          <Text type={type}>
            {[
              date(value[0]?.date, { month: 'short', day: 'numeric', weekday: 'long' }),
              date(value[1]?.date, { month: 'short', day: 'numeric', weekday: 'long' }),
            ]
              .filter((value, index, self) => self.findIndex((it) => `${it}` === `${value}`) === index)
              .join(' - ')}
          </Text>
        ) : (
          <Text type={type}>{date(value[0]?.date)}</Text>
        )}
      </HorizontalSpace>
    )
  }

  const SingleDate: FC<{ value: DateTz }> = ({ value }) => {
    const { date } = useI18n()
    return <Text type={type}>
      {format ? moment(DateTz.fromJS(value).date).format(format) : date(DateTz.fromJS(value).date, { dateStyle: dateStyle })}
      </Text>
  }

  return 'to' in value ? <MultipleDates values={value} /> : <SingleDate value={value} />
}

export default LocalFormattedDate
